<template>
  <div class="drawer" :class="{ active: drawer }">
    <div class="drawer-content lg">
      <div class="drawer-content-header bg-contrast">
        <div class="btn icon btn-info no-hover">
          <em class="fal fa-bell fs-20"></em>
        </div>
        <div class="flex-grow-1 ml-2 text-color-default">
          <span class="h4">Notificações</span>
        </div>
        <button class="btn text icon" @click="close()">
          <em class="fal fa-arrow-right-to-line"></em>
        </button>
      </div>
      <span class="divider"></span>
      <div class="drawer-content-body">
        <div class="row gap-0">
          <Transition name="width">
            <div v-if="listIsOpen" class="col-xs-12 col-sm-6 d-flex fill-height">
              <div class="tab">
                <div class="tab-header">
                  <button
                    class="btn sm"
                    :class="{ 'btn-info': tab === 1 }"
                    @click="changeTab(1)"
                  >
                    <em class="fal fa-inbox"></em>
                    <span>Inbox</span>
                  </button>
                  <button
                    class="btn sm"
                    :class="{ 'btn-info': tab === 2 }"
                    @click="changeTab(2)"
                  >
                    <em class="fal fa-box-archive"></em>
                    <span>Arquivadas</span>
                  </button>
                </div>
                <span class="divider"></span>
                <Transition name="slide-fade" mode="out-in">
                  <div v-if="tab === 1" class="tab-content scrollable pb-4">
                    <template v-for="i in 3" :key="i + '-tab-bell'">
                      <div class="sticky bg-default">
                        <div class="pt-3 pb-1 pl-2 pr-2 text-center">
                          <span
                            class="subheader md font-weight-600"
                            :class="
                              i === 1 ? 'text-color-info' : 'text-color-light'
                            "
                          >
                            {{ titles[i - 1] }}
                          </span>
                        </div>
                        <span class="divider"></span>
                      </div>
                      <NotificationItem
                        v-for="i in 4"
                        :key="i + '-bell'"
                        :read="false"
                        :notification="items[i - 1]"
                        :selected="selected"
                        @openNotification="openNotification($event, i)"
                      />
                    </template>
                  </div>
                  <div
                    v-else-if="tab === 2"
                    class="tab-content scrollable pb-4"
                  >
                    <template v-for="i in 1" :key="i + '-tab-bell-arq'">
                      <div class="sticky bg-default">
                        <div class="pt-3 pb-1 pl-2 pr-2 text-center">
                          <span
                            class="subheader md font-weight-600"
                            :class="
                              false ? 'text-color-info' : 'text-color-light'
                            "
                          >
                            {{ titles[2] }}
                          </span>
                        </div>
                        <span class="divider"></span>
                      </div>
                      <NotificationItem
                        :notification="items[2]"
                        :selected="selected"
                        @openNotification="openNotification($event, i)"
                      />
                    </template>
                  </div>
                </Transition>
              </div>
            </div>
          </Transition>
          <span class="divider v" v-if="listIsOpen"></span>
          <div class="col-xs-12 flex-grow-1 d-flex" :class="{'col-sm-6': listIsOpen}">
            <div v-if="false" class="empty">
              <img src="/images/empty/messages.svg" alt="" />
            </div>
            <NotificationMessage :notification="selected" :listIsOpen="listIsOpen" @toogleList="toogleList" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationItem from './NotificationItem.vue'
import NotificationMessage from './NotificationMessage.vue'
export default {
  components: { NotificationItem, NotificationMessage },
  name: 'NotificationsComponent',
  data () {
    return {
      drawer: false,
      tab: 1,
      selected: {},
      listIsOpen: true,
      titles: ['Recentes', 'Ontem', '20 de maio'],
      items: [
        {
          title: 'Kickoff pendente',
          area: 'Pipeline',
          message: 'O Kickoff do projeto ER456-Diretor ainda não foi iniciado.',
          date: new Date(),
          type: 'project',
          read: false,
          urgent: true
        },
        {
          title: 'Há uma conta que vence hoje!',
          area: 'Área',
          message:
            'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean.',
          date: new Date(),
          type: 'count',
          read: false,
          urgent: true
        },
        {
          title: 'Título',
          area: 'Área',
          message: 'Mensagem',
          date: this.randomDate(new Date(2022, 7, 1), new Date()),
          type: 'message',
          read: true,
          urgent: false
        },
        {
          title: 'Conta vencida',
          area: 'Financeiro',
          message:
            'A empresa Banco BV ainda não pagou a 3ª parcela que venceu dia 12/05/2022.',
          date: this.randomDate(new Date(2022, 7, 1), new Date()),
          type: 'count',
          read: false,
          urgent: true
        }
      ]
    }
  },
  methods: {
    changeTab (tab) {
      this.tab = tab
    },
    open () {
      this.drawer = true
    },
    close () {
      this.drawer = false
    },
    randomDate (start, end) {
      return new Date(
        start.getTime() + Math.random() * (end.getTime() - start.getTime())
      )
    },
    openNotification (notification, index) {
      this.items[index - 1].read = true
      this.selected = JSON.parse(JSON.stringify(notification))
    },
    toogleList () {
      this.listIsOpen = !this.listIsOpen
    }
  }
}
</script>
