import { saveAs } from 'file-saver';
export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    candidates ({ commit, dispatch }) {
      const { $axios } = this.state;

      return $axios.get('/export/candidates', { responseType: 'blob' }).then((response) => {
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'Candidatos.xlsx');
        dispatch('snackbar/showSnackbar', {
          message: 'Relatório gerado com sucesso.',
          secondsToClose: 5,
          type: 'success'
        }, { root: true })
        return response;
      }).catch(error => {
        if (!error.parsedErrorText) console.error(error)

        dispatch('snackbar/showSnackbar', {
          message: error.parsedErrorText || 'Falha ao exportar relatório de candidatos',
          secondsToClose: 5,
          type: 'warn'
        }, { root: true })

        return Promise.reject(error)
      })
    },
    candidatesByPipeline ({ commit, dispatch }, pipelineId) {
      const { $axios } = this.state;

      return $axios.get(`/export/candidates/pipeline/${pipelineId}`, { responseType: 'blob' }).then((response) => {
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'Candidatos.xlsx');
        dispatch('snackbar/showSnackbar', {
          message: 'Relatório gerado com sucesso.',
          secondsToClose: 5,
          type: 'success'
        }, { root: true })
        return response
      }).catch(error => {
        if (!error.parsedErrorText) console.error(error)

        dispatch('snackbar/showSnackbar', {
          message: error.parsedErrorText || 'Falha ao exportar relatório de candidatos',
          secondsToClose: 5,
          type: 'warn'
        }, { root: true })

        return Promise.reject(error)
      })
    },
  }
}
