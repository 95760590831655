<template>
  <div class="tab-content">
    <template v-if="stage === 'Negócios' || stage === 'Projetos' || stage === 'Placeds'">
      <div class="d-flex pa-2 gap-8 bg-contrast overflow-auto custom-scroll">
        <button class="btn outlined xs" @click="scrollTo('enderecos')">
          Endereços
        </button>
        <button class="btn outlined xs" @click="scrollTo('sobre')">
          Sobre
        </button>
        <button class="btn outlined xs" @click="scrollTo('perfil')">
          Perfil
        </button>
        <button class="btn outlined xs" @click="scrollTo('idiomas')">
          Idiomas
        </button>
        <button class="btn outlined xs" @click="scrollTo('competencias')">
          Competências
        </button>
        <button class="btn outlined xs" @click="scrollTo('formacao')">
          Formação
        </button>
        <button class="btn outlined xs" @click="scrollTo('entregas')">
          Entregas
        </button>
        <button class="btn outlined xs" @click="scrollTo('script-e-roteiro')">
          Script e Roteiro
        </button>
        <button class="btn outlined xs" @click="scrollTo('beneficios')">
          Benefícios
        </button>
        <button class="btn outlined xs" @click="scrollTo('pares')">
          Pares
        </button>
        <button class="btn outlined xs" @click="scrollTo('equipe')">
          Equipe
        </button>
      </div>
      <span class="divider"></span>
      <div class="scrollable" v-if="selectedCompany">
        <div class="pa-3" ref="enderecos">
          <div class="subheader mb-2">Endereços</div>
          <div>
            <template v-if="currentCompany.adresses.length">
              <div class="paragraph xs">Selecione o(s) endereço(s) da vaga</div>
              <div class="list list-with-shadow mt-2" v-for="address in currentCompany.adresses" :key="address.id">
                <b-checkbox class="list-item invert" :id="address.id" v-model="addresses" :inputValue="address.id">
                  <template #label>
                    <em class="fal fa-map-marker-alt text-color-light mr-2"></em>
                    <strong class="paragraph xs mr-2">{{
                      address.description
                    }}</strong>
                    <div class="paragraph xs flex-grow-1">
                      <div class="truncate truncate-after lines-1 text-color-light">
                        {{ address.address }}
                      </div>
                    </div>
                  </template>
                </b-checkbox>
              </div>
            </template>
            <div class="paragraph xs" v-else>
              Não há nenhum endereço cadastrado na empresa
            </div>
          </div>
        </div>
        <span class="divider"></span>
        <div class="pa-3" ref="sobre">
          <div class="subheader mb-2">Sobre a vaga</div>
          <b-wysiwyg label="Escolha um texto sobre a vaga" sublabel="Opcional" v-model="description"></b-wysiwyg>
        </div>
        <span class="divider"></span>
        <div class="pa-3" ref="perfil">
          <div class="subheader mb-2">Perfil</div>
          <b-textfield label="Experiência exigida" sublabel="Opcional" :multiline="true" v-model="experience" />
          <b-textfield label="Responsabilidades" sublabel="Opcional" :multiline="true" v-model="responsibility" />
          <b-textfield label="Desafios" sublabel="Opcional" :multiline="true" v-model="challenges" />
        </div>
        <span class="divider"></span>
        <div class="pa-3" ref="idiomas">
          <div class="subheader">Idiomas</div>
          <div class="d-flex justify-end">
            <button class="btn btn-coral outlined sm" @click="toggleDialog('language')">
              <em class="fal fa-plus"></em>
              <span>Adicionar</span>
            </button>
          </div>
          <div class="list list-with-shadow mt-2">
            <div v-for="record in parsedLanguages" :key="record.languageId" class="list-item">
              <em class="fal fa-language"></em>
              <div class="paragraph xs text-color-light">
                {{ record.language }}
              </div>
              <div class="paragraph xs flex-grow-1">{{ record.level }}</div>
              <button class="icon text" @click="removeLanguage(record.languageId)">
                <em class="fal fa-trash"></em>
              </button>
            </div>
          </div>
        </div>
        <span class="divider"></span>
        <div class="pa-3" ref="competencias">
          <div class="subheader">Competências técnicas</div>
          <div class="d-flex justify-end mb-2">
            <button class="btn btn-coral outlined sm" @click="openSkillList">
              <em class="fal fa-plus"></em>
              <span>Adicionar</span>
            </button>
          </div>
          <div class="list list-with-shadow">
            <div v-for="record in parsedSkills" :key="record.id" class="list-item">
              <em class="fal fa-pen-ruler"></em>
              <div class="paragraph xs flex-grow-1 text-color-light">
                {{ record.name }}
              </div>
              <button class="icon text" @click="removeSkill(record.id)">
                <em class="fal fa-trash"></em>
              </button>
            </div>
          </div>
        </div>
        <span class="divider"></span>
        <div class="pa-3">
          <div class="subheader">Competências comportamentais</div>
          <div class="d-flex justify-end mb-2">
            <button class="btn btn-coral outlined sm" @click="openSoftSkillList">
              <em class="fal fa-plus"></em>
              <span>Adicionar</span>
            </button>
          </div>
          <div class="list list-with-shadow">
            <div v-for="record in parsedSoftSkills" :key="record.id" class="list-item">
              <em class="fal fa-person-running"></em>
              <div class="paragraph xs flex-grow-1 text-color-light">
                {{ record.name }}
              </div>
              <button class="icon text" @click="removeSoftSkill(record.id)">
                <em class="fal fa-trash"></em>
              </button>
            </div>
          </div>
        </div>
        <span class="divider"></span>
        <div class="pa-3" ref="formacao">
          <div class="subheader">Formação</div>
          <div class="d-flex justify-end mb-2">
            <button class="btn btn-coral outlined sm" @click="toggleDialog('schooling')">
              <em class="fal fa-plus"></em>
              <span>Adicionar</span>
            </button>
          </div>
          <div class="list list-with-shadow">
            <div v-for="record in parsedSchoolings" :key="record.schoolingId" class="list-item">
              <em class="fal" :class="record.iconClass"></em>
              <div class="paragraph xs text-color-light">{{ record.type }}</div>
              <div class="paragraph xs flex-grow-1">{{ record.schooling }}</div>
              <button class="icon text" @click="removeSchooling(record.schoolingId)">
                <em class="fal fa-trash"></em>
              </button>
            </div>
          </div>
        </div>
        <span class="divider"></span>
        <div class="pa-3" ref="entregas">
          <div class="subheader mb-2">Entregas Combinadas</div>
          <b-wysiwyg label="Escreva um texto com as entregas combinadas com o cliente" sublabel="Opcional"
            v-model="deliveries"></b-wysiwyg>
        </div>
        <span class="divider"></span>
        <div class="pa-3" ref="script-e-roteiro">
          <div class="subheader mb-2">Script de Abordagem</div>
          <b-wysiwyg label="Escreva um script para a abordagem dos candidatos" sublabel="Opcional"
            v-model="approachScript"></b-wysiwyg>
        </div>
        <span class="divider"></span>
        <div class="pa-3">
          <div class="subheader mb-2">Roteiro de Entrevista</div>
          <b-wysiwyg label="O que falar" sublabel="Opcional" class="mb-2" v-model="interviewText"></b-wysiwyg>
          <b-wysiwyg label="O que não falar" sublabel="Opcional" v-model="interviewAvoidText"></b-wysiwyg>
        </div>
        <span class="divider"></span>
        <div class="pa-3" ref="beneficios">
          <div class="subheader mb-2">Remuneração</div>
          <div class="row">
            <div class="col-xs-12">
              <b-textfield label="Salário" placeholder="R$" v-model="salary" type="money" />
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-6">
              <b-textfield label="Bônus" sublabel="Opcional" v-model="bonus" type="money" />
            </div>
            <div class="col-xs-12 col-sm-6">
              <b-textfield label="Periodicidade do bônus" sublabel="Opcional" v-model="bonusPeriodicity" />
            </div>
          </div>
          <span class="divider"></span>
          <div class="pa-2 d-flex align-center">
            <div class="flex-grow-1 paragraph xs">Outros incentivos</div>
            <b-switch v-model="hasOtherBonus" id="incentivos" />
          </div>
          <div v-if="hasOtherBonus">
            <span class="divider mb-2"></span>
            <b-textfield label="Incentivos" sublabel="Opcional" :multiline="true" v-model="otherBonusDescription" />
          </div>
          <span class="divider"></span>
          <div class="pa-2 d-flex align-center">
            <div class="flex-grow-1 paragraph xs">Benefícios</div>
            <b-switch v-model="hasBenefits" id="beneficios" />
          </div>
          <div v-if="hasBenefits">
            <span class="divider mb-2"></span>
            <b-textfield label="Descrição dos benefícios" sublabel="Opcional" :multiline="true"
              v-model="benefitsDescription" />
          </div>
        </div>
        <span class="divider"></span>
        <div class="pa-3" ref="pares">
          <div class="subheader">Pares</div>
          <div class="d-flex justify-end mb-2">
            <button class="btn btn-coral outlined sm" @click="addPair">
              <em class="fal fa-plus"></em>
              <span>Adicionar</span>
            </button>
          </div>
          <span class="divider" v-if="pairs.length"></span>
          <div class="mt-2">
            <div v-for="(pair, i) in pairs" class="d-flex align-center gap-16 pb-2"
              :key="`pair-${pair.timestamp}-${pair.id}`">
              <b-textfield placeholder="Cargo" class="flex-grow-1 mb-xs" v-model="pair.name" />
              <div class="number-box">
                <button class="btn icon sm" @click="checkValue(pair, i)">
                  <em class="fal fa-minus"></em>
                </button>
                <b-textfield type="number" readonly v-model="pair.quantity" />
                <button class="btn icon sm" @click="pair.quantity++">
                  <em class="fal fa-plus"></em>
                </button>
              </div>
            </div>
          </div>
        </div>
        <span class="divider"></span>
        <div class="pa-3" ref="equipe">
          <div class="subheader mb-2">Equipe</div>
          <div class="d-flex align-center pb-2">
            <div class="flex-grow-1 paragraph xs">Tamanho da equipe</div>
            <div class="number-box">
              <button class="btn icon sm" @click="removeTeam()">
                <em class="fal fa-minus"></em>
              </button>
              <b-textfield type="number" readonly v-model="teams.length" />
              <button class="btn icon sm" @click="addTeam()">
                <em class="fal fa-plus"></em>
              </button>
            </div>
          </div>
          <span class="divider" v-if="teams.length"></span>
          <div v-for="team in teams" class="mt-2" :key="`team-${team.timestamp}-${team.id}`">
            <b-textfield placeholder="Cargo" class="mb-xs" v-model="team.name" />
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <Empty image="negocios" title="Cadastre os dados da vaga" :details="details"
        text="Para cadastrar dados da vaga mude a fase deste <strong>Prospect</strong> para <strong>Negócio</strong>."
        button="Negócio" icon="badge-dollar" color="turquesa" @close="$emit('close')"
        @openDrawerToBusiness="$emit('openDrawerToBusiness')" />
    </template>

    <b-dialog size="lg" :dismissable="false" v-model="dialog.skillsList">
      <template #header>
        <div class="btn icon btn-coral no-hover">
          <em class="fal fa-pen-ruler"></em>
        </div>
        <div class="flex-grow-1 h4 ml-2 mr-2 text-color-default">
          Competências Técnicas
        </div>
        <button class="btn text icon" @click="toggleDialog('skillsList')">
          <em class="fal fa-times"></em>
        </button>
      </template>
      <template #body>
        <div class="pa-1 d-flex gap-8">
          <b-textfield placeholder="Buscar" name="search" type="search" v-model="forms.skillsList.filter"
            appendIcon="fal fa-search" class="sm mb-0 flex-grow-1" />
          <button class="btn btn-coral sm" @click="toggleDialog('skill')">
            <em class="fal fa-user-plus"></em>
            <span>Novo</span>
          </button>
        </div>
        <span class="divider"></span>
        <div class="list list-with-divider bg-contrast">
          <div v-for="skill in filteredSkills" :key="skill.id" class="list-item">
            <b-checkbox v-model="forms.skillsList.selected" :inputValue="skill.id" :id="`skill-${skill.id}`"
              class="invert pb-2 pt-2">
              <template #label>
                <div class="flex-grow-1" v-text="skill.name"></div>
              </template>
            </b-checkbox>
          </div>
        </div>
      </template>
      <template #footer>
        <button class="btn outlined sm" @click="toggleDialog('skillsList')">
          Cancelar
        </button>
        <button class="btn btn-coral sm" @click="changeSkillList">
          Salvar
        </button>
      </template>
    </b-dialog>

    <b-dialog size="lg" :dismissable="false" v-model="dialog.softSkillsList">
      <template #header>
        <div class="btn icon btn-coral no-hover">
          <em class="fal fa-pen-ruler"></em>
        </div>
        <div class="flex-grow-1 h4 ml-2 mr-2 text-color-default">
          Competências Comportamentais
        </div>
        <button class="btn text icon" @click="toggleDialog('softSkillsList')">
          <em class="fal fa-times"></em>
        </button>
      </template>
      <template #body>
        <div class="pa-1 d-flex gap-8">
          <b-textfield placeholder="Buscar" name="search" type="search" v-model="forms.softSkillsList.filter"
            appendIcon="fal fa-search" class="sm mb-0 flex-grow-1" />
          <button class="btn btn-coral sm" @click="toggleDialog('softSkill')">
            <em class="fal fa-user-plus"></em>
            <span>Novo</span>
          </button>
        </div>
        <span class="divider"></span>
        <div class="list list-with-divider bg-contrast">
          <div v-for="skill in filteredSoftSkills" :key="skill.id" class="list-item">
            <b-checkbox v-model="forms.softSkillsList.selected" :inputValue="skill.id" :id="`soft-skill-${skill.id}`"
              class="invert pb-2 pt-2">
              <template #label>
                <div class="flex-grow-1" v-text="skill.name"></div>
              </template>
            </b-checkbox>
          </div>
        </div>
      </template>
      <template #footer>
        <button class="btn outlined sm" @click="toggleDialog('softSkillsList')">
          Cancelar
        </button>
        <button class="btn btn-coral sm" @click="changeSoftSkillList">
          Salvar
        </button>
      </template>
    </b-dialog>

    <b-dialog size="md" :dismissable="false" v-model="dialog.language">
      <template #header>
        <div class="btn icon btn-coral no-hover">
          <em class="fal fa-language"></em>
        </div>
        <div class="flex-grow-1 h4 ml-2 mr-2 text-color-default">Idioma</div>
        <button class="btn text icon" @click="toggleDialog('language')">
          <em class="fal fa-times"></em>
        </button>
      </template>
      <template #body>
        <div class="pa-2 bg-default">
          <b-autocomplete placeholder="Selecione o idioma" :options="languageOptions" option-text="name" option-value="id"
            label="Idioma" v-model="forms.language.languageId" />
          <b-autocomplete placeholder="Selecione o nível" :options="languageLevelOptions" option-text="name"
            option-value="id" label="Nível" v-model="forms.language.languageLevelId" />
          <b-autocomplete placeholder="Selecione o requisito" :options="languageRequirementOptions" option-text="name"
            option-value="id" label="Requisito" v-model="forms.language.languageRequirementId" />
        </div>
      </template>
      <template #footer>
        <button class="btn outlined sm" @click="toggleDialog('language')">
          Cancelar
        </button>
        <button class="btn btn-coral sm" @click="addLanguage">Salvar</button>
      </template>
    </b-dialog>

    <b-dialog size="lg" :dismissable="false" v-model="dialog.skill">
      <template #header>
        <div class="btn icon btn-coral no-hover">
          <em class="fal fa-pen-ruler"></em>
        </div>
        <div class="flex-grow-1 h4 ml-2 mr-2 text-color-default">
          Nova Competência Técnica
        </div>
        <button class="btn text icon" @click="toggleDialog('skill')">
          <em class="fal fa-times"></em>
        </button>
      </template>
      <template #body>
        <div class="pa-2 bg-default">
          <b-textfield label="Nome" v-model="forms.skill.name" :hint="errors.skillError"></b-textfield>
        </div>
      </template>
      <template #footer>
        <button class="btn outlined sm" @click="toggleDialog('skill')">
          Cancelar
        </button>
        <button class="btn btn-coral sm" @click="createSkillRecord">
          Salvar
        </button>
      </template>
    </b-dialog>

    <b-dialog size="lg" :dismissable="false" v-model="dialog.softSkill">
      <template #header>
        <div class="btn icon btn-coral no-hover">
          <em class="fal fa-pen-ruler"></em>
        </div>
        <div class="flex-grow-1 h4 ml-2 mr-2 text-color-default">
          Nova Competência Comportamental
        </div>
        <button class="btn text icon" @click="toggleDialog('softSkill')">
          <em class="fal fa-times"></em>
        </button>
      </template>
      <template #body>
        <div class="pa-2 bg-default">
          <b-textfield label="Nome" v-model="forms.softSkill.name" :hint="errors.softSkillError"></b-textfield>
        </div>
      </template>
      <template #footer>
        <button class="btn outlined sm" @click="toggleDialog('softSkill')">
          Cancelar
        </button>
        <button class="btn btn-coral sm" @click="createSoftSkillRecord">
          Salvar
        </button>
      </template>
    </b-dialog>

    <b-dialog size="lg" :dismissable="false" v-model="dialog.schooling">
      <template #header>
        <div class="btn icon btn-coral no-hover">
          <em class="fal fa-graduation-cap"></em>
        </div>
        <div class="flex-grow-1 h4 ml-2 mr-2 text-color-default">Formação</div>
        <button class="btn text icon" @click="toggleDialog('schooling')">
          <em class="fal fa-times"></em>
        </button>
      </template>
      <template #body>
        <div class="pa-2 bg-default">
          <b-autocomplete placeholder="Selecione o tipo de curso" :options="schoolingTypeOptions" option-text="name"
            option-value="id" label="Tipo de curso" v-model="forms.schooling.schoolingTypeId" />
          <div class="d-flex">
            <b-autocomplete placeholder="Selecione o curso" :options="schoolingOptions" option-text="name"
              option-value="id" label="Curso" v-model="forms.schooling.schoolingId" />
            <button class="btn btn-coral outlined sm mt-2" @click="toggleDialog('skillAcademy')">
              <em class="fal fa-plus"></em>
            </button>
          </div>
        </div>
      </template>
      <template #footer>
        <button class="btn outlined sm" @click="toggleDialog('schooling')">
          Cancelar
        </button>
        <button class="btn btn-coral sm" @click="addSchooling">Salvar</button>
      </template>
    </b-dialog>

    <b-dialog size="md" :dismissable="false" v-model="dialog.skillAcademy">
      <template #header>
        <div class="btn icon btn-coral no-hover">
          <em class="fal fa-pen-ruler"></em>
        </div>
        <div class="flex-grow-1 h4 ml-2 mr-2 text-color-default">
          Nova Formação
        </div>
        <button class="btn text icon" @click="toggleDialog('skillAcademy')">
          <em class="fal fa-times"></em>
        </button>
      </template>
      <template #body>
        <div class="pa-2 bg-default">
          <b-textfield label="Nome" v-model="forms.skillAcademy.name" :hint="errors.skillAcademy"></b-textfield>
        </div>
      </template>
      <template #footer>
        <button class="btn outlined sm" @click="toggleDialog('skillAcademy')">
          Cancelar
        </button>
        <button class="btn btn-coral sm" @click="createSkillAcademyForm">
          Salvar
        </button>
      </template>
    </b-dialog>

    <b-confirm-delete ref="confirm"></b-confirm-delete>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Empty from './Empty.vue'

export default {
  name: 'VagaComponent',
  props: ['stage', 'details'],
  components: { Empty },
  data () {
    return {
      dialog: {
        language: false,
        skill: false,
        softSkill: false,
        schooling: false,
        skillsList: false,
        softSkillsList: false,
        skillAcademy: false
      },
      forms: {
        language: {
          languageId: '',
          languageLevelId: '',
          languageRequirementId: ''
        },
        skill: {
          name: ''
        },
        softSkill: {
          name: ''
        },
        schooling: {
          schoolingTypeId: '',
          schoolingId: ''
        },
        skillsList: {
          selected: [],
          filter: ''
        },
        softSkillsList: {
          selected: [],
          filter: ''
        },
        skillAcademy: {
          name: ''
        }
      },
      errors: {
        skillError: '',
        softSkillError: '',
        schoolingType: '',
        schooling: '',
        skillAcademy: ''
      }
    }
  },
  watch: {
    'dialog.language' (close) {
      if (!close) this.resetForms('language')
    },
    'dialog.skill' (close) {
      if (!close) this.resetForms('skill')
    },
    'dialog.softSkill' (close) {
      if (!close) this.resetForms('softSkill')
    },
    'dialog.schooling' (close) {
      if (!close) this.resetForms('schooling')
    },
    'dialog.skillsList' (close) {
      if (!close) this.resetForms('skillsList')
    },
    'dialog.softSkillsList' (close) {
      if (!close) this.resetForms('softSkillsList')
    }
  },
  methods: {
    ...mapActions({
      loadJobOptions: 'jobs/loadJobOptions',
      loadPipelineJobData: 'jobs/loadPipelineJobData',
      createSkill: 'jobs/createSkill',
      createSoftSkill: 'jobs/createSoftSkill',
      resetData: 'jobs/resetData',
      createSkillAcademy: 'jobs/createSkillAcademy',
      showSnackbar: 'snackbar/showSnackbar'
    }),
    scrollTo (id) {
      this.$nextTick(() => {
        this.$refs[id].scrollIntoView({ behavior: 'smooth' })
      })
    },
    addPair () {
      const timestamp = new Date().getTime().toString()
      const pair = { id: null, name: '', quantity: 1, timestamp }
      this.pairs = [...this.pairs, pair]
    },
    addTeam () {
      const timestamp = new Date().getTime().toString()
      const team = { id: null, name: '', timestamp }
      this.teams = [...this.teams, team]
    },
    removeTeam () {
      this.teams = this.teams.slice(1, this.teams.length)
    },
    resetForms (section = null) {
      if (!section || section === 'language') {
        this.forms.language.languageId = ''
        this.forms.language.languageLevelId = ''
        this.forms.language.languageRequirementId = ''
      }

      if (!section || section === 'skill') this.forms.skill.name = ''

      if (!section || section === 'softSkill') this.forms.softSkill.name = ''

      if (!section || section === 'schooling') {
        this.forms.schooling.schoolingTypeId = ''
        this.forms.schooling.schoolingId = ''
        this.forms.skillAcademy.name = ''
      }

      if (!section || section === 'skillList') {
        this.forms.skillsList.selected = []
        this.forms.skillsList.filter = ''
      }

      if (!section || section === 'softSkillsList') {
        this.forms.softSkillsList.selected = []
        this.forms.softSkillsList.filter = ''
      }
    },
    toggleDialog (dialog) {
      this.dialog[dialog] = !this.dialog[dialog]
    },
    openSkillList () {
      this.forms.skillsList.selected = Object.assign([], this.skills)
      this.toggleDialog('skillsList')
    },
    openSoftSkillList () {
      this.forms.softSkillsList.selected = Object.assign([], this.softSkills)
      this.toggleDialog('softSkillsList')
    },
    addLanguage () {
      const languageToAdd = Object.assign({}, this.forms.language)

      const { languageId, languageLevelId, languageRequirementId } =
        languageToAdd

      const alreadyAdded = this.languages.filter(
        (language) =>
          language.languageId === languageId &&
          language.languageLevelId === languageLevelId &&
          language.languageRequirementId === languageRequirementId
      ).length

      if (alreadyAdded) return this.toggleDialog('language')

      this.languages = [...this.languages, languageToAdd]

      this.toggleDialog('language')
    },
    addSchooling () {
      if (!this.forms.schooling.schoolingId) {
        this.showSnackbar({
          message: 'Campo curso é obrigatório',
          secondsToClose: 5,
          type: 'error'
        })
        return
      }

      if (!this.forms.schooling.schoolingTypeId) {
        this.showSnackbar({
          message: 'Campo tipo de curso é obrigatório',
          secondsToClose: 5,
          type: 'error'
        })
        return
      }

      this.errors.schooling = ''

      const schoolingToAdd = Object.assign({}, this.forms.schooling)

      const { schoolingId, schoolingTypeId } = schoolingToAdd

      const alreadyAdded = this.schooling.filter(
        (schooling) =>
          schooling.schoolingId === schoolingId &&
          schooling.schoolingTypeId === schoolingTypeId
      ).length

      if (alreadyAdded) return this.toggleDialog('schooling')

      this.schooling = [...this.schooling, schoolingToAdd]

      this.toggleDialog('schooling')
    },
    createSkillRecord () {
      if (
        this.skillsOptions.filter(
          (skill) =>
            skill.name.toLowerCase() === this.forms.skill.name.toLowerCase()
        ).length > 0
      ) {
        this.errors.skillError =
          'Já existe uma competência cadastrada com esse nome'
        return
      }

      this.errors.skillError = ''
      this.createSkill(this.forms.skill.name)
      this.dialog.skill = false
    },
    createSoftSkillRecord () {
      if (
        this.softSkillsOptions.filter(
          (skill) =>
            skill.name.toLowerCase() === this.forms.softSkill.name.toLowerCase()
        ).length > 0
      ) {
        this.errors.softSkillError =
          'Já existe uma competência cadastrada com esse nome'
        return
      }

      this.errors.softSkillError = ''
      this.createSoftSkill(this.forms.softSkill.name)
      this.dialog.softSkill = false
    },
    changeSkillList () {
      this.skills = Object.assign([], this.forms.skillsList.selected)
      this.toggleDialog('skillsList')
    },
    changeSoftSkillList () {
      this.softSkills = Object.assign([], this.forms.softSkillsList.selected)
      this.toggleDialog('softSkillsList')
    },
    removeLanguage (languageId) {
      this.languages = this.languages.filter(
        (language) => language.languageId !== languageId
      )
    },
    async createSkillAcademyForm () {
      if (!this.forms.skillAcademy.name) {
        this.errors.skillAcademy = 'Nome da formação é obrigatório'
        return
      }
      this.errors.skillAcademy = ''
      await this.createSkillAcademy({ name: this.forms.skillAcademy.name })
      await this.loadJobOptions()
      this.toggleDialog('skillAcademy')
    },
    removeSchooling (schoolingId) {
      this.schooling = this.schooling.filter(
        (schooling) => schooling.schoolingId !== schoolingId
      )
    },
    removeSkill (skillId) {
      this.skills = this.skills.filter((record) => record !== skillId)
    },
    removeSoftSkill (softSkillId) {
      this.softSkills = this.softSkills.filter(
        (record) => record !== softSkillId
      )
    },
    async checkValue (data, index) {
      console.info(data, index)
      if (this.pairs[index].quantity > 1) {
        this.pairs[index].quantity--
      } else {
        const ok = await this.$refs.confirm.show({
          message: 'Tem certeza que deseja exluir este item?',
          button: 'Excluir',
          buttonColor: 'danger'
        })
        if (ok) {
          this.pairs.splice(index, 1)
          this.$refs.confirm.close()
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      currentCompany: 'company/currentCompany',
      schoolingOptions: 'jobs/schooling',
      schoolingTypeOptions: 'jobs/schoolingType',
      skillsOptions: 'jobs/skills',
      softSkillsOptions: 'jobs/softSkills',
      languageOptions: 'jobs/language',
      languageLevelOptions: 'jobs/languageLevel',
      languageRequirementOptions: 'jobs/languageRequirement',
      jobLevelOptions: 'jobs/jobLevel',
      currentStep: 'session/currentStep'
    }),
    filteredSkills () {
      const list = Object.assign([], this.skillsOptions)

      list.sort((a, b) => {
        if (a.name > b.name) return 1
        if (a.name < b.name) return -1
        return 0
      })

      const filter = this.forms.skillsList.filter.trim().toUpperCase()

      if (!filter) return list

      return list.filter((skill) =>
        skill.name.trim().toUpperCase().includes(filter)
      )
    },
    filteredSoftSkills () {
      const list = Object.assign([], this.softSkillsOptions)

      list.sort((a, b) => {
        if (a.name > b.name) return 1
        if (a.name < b.name) return -1
        return 0
      })

      const filter = this.forms.softSkillsList.filter.trim().toUpperCase()

      if (!filter) return list

      return list.filter((skill) =>
        skill.name.trim().toUpperCase().includes(filter)
      )
    },
    selectedCompany () {
      return this.currentCompany
    },
    parsedLanguages () {
      const records = []

      this.languages.forEach((record) => {
        const [language] = this.languageOptions.filter(
          (language) => language.id === record.languageId
        )
        const [level] = this.languageLevelOptions.filter(
          (level) => level.id === record.languageLevelId
        )
        const [requirement] = this.languageRequirementOptions.filter(
          (requirement) => requirement.id === record.languageRequirementId
        )

        records.push({
          languageId: record.languageId,
          language: language.name,
          level: level.name,
          requirement: requirement.name
        })
      })

      return records
    },
    parsedSchoolings () {
      const records = []

      this.schooling.forEach((record) => {
        const [schooling] = this.schoolingOptions.filter(
          (schooling) => schooling.id === record.schoolingId
        )
        const [type] = this.schoolingTypeOptions.filter(
          (type) => type.id === record.schoolingTypeId
        )

        records.push({
          schoolingId: record.schoolingId,
          schooling: schooling.name,
          type: type.name,
          iconClass:
            type.name.trim() === 'Técnico'
              ? 'fa-file-certificate'
              : 'fa-graduation-cap'
        })
      })

      return records
    },
    parsedSkills () {
      return this.skillsOptions.filter((skill) =>
        this.skills.includes(skill.id)
      )
    },
    parsedSoftSkills () {
      return this.softSkillsOptions.filter((softSkill) =>
        this.softSkills.includes(softSkill.id)
      )
    },
    addresses: {
      get () {
        return this.$store.state.jobs.form.addresses
      },
      set (value) {
        this.$store.commit('jobs/SET_FORM_ADRESSESS', value)
      }
    },
    description: {
      get () {
        return this.$store.state.jobs.form.description
      },
      set (value) {
        this.$store.commit('jobs/SET_FORM_DESCRIPTION', value)
      }
    },
    jobLevelId: {
      get () {
        return this.$store.state.jobs.form.jobLevelId
      },
      set (value) {
        this.$store.commit('jobs/SET_FORM_JOB_LEVEL_ID', value)
      }
    },
    experience: {
      get () {
        return this.$store.state.jobs.form.experience
      },
      set (value) {
        this.$store.commit('jobs/SET_FORM_EXPERIENCE', value)
      }
    },
    responsibility: {
      get () {
        return this.$store.state.jobs.form.responsibility
      },
      set (value) {
        this.$store.commit('jobs/SET_FORM_RESPONSIBILITY', value)
      }
    },
    challenges: {
      get () {
        return this.$store.state.jobs.form.challenges
      },
      set (value) {
        this.$store.commit('jobs/SET_FORM_CHALLENGES', value)
      }
    },
    languages: {
      get () {
        return this.$store.state.jobs.form.languages
      },
      set (value) {
        this.$store.commit('jobs/SET_FORM_LANGUAGES', value)
      }
    },
    skills: {
      get () {
        return this.$store.state.jobs.form.skills
      },
      set (value) {
        this.$store.commit('jobs/SET_FORM_SKILLS', value)
      }
    },
    softSkills: {
      get () {
        return this.$store.state.jobs.form.softSkills
      },
      set (value) {
        this.$store.commit('jobs/SET_FORM_SOFT_SKILLS', value)
      }
    },
    schooling: {
      get () {
        return this.$store.state.jobs.form.schooling
      },
      set (value) {
        this.$store.commit('jobs/SET_FORM_SCHOOLING', value)
      }
    },
    deliveries: {
      get () {
        return this.$store.state.jobs.form.deliveries
      },
      set (value) {
        this.$store.commit('jobs/SET_FORM_DELIVERIES', value)
      }
    },
    approachScript: {
      get () {
        return this.$store.state.jobs.form.approachScript
      },
      set (value) {
        this.$store.commit('jobs/SET_FORM_APPROACH_SCRIPT', value)
      }
    },
    interviewText: {
      get () {
        return this.$store.state.jobs.form.interviewText
      },
      set (value) {
        this.$store.commit('jobs/SET_FORM_INTERVIEW_TEXT', value)
      }
    },
    interviewAvoidText: {
      get () {
        return this.$store.state.jobs.form.interviewAvoidText
      },
      set (value) {
        this.$store.commit('jobs/SET_FORM_INTERVIEW_AVOID_TEXT', value)
      }
    },
    salary: {
      get () {
        return this.$store.state.jobs.form.salary
      },
      set (value) {
        this.$store.commit('jobs/SET_FORM_SALARY', value)
      }
    },
    bonus: {
      get () {
        return this.$store.state.jobs.form.bonus
      },
      set (value) {
        this.$store.commit('jobs/SET_FORM_BONUS', value)
      }
    },
    bonusPeriodicity: {
      get () {
        return this.$store.state.jobs.form.bonusPeriodicity
      },
      set (value) {
        this.$store.commit('jobs/SET_FORM_BONUS_PERIODICITY', value)
      }
    },
    hasOtherBonus: {
      get () {
        return this.$store.state.jobs.form.hasOtherBonus
      },
      set (value) {
        this.$store.commit('jobs/SET_FORM_HAS_OTHER_BONUS', value)
      }
    },
    otherBonusDescription: {
      get () {
        return this.$store.state.jobs.form.otherBonusDescription
      },
      set (value) {
        this.$store.commit('jobs/SET_FORM_OTHER_BONUS_DESCRIPTION', value)
      }
    },
    hasBenefits: {
      get () {
        return this.$store.state.jobs.form.hasBenefits
      },
      set (value) {
        this.$store.commit('jobs/SET_FORM_HAS_BENEFITS', value)
      }
    },
    benefitsDescription: {
      get () {
        return this.$store.state.jobs.form.benefitsDescription
      },
      set (value) {
        this.$store.commit('jobs/SET_FORM_BENEFITS_DESCRIPTION', value)
      }
    },
    pairs: {
      get () {
        return this.$store.state.jobs.form.pairs
      },
      set (value) {
        this.$store.commit('jobs/SET_FORM_PAIRS', value)
      }
    },
    teams: {
      get () {
        return this.$store.state.jobs.form.teams
      },
      set (value) {
        this.$store.commit('jobs/SET_FORM_TEAMS', value)
      }
    }
  },
  mounted () {
    this.loadJobOptions()
    if (
      this.$props.details.id &&
      this.currentStep !== 1 &&
      this.currentStep !== 2
    ) {
      this.loadPipelineJobData(this.$props.details.id).catch(this.resetData)
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog {
  min-width: 390px;
}
</style>
