<template>
  <div class="list-item activity"
    :class="{ future: isFutureDate(activity.start) && !activity.conclusionAt, late: !isFutureDate(activity.start) && !activity.conclusionAt }">
    <em class="fal icon" :class="activityTypeClass(activity.activityTypeId)"></em>
    <div class="flex-grow-1 xs" v-text="activity.title"></div>
    <div class="date xs" v-text="timeToEvent(activity.start)"></div>
    <div class="label-status bg-info" v-if="activity.company" v-text="activity.company.name"></div>
    <b-dropdown>
      <template v-slot:trigger="{ on }">
        <button class="icon" v-on="on">
          <em class="far fa-ellipsis-vertical"></em>
        </button>
      </template>
      <template #content>
        <div class="list">
          <button v-if="activity.microsoftCalendarLink" class="list-item"
            @click="openActivityExternalLink(activity.microsoftCalendarLink)">
            <em class="fal fa-external-link"></em>
            <span>Abrir</span>
          </button>
          <button v-if="!activity.conclusionAt" class="list-item" @click="setActivityConclusion(activity.id)">
            <em class="fal fa-check"></em>
            <span>Marcar como concluído</span>
          </button>
          <span v-if="activity.microsoftCalendarLink || !activity.conclusionAt" class="divider"></span>
          <button class="list-item text-color-danger" @click="deleteActivity(activity.id)">
            <em class="fal fa-trash text-color-danger"></em>
            <span>Excluir</span>
          </button>
        </div>
      </template>
    </b-dropdown>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { openWindow } from '@/helpers'

export default {
  name: 'AtividadeComponent',
  props: ['activity'],
  computed: {
    ...mapGetters({
      activityTypes: 'activities/types'
    })
  },
  methods: {
    ...mapActions({
      deleteActivity: 'activities/deleteActivity',
      setActivityConclusion: 'activities/setActivityConclusion',
      loadTypes: 'activities/loadTypes'
    }),
    isFutureDate (startDate) {
      if (!startDate) return false
      return new Date(startDate) > new Date()
    },
    activityTypeClass (activityTypeId) {
      const [activityType] = this.activityTypes.filter((type) => type.id === activityTypeId)

      let className = ''

      if (!activityType) return className

      switch (activityType.name.trim()) {
        case 'Ligação':
          className = 'fa-phone-alt'
          break
        case 'E-mail':
          className = 'fa-envelope'
          break
        case 'Mensagem':
          className = 'fa-comment-alt'
          break
        case 'Reunião presencial':
          className = 'fa-users'
          break
        case 'Videoconferência':
          className = 'fa-video'
          break
        case 'Tarefa':
          className = 'fa-tasks'
          break
        default:
          className = 'fa-question'
      }

      return className
    },
    openActivityExternalLink (link) {
      openWindow(link)
    },
    timeToEvent (startDate) {
      if (!startDate) return ''

      const start = new Date(startDate)
      const timeDifferenceInMilliseconds = start.getTime() - new Date().getTime()
      const time = `${start.getHours().toString().padStart(2, '0')}:${start.getMinutes().toString().padStart(2, '0')}`

      if (timeDifferenceInMilliseconds > 0) {
        const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1))

        const isTomorrow = start.toDateString() === tomorrow.toDateString()

        if (isTomorrow) return `amanhã às ${time}`
      }

      const hoursDifference = Math.floor(timeDifferenceInMilliseconds / 1000 / 60 / 60)

      if (hoursDifference > 24) return `${start.getDate().toString().padStart(2, '0')}/${(start.getMonth() + 1).toString().padStart(2, '0')}/${start.getFullYear()} às ${time}`

      if (hoursDifference > 0) return `hoje às ${time}`

      if (hoursDifference > -1) return 'há menos de uma hora'
      if (hoursDifference > -2) return 'há 1 hora'
      if (hoursDifference > -24) return `há ${Math.abs(hoursDifference)} horas`
      if (hoursDifference > -48) return `ontem às ${time}`

      const daysDifference = Math.floor(hoursDifference / 24)

      if (daysDifference > -7) return `há ${Math.abs(daysDifference)} dias`

      return `${start.getDate().toString().padStart(2, '0')}/${(start.getMonth() + 1).toString().padStart(2, '0')}/${start.getFullYear()}`
    }
  },
  mounted () {
    if (!this.activityTypes.length) this.loadTypes()
  }
}
</script>
