<template>
  <div class="form-group" :class="{ 'has-error': errorText || hint }" ref="formgroup">
    <div class="d-flex justify-between">
      <label :for="id" v-if="label" v-text="label"></label>
      <span v-if="sublabel" v-text="sublabel" class="f-sm text-color-light"></span>
    </div>
    <div class="input-group">
      <Trumbowyg v-model="model" :config="config" class="flex-grow-1" />
    </div>
    <span class="hint">{{ errorText || hint }}</span>
  </div>
</template>

<script>
import Trumbowyg from 'vue-trumbowyg'
import 'trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.css'
import 'trumbowyg/dist/plugins/colors/trumbowyg.colors.min.js'
import 'trumbowyg/dist/plugins/lineheight/trumbowyg.lineheight.min.js'
import 'trumbowyg/dist/ui/trumbowyg.css'

export default {
  name: 'Wysiwyg',
  components: { Trumbowyg },
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    sublabel: {
      type: String,
      default: ''
    },
    hint: {
      type: String,
      default: ''
    }
  },
  computed: {
    model: {
      get () {
        return this.modelValue || ''
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  data () {
    return {
      error: [],
      uud: Math.random().toString(16).slice(2),
      config: {
        lang: 'pt-BR',
        svgPath: '/images/icons.svg',
        tagsToRemove: ['script', 'link'],
        btns: [['lineheight', 'foreColor', 'backColor'], ['bold', 'italic'], ['orderedList', 'unorderedList']]
      }
    }
  }
}
</script>
