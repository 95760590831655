<template>
  <Teleport to="body">
    <div class="dialog" :class="{ active: model, scrollable }">
      <div class="dialog-content" :class="[size, {'no-line': noBorder }]">
        <slot name="top"></slot>
        <template v-if="$slots.header || title">
          <div class="dialog-content-header">
            <button v-if="dismissable" class="btn icon close onlytext f-size-20" @click="dismiss">
              <em class="fal fa-times"></em>
            </button>
            <template v-if="$slots.header">
              <slot name="header"></slot>
            </template>
            <template v-else>
              <h6 class="dialog-content-header-title text-uppercase">
                {{ title }}
              </h6>
            </template>
          </div>
          <span class="divider hr line"></span>
        </template>
        <slot name="header-bottom"></slot>
        <div class="dialog-content-body">
          <slot name="body"></slot>
        </div>
        <span class="divider hr line"></span>
        <div class="dialog-content-footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
export default {
  props: {
    modelValue: Boolean,
    title: String,
    dismissable: {
      type: Boolean,
      default: true
    },
    scrollable: {
      type: Boolean,
      default: false
    },
    noBorder: Boolean,
    size: {
      type: String,
      default: 'sm'
    }
  },
  computed: {
    model: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  methods: {
    dismiss () {
      this.model = false
    }
  }
}
</script>
