<template>
  <div class="form-group">
    <label :for="componentId" v-if="label">{{ label }}</label>
    <div class="input-group">
      <div class="input-field" :class="{ 'has-icon': icon }">
        <input v-model="model" :id="componentId" :placeholder="placeholder" type="text" readonly class="form-control"
          @click="open()" />
        <a v-if="icon" class="icon" @click="$emit('iconClick')">
          <em :class="icon"></em>
        </a>
      </div>
    </div>
    <Teleport to="body">
      <div class="dialog" :class="{ active: dialog }" @click.stop>
        <div class="dialog-content md">
          <div class="dialog-content-body bg-contrast pa-4">
            <div class="d-flex align-center justify-between pb-2">
              <button class="btn text" @click="decreaseMonth">
                <em class="fal fa-chevron-left"></em>
              </button>
              <b-dropdown x="right">
                <template v-slot:trigger="{ on }">
                  <button v-on="on" class="btn sm bg-default text-color-coral">
                    <span class="text-capitalize">{{ currentMonthName }}</span>
                    <em class="fal fa-chevron-down"></em>
                  </button>
                </template>
                <template #content>
                  <div class="list">
                    <button v-for="item in months" :key="item.name" class="list-item" :class="currentMonth === item.index
                      ? 'bg-coral text-color-white'
                      : ''
                      " @click="setMonth(item.index)">
                      <span class="text-capitalize">{{ item.name }}</span>
                    </button>
                  </div>
                </template>
              </b-dropdown>
              <b-dropdown x="right">
                <template v-slot:trigger="{ on }">
                  <button v-on="on" class="btn sm text">
                    <span>{{ currentYear }}</span>
                    <em class="fal fa-chevron-down"></em>
                  </button>
                </template>
                <template #content>
                  <div class="list">
                    <button v-for="item in years" :key="item" class="list-item" :class="currentYear === item ? 'bg-coral text-color-white' : ''
                      " @click="setYear(item)">
                      <span class="text-capitalize">{{ item }}</span>
                    </button>
                  </div>
                </template>
              </b-dropdown>
              <button class="btn text" @click="increaseMonth">
                <em class="fal fa-chevron-right"></em>
              </button>
            </div>
            <div :id="calendarId"></div>
          </div>
          <div class="dialog-content-footer">
            <button class="btn outlined flex-grow-1 sm" @click="close()">Cancelar</button>
            <button class="btn btn-coral flex-grow-1 sm" @click="setDate()">
              Selecionar
            </button>
          </div>
        </div>
      </div>
    </Teleport>
  </div>
</template>

<script>
import VanillaCalendar from '@uvarov.frontend/vanilla-calendar'
import moment from 'moment'
export default {
  name: 'DatePicker',
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    modelValue: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: 'fal fa-calendar'
    },
    iconClick: {
      type: Function,
      default: () => { }
    },
    label: {
      type: String,
      default: ''
    },
    startYear: {
      type: Number,
      default: new Date().getFullYear()
    },
    durationYears: {
      type: Number,
      default: 10
    }
  },
  data () {
    return {
      dialog: false,
      calendar: null,
      currentYear: new Date().getFullYear(),
      currentMonth: new Date().getMonth(),
      date: null
    }
  },
  mounted () {
    this.calendar = new VanillaCalendar(`#${this.calendarId}`, {
      settings: {
        visibility: {
          templateHeader: ''
        }
      },
      actions: {
        clickDay: (_, date) => {
          this.date = date[0]
        }
      }
    })
    this.calendar.settings.lang = 'pt-BR'
    this.calendar.init()
  },
  computed: {
    model: {
      get () {
        return this.modelValue ? moment(this.modelValue).format('DD/MM/YYYY') : ''
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    },
    calendarId () {
      return `calendar-container-${this.$.uid}`
    },
    componentId () {
      return this.id || `calendar-${this.$.uid}`
    },
    months () {
      return new Array(12).fill(0).map((_, i) => {
        return {
          name: moment().month(i).format('MMMM'),
          index: i
        }
      })
    },
    years () {
      return new Array(this.durationYears)
        .fill(0)
        .map((_, i) => this.startYear + i)
    },
    currentMonthName () {
      return moment().month(this.currentMonth).format('MMMM')
    }
  },
  methods: {
    open () {
      this.dialog = true
    },
    close () {
      this.dialog = false
    },
    increaseMonth () {
      const changeYear = this.currentMonth === 11

      this.currentMonth = changeYear ? 0 : this.currentMonth + 1

      if (changeYear) this.currentYear++

      this.calendar.settings.selected.year = this.currentYear

      this.calendar.settings.selected.month = this.currentMonth

      this.calendar.update()
    },
    decreaseMonth () {
      const changeYear = this.currentMonth === 0

      this.currentMonth = changeYear ? 11 : this.currentMonth - 1

      if (changeYear) this.currentYear--

      this.calendar.settings.selected.year = this.currentYear

      this.calendar.settings.selected.month = this.currentMonth

      this.calendar.update()
    },
    setMonth (value) {
      this.currentMonth = value
      this.calendar.settings.selected.month = this.currentMonth
      this.calendar.update()
    },
    setYear (value) {
      this.currentYear = value
      this.calendar.settings.selected.year = this.currentYear
      this.calendar.update()
    },
    setDate () {
      if (this.date) {
        const adjustedDate = moment(this.date)
        this.model = adjustedDate
      } else {
        this.model = null
      }
      this.close()
    }
  },
  watch: {
    currentMonth () {
      this.calendar.settings.selected.month = this.currentMonth
    },
    currentYear () {
      this.calendar.settings.selected.year = this.currentYear
    }
  }
}
</script>
