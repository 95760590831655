<template>
  <div>
    <div class="pa-2 card-body center">
      <apexchart
        :width="width"
        type="donut"
        :options="options"
        :series="values"
      ></apexchart>
    </div>
    <span class="divider"></span>
    <div class="pa-2 d-flex justify-center align-center gap-16">
      <div
        v-for="(label, i) in labels"
        :key="label"
        class="d-flex align-center gap-4"
      >
        <em class="fas fa-circle fs-8" :style="'color:' + _colors[i]"></em>
        <span class="paragraph xs text-color-light">{{ label }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DoughnutComponent',
  props: {
    width: {
      type: Number,
      default: 300
    },
    labels: {
      type: Array,
      default: () => []
    },
    colors: {
      type: Array,
      default: () => []
    },
    values: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      options: {
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val + '%'
          },
          style: {
            fontSize: '10px'
          },
          dropShadow: {
            enabled: false
          }
        },
        labels: this.labels,
        plotOptions: {
          pie: {
            donut: {
              size: '75%',
              labels: {
                show: false,
                name: {
                  show: true
                },
                value: {
                  show: true
                },
                total: {
                  show: true,
                  formatter: function () {
                    return '<em>Testando</em>'
                  }
                }
              }
            }
          }
        },
        legend: {
          show: false
        }
      }
    }
  },
  computed: {
    _colors() {
      return this.colors.length > 0
        ? this.colors
        : ['#A8A7D0', '#99B7B1', '#439F8E', '#D3AAA8', '#99B7B1']
    }
  },
  mounted() {
    this.options.colors = this._colors
  }
}
</script>
