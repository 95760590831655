export default {
  length (v, min, max) {
    if (min && max) {
      return v && v.length > min && v.length < max
    }
    if (min) {
      return v && v.length > min
    }
    if (max) {
      return v && v.length < max
    }
  },
  email (v) {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return regex.test(String(v).toLowerCase())
  },
  phone (v) {
    const regex = /^\\([0-9]{2}\\) ((3[0-9]{3}-[0-9]{5})|(9[0-9]{4}-[0-9]{4}))$/
    return regex.test(v)
  },
  url (v) {
    const regex = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator
    return regex.test(v)
  },
  cpf (strCPF) {
    if (!strCPF) {
      return false
    }
    strCPF = strCPF.match(/\d/g).join('')
    let Soma
    let Resto
    let i

    Soma = 0
    if (strCPF === '00000000000' || strCPF === '000.000.000-00') return false

    for (i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i)
    Resto = (Soma * 10) % 11

    if ((Resto === 10) || (Resto === 11)) Resto = 0
    if (Resto !== parseInt(strCPF.substring(9, 10))) return false

    Soma = 0
    for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i)
    Resto = (Soma * 10) % 11

    if ((Resto === 10) || (Resto === 11)) Resto = 0
    if (Resto !== parseInt(strCPF.substring(10, 11))) return false
    return true
  },
  city (v) {
    return v && v.length > 0
  },
  uf (v) {
    const regex = /^[\w]{2}$/
    return regex.test(String(v).toLowerCase())
  },
  serviceTermsAgreed (v) {
    return !!v
  },

  cnpj (cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, '')

    if (cnpj === '') return false

    if (cnpj.length !== 14) {
      return false
    }

    // Elimina CNPJs invalidos conhecidos
    if (cnpj === '00000000000000' ||
              cnpj === '11111111111111' ||
              cnpj === '22222222222222' ||
              cnpj === '33333333333333' ||
              cnpj === '44444444444444' ||
              cnpj === '55555555555555' ||
              cnpj === '66666666666666' ||
              cnpj === '77777777777777' ||
              cnpj === '88888888888888' ||
              cnpj === '99999999999999') { return false }

    // Valida DVs
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0, tamanho)
    const digitos = cnpj.substring(tamanho)
    let soma = 0
    let pos = tamanho - 7
    let i = 0
    for (i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--
      if (pos < 2) { pos = 9 }
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
    if (resultado !== parseInt(digitos.charAt(0))) { return false }

    tamanho = tamanho + 1
    numeros = cnpj.substring(0, tamanho)
    soma = 0
    pos = tamanho - 7
    for (i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--
      if (pos < 2) { pos = 9 }
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
    if (resultado !== parseInt(digitos.charAt(1))) { return false }

    return true
  },

  nascimento (v) {
    let data = v // pega o valor do input
    data = data.replace(/\//g, '-') // substitui eventuais barras (ex. IE) "/" por hífen "-"
    const dataArray = data.split('-') // quebra a data em array

    // para o IE onde será inserido no formato dd/MM/yyyy
    if (dataArray[0].length !== 4) {
      data = dataArray[2] + '-' + dataArray[1] + '-' + dataArray[0] // remonto a data no formato yyyy/MM/dd
    }

    // comparo as datas e calculo a idade
    const hoje = new Date()
    const nasc = new Date(data)
    let idade = hoje.getFullYear() - nasc.getFullYear()
    const m = hoje.getMonth() - nasc.getMonth()
    if (m < 0 || (m === 0 && hoje.getDate() < nasc.getDate())) idade--

    return idade > 10
  }
}
