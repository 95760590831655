<template>
  <div class="dialog" :class="{ active: dialog }">
    <div class="dialog-content md no-line">
      <div class="dialog-content-header bg-default pa-2">
        <div class="flex-grow-1 text-uppercase font-weight-600 text-color-light">Cargo</div>
      </div>
      <span class="divider"></span>
      <div class="dialog-content-body">
        <div class="pa-2 bg-contrast">
          <b-textfield placeholder="Preencha o cargo" class="mb-0" v-model="role" />
        </div>
      </div>
      <span class="divider"></span>
      <div class="dialog-content-footer justify-end">
        <button class="btn outlined sm" @click="close()">Cancelar</button>
        <button class="btn btn-coral sm" @click="save">Aplicar</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data () {
    return {
      dialog: false,
      companyId: null,
      contactId: null,
      recordId: null,
      type: null,
      role: ''
    }
  },
  methods: {
    ...mapActions({
      updateRole: 'company/updateRole',
      updateContactRole: 'company/updateContactRole',
      updateCouncilRole: 'company/updateCouncilRole'
    }),
    open ({ companyId, contactId, role, recordId }, type) {
      this.companyId = companyId
      this.contactId = contactId
      this.recordId = recordId
      this.role = role
      this.type = type
      this.dialog = true
    },
    async save () {
      const updateFunction = this.type === 'contact' ? this.updateContactRole : this.updateCouncilRole

      if (!this.recordId) {
        this.updateRole({
          contactId: this.contactId,
          role: this.role,
          type: this.type
        })
      }

      if (this.recordId) {
        await updateFunction({
          recordId: this.recordId,
          companyId: this.companyId,
          role: this.role
        })
      }

      this.close()
    },
    close () {
      this.companyId = null
      this.contactId = null
      this.recordId = null
      this.type = null
      this.role = ''
      this.dialog = false
    }
  }
}
</script>
