<template>
  <slot name="trigger" :on="{ click: on }"></slot>
  <Teleport to="body">
    <div
      v-if="show"
      class="dropdown"
      :class="`${size}`"
      ref="dropdown"
      v-click-outside="{
        element: event,
        handler: () => out(),
      }"
    >
      <div>
        <div v-if="!$slots.content" class="list">
          <template v-for="(item, i) in items" :key="i">
            <span v-if="item.divider" class="divider"></span>
            <a v-else class="list-item" @click="() => item.onClick">
              <em class="fal" :class="'fa-' + item.icon"></em>
              {{ item.text }}
            </a>
          </template>
        </div>
        <slot name="content"></slot>
      </div>
    </div>
  </Teleport>
</template>

<script>
import mixins from '@/mixins'
export default {
  name: 'DropdownComponent',
  mixins: [mixins],
  props: {
    items: {
      type: Array,
      default: () => []
    },
    x: {
      type: String,
      default: 'left'
    },
    y: {
      type: String,
      default: 'bottom'
    },
    size: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      show: false,
      position: {},
      event: null
    }
  },
  methods: {
    on (event) {
      this.show = !this.show
      if (event.target) {
        this.event = event.target
        this.position = event.target.getBoundingClientRect()
        this.$nextTick(() => {
          this.setPosition()
        })
      }
    },
    out () {
      this.show = false
    },
    setPosition () {
      if (!this.$refs.dropdown) return
      const { x, y, height, width } = this.position
      const { offsetWidth, offsetHeight } = this.$refs.dropdown
      const space = 2
      let left = x + 'px'
      let top = `${y + height + space}px`

      if (this.y === 'top') {
        top = y - (offsetHeight + space) + 'px'
      }
      if (this.y === 'bottom') {
        if (y + height + offsetHeight > window.innerHeight) {
          top = y - (offsetHeight + space) + 'px'
        }
      }
      if (this.x === 'left') {
        if (x + offsetWidth > window.innerWidth) {
          left = x - (offsetWidth + space) + 'px'
        }
      }
      if (this.x === 'right') {
        left = `${x - offsetWidth + width}px`
        left = x - offsetWidth + width <= 0 ? '75px' : left
      }
      if (this.$refs.dropdown) {
        this.$refs.dropdown.style.top = top
        this.$refs.dropdown.style.left = left
      }
    }
  }
}
</script>
