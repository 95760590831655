<template>
  <div class="pa-3 roteiro-content">
    <div class="whatToSay">
      <h6>O QUE FALAR:</h6>
      <div class="separator" />
      <Trumbowyg v-model="whatToSayContent" :config="config" @tbw-blur="updateInterview()" class="form-control"
        name="content" />
    </div>
    <div class="whatNotToSay">
      <h6>O QUE NÃO FALAR:</h6>
      <div class="separator" />
      <Trumbowyg v-model="whatToDontSayContent" :config="config" @tbw-blur="updateInterviewAvoid()" class="form-control"
        name="content" />
    </div>
  </div>
</template>

<script>
import Trumbowyg from 'vue-trumbowyg'
import 'trumbowyg/dist/ui/trumbowyg.css'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Roteiro',
  components: { Trumbowyg },
  data () {
    return {
      whatToSayContent: '',
      whatToDontSayContent: '',
      config: {
        btns: [
          ['bold', 'italic'],
          ['lineheight', 'foreColor', 'backColor'],
          ['orderedList', 'unorderedList']
        ],
        lang: 'pt-BR',
        svgPath: '/images/icons.svg',
        tagsToRemove: ['script', 'link', 'style', 'ol', 'li'],
        removeformatPasted: true
      }
    }
  },
  computed: {
    ...mapGetters({
      currentPipeline: 'jobs/currentPipeline',
      currentCandidate: 'jobs/currentCandidate'
    })
  },
  async mounted () {
    this.whatToSayContent = await this.loadInterviewScript({
      pipelineId: this.currentPipeline.id,
      contactId: this.currentCandidate.contactId
    })
    this.whatToDontSayContent = await this.loadInterviewAvoidScript({
      pipelineId: this.currentPipeline.id,
      contactId: this.currentCandidate.contactId
    })
  },
  methods: {
    ...mapActions({
      loadInterviewScript: 'jobs/loadInterviewScript',
      loadInterviewAvoidScript: 'jobs/loadInterviewAvoidScript',
      updateInterviewScript: 'jobs/updateInterviewScript',
      updateInterviewAvoidScript: 'jobs/updateInterviewAvoidScript'
    }),
    updateInterview () {
      this.updateInterviewScript({ pipelineId: this.currentPipeline.id, interviewText: this.whatToSayContent })
    },
    updateInterviewAvoid () {
      this.updateInterviewAvoidScript({ pipelineId: this.currentPipeline.id, interviewAvoidText: this.whatToDontSayContent })
    },
  }
}
</script>

<style lang="scss">
.roteiro-content {
  max-width: 100%;
  display: flex;

  /* gap: 24px; */
  >div {
    max-width: calc(50%);
    flex-grow: 1;
    background: var(--bg-contrast);
    padding: 32px;

    &.whatNotToSay h6 {
      color: var(--light-system-danger-100);
    }

    .separator {
      height: 1px;
      width: 100%;
      background: var(--border);
      margin: 16px 0;
    }
  }

  .trumbowyg-box {
    border: 0;
    background: transparent;
    padding-left: 0;
  }

  .trumbowyg-editor {
    padding-left: 0;
    min-height: 230px;
  }

  .trumbowyg-box .trumbowyg-button-pane {
    margin-bottom: 16px;
    border: 1px solid var(--border);
    border-bottom: 0;
    width: 100%;
  }

  .trumbowyg-editor-box {
    background: var(--bg-contrast);
    border: 0 !important;
    padding-left: 0;
  }
}
</style>
