<template>
  <div class="pa-2 d-flex-column">
    <div class="d-flex gap-8">
      <b-tooltip x="left">
        <template v-slot:activator="{ on }">
          <button v-on="on" class="btn icon sm" @click="$emit('toogleList')">
            <em
              class="fa-sidebar"
              :class="listIsOpen ? 'fas text-color-info' : 'fal'"
            ></em>
          </button>
        </template>
        {{ listIsOpen ? 'Fechar' : 'Abrir' }} barra lateral
      </b-tooltip>
      <span class="spacer"></span>
      <b-tooltip>
        <template v-slot:activator="{ on }">
          <button v-on="on" class="btn icon sm">
            <em class="fal fa-box-archive"></em>
          </button>
        </template>
        Arquivar
      </b-tooltip>
      <b-tooltip>
        <template v-slot:activator="{ on }">
          <button v-on="on" class="btn icon sm">
            <em class="fal fa-trash"></em>
          </button>
        </template>
        Excluir
      </b-tooltip>
      <b-dropdown x="right">
        <template v-slot:trigger="{ on }">
          <button v-on="on" class="btn icon sm text">
            <em class="fal fa-ellipsis-vertical"></em>
          </button>
        </template>
        <template #content>
          <div class="list">
            <button class="list-item">
              <em class="fal fa-eye"></em>
              <span>Marcar como não lida</span>
            </button>
            <button class="list-item">
              <em class="fal fa-box-archive"></em>
              <span>Arquivar</span>
            </button>
            <span class="divider"></span>
            <button class="list-item">
              <em class="fal fa-trash"></em>
              <span>Excluir</span>
            </button>
          </div>
        </template>
      </b-dropdown>
    </div>
    <div class="mt-2 mb-2">
      <div class="d-flex paragraph xs align-center gap-4">
        <div class="text-capitalize">{{ date.date }}</div>
        <div>•</div>
        <div>{{ date.time }}</div>
      </div>
      <div class="d-flex gap-8 mt-xs mb-xs">
        <div class="paragraph lg flex-grow-1">
          <strong>{{ notification.title }}</strong>
        </div>
        <div v-if="notification.urgent" class="icon mt-xs text-color-danger">
          <em class="fas fa-exclamation-circle fs-18"></em>
        </div>
      </div>
      <div class="paragraph sm">{{ notification.area }}</div>
    </div>
    <div v-if="hasButton" class="mb-2">
      <button class="btn xs btn-info">{{ buttonText }}</button>
    </div>
    <div class="bg-contrast pa-2 paragraph sm scrollable">
      <p>{{ notification.message }}</p>
      <template v-for="i in 5" :key="'p' + i">
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt
          facere eius vel sapiente assumenda, perferendis sed in enim, suscipit
          fugit nisi cum earum similique neque. Facere eius blanditiis nesciunt
          distinctio!
        </p>
      </template>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'NotificationMessage',
  props: ['notification', 'listIsOpen'],
  computed: {
    date () {
      moment.locale('pt-br')
      return {
        date: moment.utc(this.notification.date).format('dddd, DD [de] MMMM, YYYY'),
        time: moment.utc(this.notification.date).format('HH:mm')
      }
    },
    hasButton () {
      return (
        this.notification.type === 'project' ||
        this.notification.type === 'count'
      )
    },
    buttonText () {
      return this.notification.type === 'project'
        ? 'Abrir projeto'
        : 'Abrir no Conta Azul'
    }
  }
}
</script>
