<template>
  <div class="form" ref="form">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'FormComponent',
  provide () {
    return { form: this }
  },
  data () {
    return {
      errors: [],
      errorBag: {},
      inputs: []
    }
  },
  watch: {
    errorBag: {
      handler (val) {
        const errors = Object.values(val).includes(1)
        this.$emit('update:modelValue', !errors)
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    this.errors = []
    this.inputs.map((input) => this.watchInput(input))
  },
  methods: {
    watchInput (input) {
      return input.$watch(
        'hasError',
        (val) => {
          this.errorBag[input.uud] = val
        },
        {
          immediate: true
        }
      )
    },
    register (input) {
      this.inputs.push(input)
    },
    validate () {
    }
  }
}
</script>
