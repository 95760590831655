import cookieConfig from './cookieConfig'

export default {
  namespaced: true,
  state: {
    loggedUser: {}
  },
  getters: {
    loggedUser: (state) => state.loggedUser
  },
  mutations: {
    SET_LOGGED_USER(state, loggedUser) {
      state.loggedUser = loggedUser
    }
  },
  actions: {
    getUserData({ commit }) {
      const { $axios } = this.state

      return $axios
        .get('/user/data')
        .then(({ data }) => commit('SET_LOGGED_USER', data))
        .catch(console.error)
    },
    async login({ commit, dispatch }, value) {
      const { $axios } = this.state

      try {
        const result = await $axios
          .post('/user/login', value)
          .catch((error) => {
            if (!error.parsedErrorText) console.error(error)

            dispatch(
              'snackbar/showSnackbar',
              {
                message: error.parsedErrorText || 'Falha ao realizar login',
                secondsToClose: 5,
                type: 'error'
              },
              { root: true }
            )

            return Promise.reject(error)
          })

        dispatch('setUserConfig', result.data)

        commit('SET_LOGGED_USER', result.data)

        return true
      } catch (err) {
        return false
      }
    },
    async setUserConfig(_, value) {
      const { token } = value
      cookieConfig.setCookie('token_plonge', token)
    }
  }
}
