<template>
  <div class="pa-3 abordagem-content">
    <Trumbowyg v-model="approachScript" @tbw-blur="updateApproach()" :config="config" class="form-control"
      name="content" />
  </div>
</template>

<script>
import Trumbowyg from 'vue-trumbowyg'
import 'trumbowyg/dist/ui/trumbowyg.css'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Abordagem',
  components: { Trumbowyg },
  data () {
    return {
      approachScript: '',
      config: {
        btns: [
          ['bold', 'italic'],
          ['lineheight', 'foreColor', 'backColor'],
          ['orderedList', 'unorderedList']
        ],
        lang: 'pt-BR',
        svgPath: '/images/icons.svg',
        tagsToRemove: ['script', 'link', 'style', 'ol', 'li'],
        removeformatPasted: true
      }
    }
  },
  computed: {
    ...mapGetters({
      currentPipeline: 'jobs/currentPipeline',
      currentCandidate: 'jobs/currentCandidate'
    })
  },
  methods: {
    ...mapActions({
      loadApproachScript: 'jobs/loadApproachScript',
      updateApproachScript: 'jobs/updateApproachScript'
    }),
    updateApproach () {
      this.updateApproachScript({ pipelineId: this.currentPipeline.id, approachScript: this.approachScript })
    }
  },
  async mounted () {
    this.approachScript = await this.loadApproachScript({
      pipelineId: this.currentPipeline.id,
      contactId: this.currentCandidate.contactId
    })
  },
}
</script>

<style lang="scss">
.abordagem-content {
  max-width: 100%;

  .trumbowyg-box {
    border: 0;
    background: transparent;
  }

  .trumbowyg-box .trumbowyg-button-pane {
    margin-bottom: 16px;
    order: 0;
    border: 1px solid var(--border);
    border-bottom: 0;
    width: 100%;
  }

  .trumbowyg-editor-box {
    border: 1px solid var(--border);
    order: 1;
    background: var(--bg-contrast);
  }
}
</style>
