export default {
  data () {
    return {
    }
  },
  methods: {
    openModal (ref, data, screen) {
      this.$refs[ref].open(data, screen)
    },
    closeModal (ref) {
      this.$refs[ref].close()
    },
    getInitials (name) {
      if (!name) return ''
      let initials = name.split(' ')

      if (initials.length > 1) {
        initials = initials.shift().charAt(0) + initials.pop().charAt(0)
      } else {
        initials = name.substring(0, 2)
      }
      return initials.toUpperCase()
    }
  }
}
