/* eslint-disable no-useless-escape */

export default {
  setCookie (key, value) {
    let updatedCookie =
        encodeURIComponent(key) + '=' + encodeURIComponent(value)
    updatedCookie += '; ' + 'path=/'
    document.cookie = updatedCookie
  },
  removeCookie (key) {
    let updatedCookie = encodeURIComponent(key) + '=' + ''
    updatedCookie += '; ' + 'path=/; ' + 'max-age=-1'
    document.cookie = updatedCookie
  },
  getCookie (key) {
    const matches = document.cookie.match(
      new RegExp(
        '(?:^|; )' +
                        key.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
                        '=([^;]*)'
      )
    )
    return matches ? decodeURIComponent(matches[1]) : null
  }
}
