<template>
  <div class="switch">
    <input v-model="model" :checked="model" type="checkbox" :id="id" />
    <label :for="id" />
  </div>
</template>

<script>
export default {
  name: 'SwitchComponent',
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    }
  },
  computed: {
    model: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>
