export default {
  namespaced: true,
  state: {
    schooling: [],
    schoolingType: [],
    skills: [],
    softSkills: [],
    language: [],
    languageLevel: [],
    languageRequirement: [],
    jobLevel: [],
    form: {
      jobLevelId: '',
      languages: [],
      skills: [],
      softSkills: [],
      schooling: []
    }
  },
  getters: {
    schooling: state => state.schooling,
    schoolingType: state => state.schoolingType,
    skills: state => state.skills,
    softSkills: state => state.softSkills,
    language: state => state.language,
    languageLevel: state => state.languageLevel,
    languageRequirement: state => state.languageRequirement,
    jobLevel: state => state.jobLevel
  },
  mutations: {
    APPEND_SKILL (state, data) {
      state.skills = [...state.skills, data]
    },
    APPEND_SOFT_SKILL (state, data) {
      state.softSkills = [...state.softSkills, data]
    },
    SET_SCHOOLING (state, data) {
      state.schooling = data
    },
    SET_SCHOOLING_TYPE (state, data) {
      state.schoolingType = data
    },
    SET_SKILLS (state, data) {
      state.skills = data
    },
    SET_SOFT_SKILLS (state, data) {
      state.softSkills = data
    },
    SET_LANGUAGE (state, data) {
      state.language = data
    },
    SET_LANGUAGE_LEVEL (state, data) {
      state.languageLevel = data
    },
    SET_LANGUAGE_REQUIREMENT (state, data) {
      state.languageRequirement = data
    },
    SET_JOB_LEVEL (state, data) {
      state.jobLevel = data
    },
    SET_FORM_EXPERIENCE (state, data) {
      state.form.experience = data
    },
    SET_FORM_RESPONSIBILITY (state, data) {
      state.form.responsibility = data
    },
    SET_FORM_LANGUAGES (state, data) {
      state.form.languages = data
    },
    SET_FORM_SKILLS (state, data) {
      state.form.skills = data
    },
    SET_FORM_SOFT_SKILLS (state, data) {
      state.form.softSkills = data
    },
    SET_FORM_SCHOOLING (state, data) {
      state.form.schooling = data
    },
    SET_FORM_JOB_LEVEL_ID (state, data) {
      state.form.jobLevelId = data
    }
  },
  actions: {
    resetData ({ commit }) {
      commit('SET_FORM_EXPERIENCE', '')
      commit('SET_FORM_RESPONSIBILITY', '')
      commit('SET_FORM_CHALLENGES', '')
      commit('SET_FORM_LANGUAGES', [])
      commit('SET_FORM_SKILLS', [])
      commit('SET_FORM_SOFT_SKILLS', [])
      commit('SET_FORM_SCHOOLING', [])
    },
    loadJobOptions ({ commit, dispatch }) {
      const { $axios } = this.state

      return $axios.get('/pipeline/job/options').then(({ data }) => {
        commit('SET_SCHOOLING', data.schooling)
        commit('SET_SCHOOLING_TYPE', data.schoolingType)
        commit('SET_SKILLS', data.skills)
        commit('SET_SOFT_SKILLS', data.softSkills)
        commit('SET_LANGUAGE', data.language)
        commit('SET_LANGUAGE_LEVEL', data.languageLevel)
        commit('SET_JOB_LEVEL', data.jobLevel)
      }).catch(error => {
        if (!error.parsedErrorText) console.error(error)

        dispatch('snackbar/showSnackbar', {
          message: error.parsedErrorText || 'Falha ao carregar listagem de opções da vaga',
          secondsToClose: 5,
          type: 'warn'
        }, { root: true })

        return Promise.reject(error)
      })
    },
    loadContactData ({ commit, dispatch }, contactId) {
      const { $axios } = this.state

      return $axios.get(`/contact/cv/${contactId}`).then(({ data }) => {
        commit('SET_FORM_LANGUAGES', data.languages)
        commit('SET_FORM_LANGUAGES', data.languages)
        commit('SET_FORM_SCHOOLING', data.schooling)
        commit('SET_FORM_SKILLS', data.skills)
        commit('SET_FORM_SOFT_SKILLS', data.softSkills)
        commit('SET_FORM_JOB_LEVEL_ID', data.jobLevelId)
      }).catch(error => {
        if (!error.parsedErrorText) console.error(error)

        dispatch('snackbar/showSnackbar', {
          message: error.parsedErrorText || 'Falha ao carregar informações do curriculo.',
          secondsToClose: 5,
          type: 'warn'
        }, { root: true })

        return Promise.reject(error)
      })
    },
    createSkill ({ commit, dispatch }, name) {
      const { $axios } = this.state

      return $axios.post('/pipeline/skill', { name }).then(({ data }) => {
        if (!data.success) throw Error()

        commit('APPEND_SKILL', {
          id: data.id,
          name
        })

        return data
      }).catch(error => {
        if (!error.parsedErrorText) console.error(error)

        dispatch('snackbar/showSnackbar', {
          message: error.parsedErrorText || 'Falha ao gravar nova competência técnica',
          secondsToClose: 5,
          type: 'warn'
        }, { root: true })

        return Promise.reject(error)
      })
    },
    createSoftSkill ({ commit, dispatch }, name) {
      const { $axios } = this.state

      return $axios.post('/pipeline/soft-skill', { name }).then(({ data }) => {
        if (!data.success) throw Error()

        commit('APPEND_SOFT_SKILL', {
          id: data.id,
          name
        })

        return data
      }).catch(error => {
        if (!error.parsedErrorText) console.error(error)

        dispatch('snackbar/showSnackbar', {
          message: error.parsedErrorText || 'Falha ao gravar nova competência técnica',
          secondsToClose: 5,
          type: 'warn'
        }, { root: true })

        return Promise.reject(error)
      })
    },
    updateCurriculum ({ dispatch }, contactId) {
      const { $axios } = this.state

      const data = {
        languages: this.state.curriculum.form.languages,
        schooling: this.state.curriculum.form.schooling,
        skills: this.state.curriculum.form.skills,
        softSkills: this.state.curriculum.form.softSkills,
        jobLevelId: this.state.curriculum.form.jobLevelId
      }

      return $axios.put(`/contact/cv/${contactId}`, data).then(({ data: result }) => {
        if (!result.success) throw Error('Falha na atualização de dados do curriculo')
        dispatch('snackbar/showSnackbar', {
          message: 'Currículo atualizado com sucesso',
          secondsToClose: 5,
          type: 'success'
        }, { root: true })
        return result.success
      }).catch(error => {
        if (!error.parsedErrorText) console.error(error)

        dispatch('snackbar/showSnackbar', {
          message: error.parsedErrorText || 'Falha ao gravar dados do curriculo',
          secondsToClose: 5,
          type: 'warn'
        }, { root: true })

        return Promise.reject(error)
      })
    },
    matchJobCandidate ({ commit, dispatch }, data) {
      const { $axios } = this.state
      return $axios
        .post(`/contact/cv/matchJob`, {
          contactId: data.contactId,
        })
        .then((response) => {
          return response.data
        })
        .catch((error) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText || 'Erro ao calcular match',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )
        })
    }
  }
}
