<template>
  <div class="form-group">
    <label :for="id" v-if="label">{{ label }}</label>
    <div class="input-group">
      <div class="input-field" :class="{ 'has-icon': icon }">
        <input type="file" :id="id" class="d-none" @change="handleFile">
        <label :for="id" class="form-control">{{ model ? model.name : placeholder }}</label>
        <a v-if="icon" class="icon" @click="$emit('iconClick')">
          <em :class="icon"></em>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputFile',
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    modelValue: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: 'fal fa-paperclip'
    },
    iconClick: {
      type: Function,
      default: () => { }
    },
    label: {
      type: String,
      default: ''
    }
  },
  computed: {
    model: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  methods: {
    handleFile (event) {
      this.model = event.target.files[0] || null
    }
  }
}
</script>
