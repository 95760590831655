const setType = (type) => {
  switch ((type || '').toLowerCase()) {
    case 'error':
    case 'warn':
    case 'success':
    case 'info':
      return type.toLowerCase()
    default:
      return 'common'
  }
}

const generateId = () => Math.random().toString(36).substring(2) + Date.now().toString(36)

export default {
  namespaced: true,
  state: {
    instances: []
  },
  getters: {
    instances: state => state.instances
  },
  mutations: {
    ADD_INSTANCE (state, instance) {
      state.instances.push(instance)
    },
    REMOVE_INSTANCE (state, id) {
      state.instances = state.instances.filter(instance => instance.id !== id)
    }
  },
  actions: {
    close ({ commit }, id) {
      commit('REMOVE_INSTANCE', id)
    },
    showSnackbar ({ commit }, { message, type = 'common', secondsToClose = null }) {
      if (!message) return

      const id = generateId()

      commit('ADD_INSTANCE', {
        id,
        message: message.toString().replace(/\n/g, '<br />'),
        type: setType(type)
      })

      if (secondsToClose && !isNaN(parseInt(secondsToClose))) {
        const timeToClose = parseInt(secondsToClose) * 1000
        setTimeout(() => commit('REMOVE_INSTANCE', id), timeToClose)
      }
    }
  }
}
