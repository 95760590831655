<template>
  <div class="bar pa-3" :class="type">
    <div v-for="(value, i) in values" :key="value" class="bar-line">
      <template v-if="Array.isArray(value)">
        <div class="bars">
          <div v-for="(number, index) in value" :key="number" class="bar-item">
            <div
class="bar-line-progress"
              :style="'--value:' + getPercent(number, value) + '%; color: ' + getColor(index)" />
            <div class="bar-line-value">{{ number }}{{ suffix }}</div>
          </div>
        </div>
        <div class="bar-line-label">{{ labels[i] }}</div>
      </template>
      <template v-else>
        <div class="bar-line-label">{{ labels[i] }}</div>
        <div class="bar-line-progress" :style="'--value:' + percents[i] + '%; color: ' + getColor(i)"></div>
        <span v-if="type === 'bar-vertical'" class="divider"></span>
        <div class="bar-line-value">{{ value }}{{ suffix }}</div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BarChart',
  props: {
    type: {
      type: String,
      default: ''
    },
    labels: {
      type: Array,
      default: () => []
    },
    colors: {
      type: Array,
      default: () => []
    },
    values: {
      type: Array,
      default: () => []
    },
    suffix: {
      type: String,
      default: '%'
    }
  },
  data () {
    return {
      percents: []
    }
  },
  mounted () {
    this.percents = this.values.map((value) => {
      if (Array.isArray(value)) {
        return value.map(e => this.getPercent(e, value))
      } else {
        return this.getPercent(value, this.values)
      }
    })
  },
  methods: {
    getColor (index) {
      if (this.type === 'bar-vertical' && Array.isArray(this.values[0])) {

        let alpha;
        if (index === 0) {
          alpha = 0.2;
        } else if (index === 1) {
          alpha = 0.6;
        } else {
          alpha = 1;
        }

        return this.hexToRGB(this.colors[0], alpha);

      } else if (this.colors.length === 1) {
        return this.hexToRGB(this.colors[0], index & 1 ? 1 : 0.6)
      } else {
        return index & 1 ? this.colors[1] : this.colors[0]
      }
    },
    hexToRGB (hex, alpha) {
      const r = parseInt(hex.slice(1, 3), 16)
      const g = parseInt(hex.slice(3, 5), 16)
      const b = parseInt(hex.slice(5, 7), 16)

      if (alpha) {
        return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')'
      } else {
        return 'rgb(' + r + ', ' + g + ', ' + b + ')'
      }
    },
    getPercent (value, array) {
      const data = JSON.parse(JSON.stringify(array))
      let total = 0
      total = data.reduce((a, b) => a + b, 0)
      const percent = ((100 * value) / total).toFixed(0)
      if (parseInt(percent) > 100) {
        return 100
      }
      if (percent === '0') {
        return 1
      }
      return parseInt(percent) // Ensure that the return value is always of type 'number'
    }
  }
}
</script>
