import rules from './rules'
export default {
  inject: ['form'],
  data () {
    return {
      errorMsg: {
        email: 'Informe um e-mail válido.',
        cpf: 'Verifique o número do CPF informado.',
        cnpj: 'Verifique o número do CNPJ informado.',
        phone: 'O número de telefone informado é inválido.',
        url: 'Informe um link válido.'
      }
    }
  },
  computed: {
    fieldRules () {
      const data = [...this.rules]
      if (this.required) {
        data.push((v) => !!v || 'Campo obrigatório')
        const types = ['cpf', 'cnpj', 'phone', 'url']
        if (types.includes(this.type)) {
          data.push((v) => rules[this.type](v) || this.errorMsg[this.type])
        }
      }
      return data
    },
    hasError () {
      return this.error.length
    },
    errorText () {
      return this.model ? this.error[0] : ''
    }
  },
  watch: {
    hasError (val) {
      return val
    }
  },
  methods: {
    validate () {
      this.error = []
      let valid = false
      if (this.fieldRules.length) {
        this.fieldRules.map((rule) => {
          valid = rule(this.model) !== true
          if (valid) {
            this.error.push(rule(this.model))
            return true
          }
          return false
        })
      }
    }
  },
  created () {
    this.form && this.form.register(this)
  },
  beforeMount () {
    this.validate()
  }
}
