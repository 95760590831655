export default {
  namespaced: true,
  state: {
    options: [],
    prospectsData: []
  },
  getters: {
    options: state => state.options,
    prospectsData: state => state.prospectsData
  },
  mutations: {
    SET_OPTIONS (state, options) {
      state.options = options
    },
    SET_PROSPECT_DATA (state, data) {
      state.prospectsData = data
    }
  },
  actions: {
    saveProspect ({ dispatch }, data) {
      const { $axios } = this.state

      if (!data.companyId || !data.prospectStatusId || !data.originId) return

      const action = data.id ? 'put' : 'post'

      const position = {
        contactsId: data.contacts
          ? data.contacts.map((contact) => contact.id)
          : [],
        responsibleContactId: data.responsible ? data.responsible.id : null,
        companyId: data.companyId,
        prospectStatusId: data.prospectStatusId,
        originId: data.originId,
        note: data.note || null,
        internalFupPriorityId: data.internalFupPriorityId || null,
        customerFupPriorityId: data.customerFupPriorityId || null
      }

      return $axios[action](`/pipeline/prospect/${data.id || ''}`, position)
        .then(({ data }) => data)
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha na inserção',
              secondsToClose: 5,
              type: 'error'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    loadOptions ({ commit, dispatch }) {
      const { $axios } = this.state

      return $axios.get('/pipeline/prospect/options').then((response) => {
        commit('SET_OPTIONS', response.data)
      }).catch(error => {
        if (!error.parsedErrorText) console.error(error)

        dispatch('snackbar/showSnackbar', {
          message: error.parsedErrorText || 'Falha ao carregar opções de prospect',
          secondsToClose: 5,
          type: 'warn'
        }, { root: true })

        return Promise.reject(error)
      })
    },
    getOnlyProspects ({ commit, dispatch }) {
      const { $axios } = this.state

      return $axios.get('/pipeline/step/1').then((response) => {
        commit('SET_PROSPECT_DATA', response.data)
      }).catch(error => {
        if (!error.parsedErrorText) console.error(error)

        dispatch('snackbar/showSnackbar', {
          message: error.parsedErrorText || 'Falha ao carregar prospects',
          secondsToClose: 5,
          type: 'warn'
        }, { root: true })

        return Promise.reject(error)
      })
    }
  }
}
