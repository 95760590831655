<template>
  <div class="card border-0">
    <div class="card-header pa-0">
      <div class="d-flex align-center gap-8 pa-2 flex-grow-1 justify-between">
        <div class="paragraph sm font-weight-900">{{ title }}</div>
        <div
          v-if="total"
          class="paragraph sm font-weight-800 d-flex align-center gap-8"
        >
          <span class="text-color-light">Total:</span>
          <span>{{ total }}</span>
        </div>
      </div>
      <span class="divider v"></span>
      <template v-if="byMonth">
        <b-dropdown x="right">
          <template v-slot:trigger="{ on }">
            <button v-on="on" class="btn text pl-2 pr-2">
              <div class="icon">
                <em class="fal fa-calendar"></em>
              </div>
              <span class="text-capitalize">{{ currentMonthName }}</span>
              <div class="icon">
                <em class="fal fa-chevron-down"></em>
              </div>
            </button>
          </template>
          <template #content>
            <div class="list">
              <button
                v-for="item in months"
                :key="item.name"
                class="list-item"
                :class="
                  currentMonth === item.index ? 'bg-coral text-color-white' : ''
                "
                @click="setMonth(item.index)"
              >
                <span class="text-capitalize">{{ item.name }}</span>
              </button>
            </div>
          </template>
        </b-dropdown>
      </template>
    </div>
    <span class="divider"></span>
    <Doughnut
      v-if="type === 'doughnut'"
      :labels="labels"
      :values="values"
      :suffix="suffix"
      :colors="colors"
    />
    <Bar
      v-if="type === 'bar'"
      :labels="labels"
      :values="values"
      :suffix="suffix"
      :colors="colors"
    />
    <Line
      v-if="type === 'line'"
      :labels="labels"
      :values="values"
      :suffix="suffix"
      :colors="colors"
    />
    <Bar
      v-if="type === 'bar-vertical'"
      :type="type"
      :labels="labels"
      :values="values"
      :suffix="suffix"
      :colors="colors"
    />
  </div>
</template>
<script>
import Bar from './Bar.vue'
import Doughnut from './Doughnut.vue'
import Line from './Line.vue'
import moment from 'moment'
export default {
  components: { Doughnut, Bar, Line },
  name: 'DoughnutComponent',
  props: {
    type: {
      type: String,
      default: ''
    },
    labels: {
      type: Array,
      default: () =>
        new Array(12).fill(0).map((_, i) => moment().month(i).format('MMM'))
    },
    colors: {
      type: Array
    },
    values: {
      type: Array,
      default: () => []
    },
    suffix: {
      type: String,
      default: '%'
    },
    title: {
      type: String,
      default: ''
    },
    total: {
      type: Number,
      default: 0
    },
    byMonth: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      currentMonth: new Date().getMonth(),
      currentYear: new Date().getFullYear()
    }
  },
  computed: {
    months () {
      return new Array(12).fill(0).map((_, i) => {
        return {
          name: moment().month(i).format('MMMM'),
          index: i
        }
      })
    },
    years () {
      return new Array(12).fill(0).map((_, i) => {
        return {
          name: moment().subtract(i, 'year').format('YYYY'),
          index: i
        }
      })
    },
    currentMonthName () {
      return moment().month(this.currentMonth).format('MMMM')
    }
  },
  methods: {
    setMonth (value) {
      this.currentMonth = value
    },
    setYear (value) {
      this.currentYear = value
    }
  }
}
</script>
