export const openWindow = (url, title = '', width = 600, height = 330) => {
  // Credits to: https://stackoverflow.com/a/16861050/6161969
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY

  const windowWidth = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width
  const windowHeight = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height

  const systemZoom = windowWidth / window.screen.availWidth
  const left = (windowWidth - width) / 2 / systemZoom + dualScreenLeft
  const top = (windowHeight - height) / 2 / systemZoom + dualScreenTop

  const popupConfig = `
      scrollbars=yes,
      width=${width / systemZoom},
      height=${height / systemZoom},
      top=${top},
      left=${left}
    `

  const newWindow = window.open(url, title, popupConfig)

  if (window.focus && newWindow) newWindow.focus()

  return newWindow
}
export function debounce (fn, delay) {
  var timeoutID = null
  return function () {
    clearTimeout(timeoutID)
    var args = arguments
    var that = this
    timeoutID = setTimeout(function () {
      fn.apply(that, args)
    }, delay)
  }
}
export default {
  openWindow
}
