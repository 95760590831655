<template>
  <slot name="activator" :on="{ mouseenter: on, mouseleave: out }" />
  <Teleport to="body">
    <Transition :name="animation">
      <div v-if="show && $slots.default" class="tooltip font-support" :class="`${x} ${y}`" ref="tooltip">
        <slot />
      </div>
    </Transition>
  </Teleport>
</template>

<script>
export default {
  name: 'TooltipComponent',
  props: {
    x: {
      type: String,
      default: 'center'
    },
    y: {
      type: String,
      default: 'top'
    }
  },
  data () {
    return {
      show: false,
      position: {},
      animation: 'slide-fade-top'
    }
  },
  mounted () {
    if (this.x === 'center') {
      this.animation = `slide-fade-${this.y}`
    } else {
      this.animation = `slide-fade-${this.x}`
    }
  },
  methods: {
    on (event) {
      this.show = true
      if (event.target) {
        this.position = event.target.getBoundingClientRect()
        this.$nextTick(() => {
          this.setPosition()
        })
      }
    },
    out () {
      this.show = false
    },
    setPosition () {
      if (!this.$refs.tooltip) return
      const { x, y, height, width } = this.position
      const { offsetWidth, offsetHeight } = this.$refs.tooltip
      const space = 16
      let left = x + 'px'
      let top = y - (offsetHeight + space) + 'px'

      if (this.x === 'center') {
        left = `${x - (offsetWidth - width) / 2}px`
      }
      if (this.x === 'right') {
        left = `${x - offsetWidth - space}px`
        top = `${(y + (height / 2)) - offsetHeight / 2}px`
      }
      if (this.x === 'left') {
        left = `${x + width + space}px`
        top = `${(y + height / 2) - offsetHeight / 2}px`
      }
      if (this.y === 'bottom') {
        top = `${y + height + space}px`
      }
      if (this.$refs.tooltip) {
        this.$refs.tooltip.style.top = top
        this.$refs.tooltip.style.left = left
      }
    }
  }
}
</script>
