<template>
  <div class="dialog" :class="{ active: dialog }">
    <div class="dialog-content">
      <div class="dialog-content-header">
        <div class="btn icon btn-coral no-hover">
          <em class="fal fa-circle-dollar"></em>
        </div>
        <div class="flex-grow-1 h4 ml-2 mr-2">Impostos</div>
        <button class="btn text icon" @click="close()">
          <em class="fal fa-times"></em>
        </button>
      </div>
      <span class="divider"></span>
      <div class="dialog-content-body">
        <div class="pa-3">
            <b-textfield label="PIS" icon="fal fa-percent"/>
            <b-textfield label="Cofins" icon="fal fa-percent"/>
            <b-textfield label="ISS" icon="fal fa-percent"/>
        </div>
      </div>
      <span class="divider"></span>
      <div class="dialog-content-footer justify-end">
        <button class="btn outlined sm">Cancelar</button>
        <button class="btn btn-coral sm">Salvar</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'impostosModal',
  data () {
    return {
      dialog: false
    }
  },
  methods: {
    open () {
      this.dialog = true
    },
    close () {
      this.dialog = false
    }
  }
}
</script>
