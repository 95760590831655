import { openWindow } from '@/helpers'

export default {
  namespaced: true,
  state: {
    types: [],
    records: [],
    form: {
      activityTypeId: '',
      title: null,
      description: null,
      place: null,
      start: null,
      end: null,
      attendees: []
    }
  },
  getters: {
    types: state => state.types,
    records: state => state.records
  },
  mutations: {
    SET_TYPES (state, data) {
      state.types = data
    },
    APPEND_RECORD (state, data) {
      state.records = [...state.records, data]
    },
    REMOVE_RECORD_BY_ID (state, activityId) {
      state.records = state.records.filter(record => record.id !== activityId)
    },
    SET_RECORDS (state, data) {
      state.records = data
    },
    SET_FORM_ACTIVITY_TYPE_ID (state, data) {
      state.form.activityTypeId = data
    },
    SET_FORM_TITLE (state, data) {
      state.form.title = data
    },
    SET_FORM_DESCRIPTION (state, data) {
      state.form.description = data
    },
    SET_FORM_PLACE (state, data) {
      state.form.place = data
    },
    SET_FORM_START (state, data) {
      state.form.start = data
    },
    SET_FORM_END (state, data) {
      state.form.end = data
    },
    SET_FORM_ATTENDEES (state, data) {
      state.form.attendees = data
    }
  },
  actions: {
    loadTypes ({ commit, dispatch }) {
      const { $axios } = this.state

      return $axios
        .get('pipeline/activity-types')
        .then(({ data }) => {
          commit('SET_TYPES', data)
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText ||
                'Falha ao carregar tipos de atividades',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    loadPipelineActivities ({ commit, dispatch }, pipelineId) {
      const { $axios } = this.state

      return $axios
        .get(`pipeline/activity/${pipelineId}`)
        .then(({ data }) => {
          commit('SET_RECORDS', data)
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText ||
                'Falha ao carregar tipos de atividades',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    setActivityConclusion ({ commit, dispatch }, activityId) {
      const { $axios } = this.state

      return $axios
        .put(`pipeline/activity/conclude/${activityId}`)
        .then(({ data }) => {
          if (!data.success) throw Error()

          commit(
            'SET_RECORDS',
            this.state.activities.records.map((activity) => {
              if (activity.id !== activityId) return activity

              return {
                ...activity,
                conclusionAt: data.conclusionAt
              }
            })
          )

          dispatch('contacts/setActivityConclusion', { activityId, conclusionAt: data.conclusionAt }, { root: true })

          return data
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText ||
                'Falha ao marcar atividade como concluída',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    setActivityTypeId ({ commit }, activityTypeId) {
      commit('SET_FORM_ACTIVITY_TYPE_ID', activityTypeId)
    },
    createActivity ({ commit, dispatch, rootState }, { activityTypeId, positionId }) {
      const { $axios } = rootState

      return $axios
        .post('/activities', {
          activityTypeId,
          positionId
        })
        .then(({ data }) => {
          commit('APPEND_RECORD', data)
          return data
        })
        .catch((error) => {
          if (!error.response?.data?.authUrl) {
            console.error(error)

            const message = error.response?.data?.message || 'Falha ao criar atividade'

            dispatch('snackbar/showSnackbar', {
              message,
              type: 'error'
            }, { root: true })

            return
          }

          const { authUrl } = error.response.data

          openWindow(authUrl)

          dispatch('snackbar/showSnackbar', {
            message: 'É necessário autenticar-se para criar uma atividade',
            secondsToClose: 5,
            type: 'warn'
          }, { root: true })

          return Promise.reject(error)
        })
    },
    deleteActivity ({ commit, dispatch, rootState }, activityId) {
      const { $axios } = rootState

      return $axios
        .delete(`/activities/${activityId}`)
        .then(({ data }) => {
          commit('REMOVE_RECORD_BY_ID', activityId)
          return data
        })
        .catch((error) => {
          if (!error.response?.data?.authUrl) {
            console.error(error)

            const message = error.response?.data?.message || 'Falha ao remover atividade'

            dispatch('snackbar/showSnackbar', {
              message,
              type: 'error'
            }, { root: true })

            return
          }

          const { authUrl } = error.response.data

          openWindow(authUrl)

          dispatch('snackbar/showSnackbar', {
            message: 'É necessário autenticar-se para remover uma atividade',
            secondsToClose: 5,
            type: 'warn'
          }, { root: true })

          return Promise.reject(error)
        })
    },
    syncActivities ({ dispatch, rootState }, positionId) {
      const { $axios } = rootState

      return $axios
        .post('/activities/sync', { positionId })
        .then(({ data }) => data)
        .catch((error) => {
          if (!error.response?.data?.authUrl) {
            console.error(error)

            const message =
              error.response?.data?.message || 'Falha ao criar atividade'

            dispatch(
              'snackbar/showSnackbar',
              {
                message,
                type: 'error'
              },
              { root: true }
            )

            return
          }

          const { authUrl } = error.response.data

          openWindow(authUrl)

          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'É necessário autenticar-se para criar uma atividade',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    }
  }
}
