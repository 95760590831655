import { openWindow } from '@/helpers'

export default {
  namespaced: true,
  state: {
    files: [],
    folders: [],
    created: '',
    currentPipeline: '',
    currentFolder: null
  },
  getters: {
    files: (state) => state.files,
    folders: (state) => state.folders,
    created: (state) => state.folders,
    currentPipeline: (state) => state.currentPipeline,
    currentFolder: (state) => state.currentFolder
  },
  mutations: {
    SET_FILES (state, files) {
      state.files = files
    },
    SET_FOLDERS (state, folders) {
      state.folders = folders
    },
    SET_CREATED_FILE (state, files) {
      state.created = files
    },
    SET_CURRENTPIPELINE (state, currentPipeline) {
      state.currentPipeline = currentPipeline
    },
    SET_CURRENT_FOLDER (state, currentFolder) {
      state.currentFolder = currentFolder
    }
  },
  actions: {
    loadFiles ({ commit, dispatch }, pipelineId) {
      const { $axios } = this.state

      return $axios
        .get(`pipeline/attachment/${pipelineId}`)
        .then(({ data }) => {
          commit('SET_FILES', data)
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao carregar atividades',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    loadFolders ({ commit, dispatch }, pipelineId) {
      const { $axios } = this.state

      return $axios
        .get(`pipeline/attachment/folder/${pipelineId}`)
        .then(({ data }) => {
          commit('SET_FOLDERS', data)
          if (data.length < 1) {
            dispatch(
              'snackbar/showSnackbar',
              {
                message: 'Nenhum arquivo encontrado',
                secondsToClose: 5,
                type: 'warn'
              },
              { root: true }
            )
          }
        })
        .catch((error) => {
          if (!error.response?.data?.authUrl) {
            console.error(error)

            const message = 'Falha ao carregar pastas'

            dispatch(
              'snackbar/showSnackbar',
              {
                message,
                type: 'error'
              },
              { root: true }
            )

            return
          }

          const { authUrl } = error.response.data

          openWindow(authUrl)

          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'É necessário autenticar-se para listar as pastas',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    loadFoldersContact ({ commit, dispatch }, contactId) {
      const { $axios } = this.state

      return $axios
        .get(`contact/attachment/folder/contact/${contactId}`)
        .then(({ data }) => {
          commit('SET_FOLDERS', data)
          if (data.length < 1) {
            dispatch(
              'snackbar/showSnackbar',
              {
                message: 'Nenhum arquivo encontrado',
                secondsToClose: 5,
                type: 'warn'
              },
              { root: true }
            )
          }
        })
        .catch((error) => {
          if (!error.response?.data?.authUrl) {
            console.error(error)

            const message =
              error.response?.data?.message || 'Falha ao carregar pastas'

            dispatch(
              'snackbar/showSnackbar',
              {
                message,
                type: 'error'
              },
              { root: true }
            )

            return
          }

          const { authUrl } = error.response.data

          openWindow(authUrl)

          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'É necessário autenticar-se para listar as pastas',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    addFiles ({ commit, dispatch }, { PipelineId, ...data }) {
      const { $axios } = this.state
      const body = {
        PipelineId: String(PipelineId),
        ...data
      }
      return $axios
        .post('files', body)
        .then((response) => {
          commit('SET_CREATED_FILE', response.data)
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao carregar atividades',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    saveFile ({ dispatch }, data) {
      const { $axios } = this.state
      return $axios
        .post('pipeline/attachment/file', data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((result) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Arquivo incluído com sucesso',
              secondsToClose: 5,
              type: 'success'
            },
            { root: true }
          )
          return result
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)
          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao incluir arquivo',
              secondsToClose: 5,
              type: 'error'
            },
            { root: true }
          )
          return Promise.reject(error)
        })
    },
    replaceFile ({ dispatch }, data) {
      const { $axios } = this.state
      return $axios
        .post('pipeline/attachment/replace/file', data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((result) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Arquivo substituido com sucesso',
              secondsToClose: 5,
              type: 'success'
            },
            { root: true }
          )
          return result
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)
          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao substituir arquivo',
              secondsToClose: 5,
              type: 'error'
            },
            { root: true }
          )
          return Promise.reject(error)
        })
    },
    saveNewVersionFile ({ dispatch }, data) {
      const { $axios } = this.state
      return $axios
        .post('pipeline/attachment/version/file', data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((result) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Arquivo incluído com sucesso',
              secondsToClose: 5,
              type: 'success'
            },
            { root: true }
          )
          return result
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)
          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao incluir arquivo',
              secondsToClose: 5,
              type: 'error'
            },
            { root: true }
          )
          return Promise.reject(error)
        })
    },
    deleteFile ({ dispatch }, data) {
      const { $axios } = this.state
      const { fileId, driveIdSharePoint } = data
      return $axios
        .delete(`pipeline/attachment/file/${fileId}/drive/${driveIdSharePoint}`)
        .then((result) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Arquivo deletado com sucesso',
              secondsToClose: 5,
              type: 'success'
            },
            { root: true }
          )
          return result
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)
          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao deletar arquivo',
              secondsToClose: 5,
              type: 'error'
            },
            { root: true }
          )
          return Promise.reject(error)
        })
    },
    deleteFolder ({ dispatch }, data) {
      const { $axios } = this.state
      const { folderId } = data
      return $axios
        .delete(`pipeline/attachment/folder/${folderId}`)
        .then((result) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Pasta deletada com sucesso',
              secondsToClose: 5,
              type: 'success'
            },
            { root: true }
          )
          return result
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)
          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao deletar pasta',
              secondsToClose: 5,
              type: 'error'
            },
            { root: true }
          )
          return Promise.reject(error)
        })
    },
    saveLink ({ dispatch }, data) {
      const { $axios } = this.state

      const action = data.id ? 'put' : 'post'

      const record = {
        title: data.title,
        content: data.url,
        pipelineAttachmentFolderId: data.folderId,
        pipelineId: data.pipelineId,
        type: 'L'
      }

      return $axios[action](`pipeline/attachment/${data.id || ''}`, record)
        .then(({ data: result }) =>
          dispatch('loadFiles', data.pipelineId).then(() => result)
        )
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao salvar link',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    deleteLink ({ dispatch }, data) {
      const { $axios } = this.state
      const { linkId } = data
      return $axios
        .delete(`pipeline/attachment/link/${linkId}`)
        .then((result) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Link deletado com sucesso',
              secondsToClose: 5,
              type: 'success'
            },
            { root: true }
          )
          return result
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)
          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao deletar link',
              secondsToClose: 5,
              type: 'error'
            },
            { root: true }
          )
          return Promise.reject(error)
        })
    },
    saveFolder ({ dispatch }, data) {
      const { $axios } = this.state

      const action = data.id ? 'put' : 'post'

      return $axios[action](`pipeline/attachment/folder/${data.id || ''}`, {
        name: data.name,
        pipelineId: data.pipelineId
      })
        .then(({ data: result }) =>
          dispatch('loadFolders', data.pipelineId).then(() => result)
        )
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao salvar pasta',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    renameFolder ({ dispatch }, data) {
      const { $axios } = this.state
      return $axios
        .post('pipeline/attachment/rename/folder', data)
        .then((result) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Pasta renomeada com sucesso',
              secondsToClose: 5,
              type: 'success'
            },
            { root: true }
          )
          return result
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)
          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao renomear pasta',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    renameFile ({ dispatch }, data) {
      const { $axios } = this.state
      return $axios
        .post('pipeline/attachment/rename/file', data)
        .then((result) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Arquivo renomeado com sucesso',
              secondsToClose: 5,
              type: 'success'
            },
            { root: true }
          )
          return result
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)
          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao renomear arquivo',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    renameFileContact ({ dispatch }, data) {
      const { $axios } = this.state
      return $axios
        .post('contact/attachment/rename/file', data)
        .then((result) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Arquivo renomeado com sucesso',
              secondsToClose: 5,
              type: 'success'
            },
            { root: true }
          )
          return result
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)
          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao renomear arquivo',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    renameFolderContact ({ dispatch }, data) {
      const { $axios } = this.state
      return $axios
        .post('contact/attachment/rename/folder', data)
        .then((result) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Pasta renomeada com sucesso',
              secondsToClose: 5,
              type: 'success'
            },
            { root: true }
          )
          return result
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)
          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao renomear pasta',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    saveFolderContact ({ dispatch }, data) {
      const { $axios } = this.state

      return $axios
        .post('contact/attachment/folder', {
          name: data.name,
          contactId: data.contactId
        })
        .then((result) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Pasta criada com sucesso',
              secondsToClose: 5,
              type: 'success'
            },
            { root: true }
          )
          return result
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)
          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao salvar pasta',
              secondsToClose: 5,
              type: 'error'
            },
            { root: true }
          )
          return Promise.reject(error)
        })
    },
    renameFile ({ dispatch }, data) {
      const { $axios } = this.state
      return $axios
        .post('contact/attachment/file', data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((result) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Arquivo incluído com sucesso',
              secondsToClose: 5,
              type: 'success'
            },
            { root: true }
          )
          return result
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)
          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao incluir arquivo',
              secondsToClose: 5,
              type: 'error'
            },
            { root: true }
          )
          return Promise.reject(error)
        })
    },
    renameFileContact ({ dispatch }, data) {
      const { $axios } = this.state
      return $axios
        .post('contact/attachment/rename/file', data)
        .then((result) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Arquivo renomeado com sucesso',
              secondsToClose: 5,
              type: 'success'
            },
            { root: true }
          )
          return result
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)
          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao renomear arquivo',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    renameFolderContact ({ dispatch }, data) {
      const { $axios } = this.state
      return $axios
        .post('contact/attachment/rename/folder', data)
        .then((result) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Pasta renomeada com sucesso',
              secondsToClose: 5,
              type: 'success'
            },
            { root: true }
          )
          return result
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)
          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao renomear pasta',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    saveFolderContact ({ dispatch }, data) {
      const { $axios } = this.state

      return $axios
        .post('contact/attachment/folder', {
          name: data.name,
          contactId: data.contactId
        })
        .then((result) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Pasta criada com sucesso',
              secondsToClose: 5,
              type: 'success'
            },
            { root: true }
          )
          return result
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)
          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao salvar pasta',
              secondsToClose: 5,
              type: 'error'
            },
            { root: true }
          )
          return Promise.reject(error)
        })
    },
    saveFileContact ({ dispatch }, data) {
      const { $axios } = this.state
      return $axios
        .post('contact/attachment/file', data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((result) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Arquivo incluído com sucesso',
              secondsToClose: 5,
              type: 'success'
            },
            { root: true }
          )
          return result
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)
          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao incluir arquivo',
              secondsToClose: 5,
              type: 'error'
            },
            { root: true }
          )
          return Promise.reject(error)
        })
    },
    deleteFileContact ({ dispatch }, data) {
      const { $axios } = this.state
      const { fileId, driveIdSharePoint } = data
      return $axios
        .delete(`contact/attachment/file/${fileId}/drive/${driveIdSharePoint}`)
        .then((result) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Arquivo deletado com sucesso',
              secondsToClose: 5,
              type: 'success'
            },
            { root: true }
          )
          return result
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)
          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao deletar arquivo',
              secondsToClose: 5,
              type: 'error'
            },
            { root: true }
          )
          return Promise.reject(error)
        })
    },
    deleteFolderContact ({ dispatch }, data) {
      const { $axios } = this.state
      const { folderId } = data
      return $axios
        .delete(`contact/attachment/folder/${folderId}`)
        .then((result) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Pasta deletada com sucesso',
              secondsToClose: 5,
              type: 'success'
            },
            { root: true }
          )
          return result
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)
          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao deletar pasta',
              secondsToClose: 5,
              type: 'error'
            },
            { root: true }
          )
          return Promise.reject(error)
        })
    },
    saveLinkContact ({ dispatch }, data) {
      const { $axios } = this.state
      const { contactId, folderId } = data
      return $axios
        .post(
          `contact/attachment/link/contact/${contactId}/folder/${folderId}`,
          data
        )
        .then((result) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Arquivo incluído com sucesso',
              secondsToClose: 5,
              type: 'success'
            },
            { root: true }
          )
          return result
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)
          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao incluir link',
              secondsToClose: 5,
              type: 'error'
            },
            { root: true }
          )
          return Promise.reject(error)
        })
    },
    deleteFolderContact ({ dispatch }, data) {
      const { $axios } = this.state
      const { folderId } = data
      return $axios
        .delete(`contact/attachment/folder/${folderId}`)
        .then((result) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Pasta deletada com sucesso',
              secondsToClose: 5,
              type: 'success'
            },
            { root: true }
          )
          return result
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)
          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao deletar pasta',
              secondsToClose: 5,
              type: 'error'
            },
            { root: true }
          )
          return Promise.reject(error)
        })
    },
    saveLinkContact ({ dispatch }, data) {
      const { $axios } = this.state
      const { contactId, folderId } = data
      return $axios
        .post(
          `contact/attachment/link/contact/${contactId}/folder/${folderId}`,
          data
        )
        .then((result) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Arquivo incluído com sucesso',
              secondsToClose: 5,
              type: 'success'
            },
            { root: true }
          )
          return result
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)
          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao incluir link',
              secondsToClose: 5,
              type: 'error'
            },
            { root: true }
          )
          return Promise.reject(error)
        })
    },
    deleteLinkContact ({ dispatch }, data) {
      const { $axios } = this.state
      const { linkId } = data
      return $axios
        .delete(`contact/attachment/link/${linkId}`)
        .then((result) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Link deletado com sucesso',
              secondsToClose: 5,
              type: 'success'
            },
            { root: true }
          )
          return result
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)
          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao deletar link',
              secondsToClose: 5,
              type: 'error'
            },
            { root: true }
          )
          return Promise.reject(error)
        })
    },
    replaceFileContact ({ dispatch }, data) {
      const { $axios } = this.state
      return $axios
        .post('contact/attachment/replace/file', data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((result) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Arquivo substituido com sucesso',
              secondsToClose: 5,
              type: 'success'
            },
            { root: true }
          )
          return result
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)
          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao substituir arquivo',
              secondsToClose: 5,
              type: 'error'
            },
            { root: true }
          )
          return Promise.reject(error)
        })
    },
    saveNewVersionFileContact ({ dispatch }, data) {
      const { $axios } = this.state
      return $axios
        .post('contact/attachment/version/file', data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((result) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Arquivo incluído com sucesso',
              secondsToClose: 5,
              type: 'success'
            },
            { root: true }
          )
          return result
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)
          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao incluir arquivo',
              secondsToClose: 5,
              type: 'error'
            },
            { root: true }
          )
          return Promise.reject(error)
        })
    },
    setCurrentPipeline ({ commit }, data) {
      commit('SET_CURRENTPIPELINE', data)
    },
    shared ({ dispatch }, data) {
      const { $axios } = this.state
      return $axios
        .put('contact/attachment/shared/file', data)
        .then((result) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Arquivo atualizado com sucesso',
              secondsToClose: 5,
              type: 'success'
            },
            { root: true }
          )
          return result
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)
          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao atualizar arquivo',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    moveFile ({ dispatch }, data) {
      const { $axios } = this.state
      return $axios
        .put('contact/attachment/move/file', data)
        .then((result) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Arquivo movido com sucesso',
              secondsToClose: 5,
              type: 'success'
            },
            { root: true }
          )
          return result
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)
          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao mover arquivo',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    getDirRoot ({ commit, dispatch }, data) {
      const { $axios } = this.state
      return $axios
        .get(`/pipeline/attachment/folder/root/${data.name}`)
        .then((result) => {
          commit('SET_CURRENT_FOLDER', result.data)
          return result
        })
        .catch((error) => {
          if (!error.response?.data?.authUrl) {
            console.error(error)

            const message = 'Falha ao buscar pasta'

            dispatch(
              'snackbar/showSnackbar',
              {
                message,
                type: 'error'
              },
              { root: true }
            )

            return
          }


          const { authUrl } = error.response.data

          openWindow(authUrl)

          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'É necessário autenticar-se para listar as pastas',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

        })
    },
    saveFileContract ({ commit, dispatch }, data) {
      const { $axios } = this.state
      return $axios
        .post('/pipeline/attachment/contract/file', data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((result) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Arquivo incluído com sucesso',
              secondsToClose: 5,
              type: 'success'
            },
            { root: true }
          )
          return result
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)
          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao incluir arquivo',
              secondsToClose: 5,
              type: 'error'
            },
            { root: true }
          )
          return Promise.reject(error)
        })
    }
  }
}
