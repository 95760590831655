<template>
  <div class="snackbar" :class="type">
    <div class="content">
      <em v-if="icon" class="fas" :class="icon"></em>
      <span v-html="message"></span>
    </div>
    <button @click="close">
      <em class="fas fa-times"></em>
    </button>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  props: {
    message: String,
    type: String
  },
  computed: {
    icon () {
      let icon = ''

      switch (this.type) {
        case 'error':
          icon = 'fa-exclamation-circle'
          break
        case 'warn':
          icon = 'fa-comment-alt-exclamation'
          break
        case 'success':
          icon = 'fa-check-circle'
          break
        case 'info':
          icon = 'fa-info-circle'
          break
        default:
          icon = ''
      }

      return icon
    }
  },
  methods: {
    ...mapActions({
      closeSnackbar: 'snackbar/close'
    }),
    close () {
      this.closeSnackbar(this.$.vnode.key)
    }
  }
}
</script>
