<template>
  <div class="flex-wrapper" :class="{ small: !!small }">
    <div class="single-chart">
      <svg
        viewBox="0 0 36 36"
        class="circular-chart"
        :class="{
          green: percentage > 60,
          yellow: percentage > 30 && percentage <= 60,
          red: percentage <= 30
        }"
      >
        <path
          class="circle-bg"
          d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path
          class="circle"
          :stroke-dasharray="`${percentage}, 100`"
          d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <text x="20" y="22" class="percentage f-paragraph-xxs">
          {{ percentage }}%
        </text>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PercentageCircle',
  props: {
    percentage: {
      type: Number,
      required: true
    },
    small: {
      type: Boolean
    }
  }
}
</script>

<style scoped>
.flex-wrapper {
  display: flex;
}

.single-chart {
  width: 68px;
}

.circular-chart {
  display: block;
  margin: 10px auto;
  max-width: 80%;
  max-height: 250px;
}

.circle-bg {
  fill: none;
  stroke: #eee;
  stroke-width: 3.8;
}

.circle {
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

.small {
  transform: scale(0.8);
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.circular-chart.yellow .circle {
  stroke: #c2bb00;
}
.circular-chart.green .circle {
  stroke: #13b279;
}

.circular-chart.red .circle {
  stroke: #ea2a00;
}

.percentage {
  fill: var(--text-default);
  font-weight: 600;
  text-anchor: middle;
}
</style>
