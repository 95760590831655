import { createApp } from 'vue'
import VueApexCharts from 'vue3-apexcharts'
import axios from 'axios'
import mitt from 'mitt'

import App from './App.vue'
import router from './router'
import store from './store'

import Autocomplete from '@/components/ui/Autocomplete.vue'
import Avatar from '@/components/ui/Avatar.vue'
import Dialog from '@/components/ui/Dialog.vue'
import ConfirmExitDialog from '@/components/ui/ConfirmExitDialog.vue'
import Dropdown from '@/components/ui/Dropdown.vue'
import Snackbar from '@/components/ui/Snackbar.vue'
import Tooltip from '@/components/ui/Tooltip.vue'
import Form from '@/components/ui/Form.vue'
import InputFile from '@/components/ui/InputFile.vue'
import Textfield from '@/components/ui/Textfield.vue'
import Select from '@/components/ui/Select.vue'
import Checkbox from '@/components/ui/Checkbox.vue'
import Radio from '@/components/ui/Radio.vue'
import Switch from '@/components/ui/Switch.vue'
import Datepicker from '@/components/ui/Datepicker.vue'
import Slider from '@/components/ui/Slider.vue'
import PreviewFile from '@/components/ui/PreviewFile.vue'
import Wysiwyg from '@/components/ui/Wysiwyg.vue'
import cookieConfig from './store/cookieConfig'
import helpers from './mixins/helpers'
import ToastPlugin from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-bootstrap.css'

const clickOutside = {
  beforeMount: (el, binding) => {
    el.clickOutsideEvent = (event) => {
      const { handler, element } = binding.value
      if (!(el === event.target || element === event.target)) {
        handler()
      }
    }
    document.addEventListener('click', el.clickOutsideEvent)
  },
  unmounted: (el) => {
    document.removeEventListener('click', el.clickOutsideEvent)
  }
}

const app = createApp(App)

app.use(ToastPlugin, {
  position: 'top-right',
})

app.mixin(helpers)

const axiosConfig = axios.create({
  baseURL: process.env.VUE_APP_API_URL || 'http://localhost:3000/api',
  withCredentials: true
})

axiosConfig.interceptors.request.use(function (config) {
  config.headers.token = cookieConfig.getCookie('token_plonge')
  return config
}, Promise.reject)

axiosConfig.interceptors.response.use(
  (response) => response,
  function (error) {
    if (error.response?.data?.message) {
      const message = error.response?.data?.message

      if (typeof message === 'object') {
        error.parsedErrorText = Object.values(message).join('\n')
      }

      if (typeof message === 'string') error.parsedErrorText = message

      if (error?.response?.status === 401) window.location.href = '/login'
    }

    return Promise.reject(error)
  }
)

app.config.globalProperties.$axios = axiosConfig
app.config.globalProperties.$emitter = mitt()

app.use(store(app)).use(router)

app.use(VueApexCharts)

app.directive('click-outside', clickOutside)

app.component('BAutocomplete', Autocomplete)
app.component('BAvatar', Avatar)
app.component('BDialog', Dialog)
app.component('BConfirmExitDialog', ConfirmExitDialog)
app.component('BDropdown', Dropdown)
app.component('BSnackbar', Snackbar)
app.component('BTooltip', Tooltip)
app.component('BForm', Form)
app.component('BInputFile', InputFile)
app.component('BTextfield', Textfield)
app.component('BSelect', Select)
app.component('BCheckbox', Checkbox)
app.component('BRadio', Radio)
app.component('BSwitch', Switch)
app.component('BDatepicker', Datepicker)
app.component('BSlider', Slider)
app.component('BPreviewFile', PreviewFile)
app.component('BWysiwyg', Wysiwyg)

app.mount('#app')
