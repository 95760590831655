<template>
  <div>
    <div class="dialog" :class="{ active: dialog }">
      <div class="dialog-content">
        <div class="dialog-content-header">
          <div class="btn icon btn-coral no-hover">
            <em class="fal fa-rocket-launch"></em>
          </div>
          <div class="flex-grow-1 h4 ml-2 mr-2">Kickoff</div>
          <button class="btn text icon" @click="close()">
            <em class="fal fa-times"></em>
          </button>
        </div>
        <span class="divider"></span>
        <div class="dialog-content-body">
          <div class="d-flex align-center gap-16 pa-3">
            <b-textfield class="mb-0 sm flex-grow-1" icon="fal fa-calendar" placeholder="Data do kickoff" />
            <button class="btn sm btn-indigo">
              <em class="fal fa-scroll"></em>
              <span>Roteiro</span>
            </button>
            <button class="btn sm btn-coral">
              <em class="fal fa-mask-snorkel"></em>
              <span>Mergulho</span>
            </button>
          </div>
          <span class="divider"></span>
          <div class="d-flex align-center gap-16 pa-3 bg-contrast">
            <div class="btn no-hover icon xl">
              <em class="fal fa-folder-user"></em>
            </div>
            <div>
              <div class="paragraph">ER456</div>
              <div class="paragraph md"><strong>Posição</strong></div>
              <div class="paragraph">Empresa</div>
            </div>
          </div>
          <span class="divider"></span>
          <div class="pa-3">
            <div class="row">
              <div class="col-xs-12 col-sm-6">
                <b-autocomplete label="Periodicidade FUP com cliente" />
              </div>
              <div class="col-xs-12 col-sm-6">
                <b-autocomplete label="Periodicidade FUP com internos" />
              </div>
            </div>
          </div>
          <span class="divider"></span>
          <div class="pa-3">
            <div class="subheader mb-2">Sources</div>
            <div class="d-flex justify-end mb-2">
              <button class="btn btn-coral outlined sm" @click="openModal('sources')">
                <em class="fal fa-plus"></em>
                <span>Sources</span>
              </button>
            </div>
            <div class="list list-with-shadow">
              <div v-for="i in 4" :key="i + '-source'" class="list-item">
                <em class="fal fa-compass"></em>
                <div class="paragraph xs flex-grow-1">Source</div>
                <button class="icon">
                  <em class="fal fa-trash"></em>
                </button>
              </div>
            </div>
          </div>
          <span class="divider"></span>
          <div class="pa-3">
            <div class="subheader mb-2">Projetos relacionados</div>
            <div class="d-flex justify-end mb-2">
              <button class="btn btn-coral outlined sm" @click="openModal('projetos')">
                <em class="fal fa-plus"></em>
                <span>Projetos</span>
              </button>
            </div>
            <div class="list list-with-shadow">
              <div v-for="i in 4" :key="i + '-projetos'" class="list-item">
                <em class="fal fa-folder-user"></em>
                <div class="paragraph xs">Empresa</div>
                <div class="paragraph xs flex-grow-1 font-weight-700">
                  Posição
                </div>
                <button class="icon text">
                  <em class="fal fa-trash"></em>
                </button>
              </div>
            </div>
          </div>
          <span class="divider"></span>
          <div class="pa-3">
            <div class="subheader mb-2">Posições anteriores</div>
            <div class="d-flex justify-end mb-2">
              <button class="btn btn-coral outlined sm" @click="openModal('projetos')">
                <em class="fal fa-plus"></em>
                <span>Projetos</span>
              </button>
            </div>
            <div class="list list-with-shadow">
              <div v-for="i in 4" :key="i + '-projetos'" class="list-item">
                <em class="fal fa-user-circle"></em>
                <div class="paragraph xs">Empresa</div>
                <div class="paragraph xs flex-grow-1 font-weight-700">
                  Posição
                </div>
                <div class="d-flex align-center gap-8 text-color-light paragraph xs">
                  <div class="d-flex align-center gap-4">
                    <em class="fal fa-calendar"></em>
                    <span>20/01/2021</span>
                  </div>
                  <em class="fal fa-angle-right"></em>
                  <div class="d-flex align-center gap-4">
                    <em class="fal fa-calendar"></em>
                    <span>10/04/2022</span>
                  </div>
                </div>
                <button class="icon text">
                  <em class="fal fa-trash"></em>
                </button>
              </div>
            </div>
          </div>
          <span class="divider"></span>
          <div class="pa-3">
            <b-textfield :multiline="true" class="mb-0" label="Observações" />
          </div>
        </div>
        <span class="divider"></span>
        <div class="dialog-content-footer justify-end">
          <button class="btn outlined sm">Cancelar</button>
          <button class="btn btn-coral sm">Salvar</button>
        </div>
      </div>
    </div>
    <SourcesModal ref="sources" />
    <ProjetosModal ref="projetos" />
  </div>
</template>

<script>
import SourcesModal from './sources.vue'
import ProjetosModal from './projetos.vue'
import mixins from '../../mixins'
export default {
  name: 'kickoffModal',
  mixins: [mixins],
  components: { SourcesModal, ProjetosModal },
  data () {
    return {
      dialog: false
    }
  },
  methods: {
    open () {
      this.dialog = true
    },
    close () {
      this.dialog = false
    }
  }
}
</script>
