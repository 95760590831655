<template>
  <div class="sidebar">
    <div class="logo">
      <img src="favicon-dark.png" alt="" />
    </div>
    <ul class="menu">
      <!-- <li @focusout="menuOut" tabindex="0">
        <b-tooltip x="left">
          <template v-slot:activator="{ on }">
            <a @click="openMenu" v-on="on">
              <em class="fal fa-grid-2"></em>
            </a>
          </template>
          Apps
        </b-tooltip>
        <ul class="grid">
          <li>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <img src="/images/conta-azul.png" alt="" />
              <span>Conta Azul</span>
            </a>
          </li>
          <li>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <img src="/images/pipedrive.png" alt="" />
              <span>Pipedrive</span>
            </a>
          </li>
          <li>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <img src="/images/sharepoint.png" alt="" />
              <span>SharePoint</span>
            </a>
          </li>
          <li>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <img src="/images/teams.png" alt="" />
              <span>Teams</span>
            </a>
          </li>
          <li>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <img src="/images/power-bi.png" alt="" />
              <span>Power BI</span>
            </a>
          </li>
        </ul>
      </li> -->
      <li>
        <b-tooltip x="left">
          <template v-slot:activator="{ on }">
            <router-link to="/" v-on="on">
              <em class="fal fa-rectangle-vertical-history"></em>
            </router-link>
          </template>
          Pipeline overview
        </b-tooltip>
      </li>
      <!-- <li @focusout="menuOut" tabindex="1">
        <b-tooltip x="left">
          <template v-slot:activator="{ on }">
            <a @click="openMenu" v-on="on">
              <em class="fal fa-circle-dollar-to-slot"></em>
            </a>
          </template>
          Financeiro
        </b-tooltip>
        <ul class="list">
          <li>
            <a href="#">
              <div class="icon">
                <em class="fal fa-chart-area"></em>
              </div>
              <span>Dashboard</span>
            </a>
          </li>
          <li>
            <a href="#">
              <div class="icon">
                <em class="fal fa-money-check-dollar-pen"></em>
              </div>
              <span>Contas a pagar e receber</span>
            </a>
          </li>
          <li>
            <a href="#">
              <div class="icon">
                <em class="fal fa-file-invoice-dollar"></em>
              </div>
              <span>Notas emitidas</span>
            </a>
          </li>
        </ul>
      </li> -->
      <li>
        <b-tooltip x="left">
          <template v-slot:activator="{ on }">
            <router-link to="/empresa-crm" v-on="on">
              <em class="fal fa-building"></em>
            </router-link>
          </template>
          Empresas
        </b-tooltip>
      </li>
      <li>
        <b-tooltip x="left">
          <template v-slot:activator="{ on }">
            <router-link to="/crm" v-on="on">
              <em class="fal fa-address-book"></em>
            </router-link>
          </template>
          Contatos - CRM
        </b-tooltip>
      </li>
      <!-- <li @focusout="menuOut" tabindex="2">
        <b-tooltip x="left">
          <template v-slot:activator="{ on }">
            <a @click="openMenu" v-on="on">
              <em class="fal fa-sliders-up"></em>
            </a>
          </template>
          Configurações
        </b-tooltip>
        <ul class="list">
          <li>
            <a href="#">
              <div class="icon">
                <em class="fal fa-scroll"></em>
              </div>
              <span>Roteiro de planejamento</span>
            </a>
          </li>
          <li>
            <a href="#" @click.prevent="openModal('impostosModal')">
              <div class="icon">
                <em class="fal fa-circle-dollar"></em>
              </div>
              <span>Configuração de Impostos</span>
            </a>
          </li>
          <li>
            <a href="#" @click.prevent="openModal('impostosModal')">
              <div class="icon">
                <em class="fal fa-list-check"></em>
              </div>
              <span>Cadastro de atividades</span>
            </a>
          </li>
        </ul>
      </li> -->
      <li @focusout="menuOut" tabindex="2">
        <b-tooltip x="left">
          <template v-slot:activator="{ on }">
            <a @click="openMenu" v-on="on">
              <em class="fal fa-clipboard-list"></em>
            </a>
          </template>
          Relatórios
        </b-tooltip>
        <ul class="list">
          <li>
            <a href="#" @click.prevent="reletCandidates">
              <div class="icon">
                <em class="fal fa-screen-users"></em>
              </div>
              <span>Candidatos</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
    <div class="footer">
      <ul class="menu profile">
        <ProfileMenu
          @openNotifications="openModal('notifications')"
          @toggleTheme="toggleTheme()"
        />
      </ul>
      <ul class="menu ellipsis">
        <li @focusout="menuOut" tabindex="3">
          <b-tooltip x="left">
            <template v-slot:activator="{ on }">
              <a href="#" @click="openMenu" v-on="on">
                <em class="fal fa-ellipsis-v"></em>
              </a>
            </template>
            Perfil
          </b-tooltip>
          <ul class="list">
            <ProfileMenu
              @openNotifications="openModal('notifications')"
              @toggleTheme="toggleTheme()"
            />
          </ul>
        </li>
      </ul>
      <span class="divider" />
      <ul class="menu">
        <li>
          <b-tooltip x="left">
            <template v-slot:activator="{ on }">
              <router-link to="/login" @click="cleanLogin()" v-on="on">
                <em class="fal fa-arrow-right-from-bracket"></em>
              </router-link>
            </template>
            Sair
          </b-tooltip>
        </li>
      </ul>
    </div>
  </div>
  <ImpostosModal ref="impostosModal" />
  <Notifications ref="notifications" />
</template>

<script>
import { mapActions } from 'vuex'
import mixins from '@/mixins'
import ImpostosModal from '@/components/modals/impostos.vue'
import ProfileMenu from '@/components/ProfileMenu.vue'
import Notifications from './Notifications.vue'
import cookieConfig from '@/store/cookieConfig'
export default {
  name: 'SidebarComponent',
  mixins: [mixins],
  components: {
    ImpostosModal,
    Notifications,
    ProfileMenu
  },
  methods: {
    ...mapActions({
      toggleTheme: 'theme/toggleTheme',
      exportCandidates: 'relets/candidates'
    }),
    cleanLogin() {
      cookieConfig.removeCookie('token_plonge')
      this.$router.push('/login')
    },
    openMenu(event) {
      let element = event.target.nextSibling
      if (event.target.nextSibling.nodeName === '#text') {
        element = event.target.parentElement.querySelector('ul')
      }
      if (element) {
        if (element.classList.contains('active')) {
          element.classList.remove('active')
        } else {
          element.classList.add('active')
        }
      }
    },
    menuOut() {
      if (document.querySelector('ul.active')) {
        document.querySelector('ul.active').classList.remove('active')
      }
    },
    reletCandidates() {
      this.exportCandidates()
    }
  }
}
</script>
