import { createStore } from 'vuex'

import company from './company'
import dialog from './dialog'
import theme from './theme'
import contacts from './contacts'
import prospect from './prospect'
import users from './users'
import auth from './login'
import leads from './leads'
import history from './history'
import activities from './activities'
import jobs from './jobs'
import business from './business'
import home from './home'
import session from './session'
import snackbar from './snackbar'
import cookieConfig from './cookieConfig'
import attachments from './attachments'
import curriculum from './curriculum'
import relets from './relets'

export default (app) =>
  createStore({
    state: {
      $axios: app.config.globalProperties.$axios
    },
    modules: {
      auth,
      activities,
      history,
      cookieConfig,
      company,
      dialog,
      users,
      theme,
      contacts,
      prospect,
      leads,
      jobs,
      business,
      snackbar,
      session,
      home,
      attachments,
      curriculum,
      relets
    }
  })
