const orderObject = (data) => {
  const result = {}

  const keys = Object.keys(data).sort((a, b) => {
    if (a > b) return 1
    if (a < b) return -1
    return 0
  })

  keys.forEach((key) => {
    if (data[key] === null || data[key] === undefined) {
      result[key] = data[key]
      return
    }

    if (data[key].constructor.name === 'Object') {
      result[key] = orderObject(data[key])
      return
    }

    if (data[key] instanceof Array) {
      result[key] = orderArray(data[key])
      return
    }

    result[key] = data[key]
  })

  return result
}

const orderArray = (data) => {
  const clonedArray = Object.assign([], data)

  if (!clonedArray.length) return []

  const [firstElement] = clonedArray
  const isArrayOfObjects = firstElement.constructor.name === 'Object'

  if (!isArrayOfObjects) {
    clonedArray.sort((a, b) => {
      if (a > b) return 1
      if (a < b) return -1
      return 0
    })

    return clonedArray
  }

  const containsIdProperty = Object.keys(firstElement)
    .map((property) => property.toLowerCase())
    .includes('id')

  if (!containsIdProperty) return clonedArray.map(orderObject)

  clonedArray.sort((a, b) => {
    if (a.id > b.id) return 1
    if (a.id < b.id) return -1
    return 0
  })

  return clonedArray.map(orderObject)
}

const serialize = (data) => JSON.stringify(orderObject(data))

export default {
  namespaced: true,
  state: {
    show: false,
    snapshot: null,
    callback: () => {},
    discard: () => {},
    save: () => {}
  },
  getters: {
    show: (state) => state.show
  },
  mutations: {
    SET_DIALOG (state, { show = null, callback = null, discard = null, save = null }) {
      if (show !== null) state.show = show
      if (callback instanceof Function) state.callback = callback
      if (discard instanceof Function) state.discard = discard
      if (save instanceof Function) state.save = save
    },
    SET_SNAPSHOT (state, snapshot) {
      state.snapshot = snapshot
    }
  },
  actions: {
    async discardChanges ({ state, dispatch }) {
      if (state.discard instanceof Function) await state.discard()
      if (state.callback instanceof Function) await state.callback()
      dispatch('closeDialog')
    },
    async saveChanges ({ state, dispatch }) {
      if (state.save instanceof Function) await state.save()
      if (state.callback instanceof Function) await state.callback()
      dispatch('closeDialog')
    },
    closeDialog ({ commit }) {
      commit('SET_DIALOG', { show: false })
    },
    openIfChanged ({ state, commit }, { data, callback, discard, save }) {
      if (!data || !save) {
        if (callback instanceof Function) callback()
        return
      }
      const snapshot = serialize(data)

      if (state.snapshot === snapshot) {
        if (callback instanceof Function) callback()
        return
      }

      commit('SET_DIALOG', {
        show: true,
        callback,
        discard,
        save
      })
    },
    setSnapshot ({ commit }, data) {
      if (data === null || data === undefined) return
      if (data.constructor.name !== 'Object') return

      const snapshot = serialize(data)

      commit('SET_SNAPSHOT', snapshot)
    }
  }
}
