<template>
  <div class="w-100">
    <div class="w-100">
      <div v-if="!candidancy" class="subheader title pa-3">Currículo</div>
      <span v-if="!candidancy" class="divider"></span>
      <User v-if="!candidancy" />
      <div
:class="`list list-with-divider list-flat referencias w-100 max-md ${!candidancy ? 'pl-3 pr-3' : ''
        }`">
        <div class="w-100 d-flex justify-end pt-1 pb-1">
          <button class="btn btn-coral outlined sm" @click="openReferencesDialog">
            <em class="fal fa-plus"></em>
            <span>Nova referência</span>
          </button>
        </div>
        <div v-for="contact in recordList" :key="contact.id" class="list-item">
          <b-avatar :name="contact.contactsReferences[0].name" size="sm">
            <img :src="contact.contactsReferences[0].photo" :alt="contact.contactsReferences[0].name" />
          </b-avatar>
          <div class="flex-grow-1">
            <div class="paragraph sm">
              <strong v-text="contact.contactsReferences[0].name"></strong>
            </div>
            <div class="paragraph xs text-color-light" v-text="contact.emails.length ? contact.emails[0].email : ''">
            </div>
          </div>
          <div v-if="!candidancy" class="paragraph sm">
            <strong>Posição</strong>
          </div>
          <div @click.prevent.stop="">
            <b-dropdown x="right">
              <template #trigger="{ on }">
                <button class="btn-icon" v-on="on">
                  <em class="fal fa-ellipsis-vertical"></em>
                </button>
              </template>

              <template #content>
                <div class="list">
                  <router-link :to="`/contato?tab=dados&id=${contact.contactsReferences[0].id}`" class="list-item">
                    <em class="fal fa-address-book"></em>
                    <span>Ir para contato</span>
                  </router-link>
                  <button class="list-item" @click="openUserModal(contact)">
                    <em class="fal fa-magnifying-glass"></em>
                    <span>Visualizar contato</span>
                  </button>
                  <button class="list-item" @click="openReferencesDialogUpdate(contact)">
                    <em class="fal fa-pen"></em>
                    <span>Atualizar referência</span>
                  </button>
                  <span class="divider"></span>
                  <button class="list-item text-color-danger" @click="removeReferences(contact.id)">
                    <em class="fal fa-ban text-color-danger"></em>
                    <span>Remover vínculo</span>
                  </button>
                </div>
              </template>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>
    <b-dialog v-model="dialog.references" size="lg" :dismissable="false">

      <template #header>
        <button class="btn icon btn-coral" @click="openNovoContato">
          <em class="fal fa-user-plus"></em>
        </button>
        <div class="flex-grow-1 h4 ml-2 mr-2 text-color-default">Referência</div>
        <button class="btn text icon" @click="closeDialog('references')">
          <em class="fal fa-times"></em>
        </button>
      </template>

      <template #body>
        <div class="pa-2 bg-default">
          <b-autocomplete
v-model="forms.contactReferenceId" label="Contato" :options="records" option-text="name"
            option-value="id"></b-autocomplete>
          <p v-if="errors.contactReferenceId" class="text-color-danger">
            {{ errors.contactReferenceId }}
          </p>
          <b-autocomplete
v-model="forms.relationshipStatusId" label="Relacionamento" :options="relationshipStatus"
            option-text="name" option-value="id"></b-autocomplete>
          <p v-if="errors.relationshipStatusId" class="text-color-danger">
            {{ errors.relationshipStatusId }}
          </p>
          <b-textfield v-model="forms.jobReference" label="Descrição" :multiline="true" :hint="errors.jobReference" />
        </div>
      </template>

      <template #footer>
        <div class="d-flex gap-16 justify-end w-100">
          <button class="btn outlined sm" @click="closeDialog('references')">
            Voltar
          </button>
          <button class="btn btn-coral sm" @click="saveReference">Salvar</button>
        </div>
      </template>
    </b-dialog>

    <b-dialog v-model="dialog.updateReferences" size="lg" :dismissable="false">

      <template #header>
        <button class="btn icon btn-coral" @click="openNovoContato">
          <em class="fal fa-user-plus"></em>
        </button>
        <div class="flex-grow-1 h4 ml-2 mr-2 text-color-default">
          Atualizar Referência
        </div>
        <button class="btn text icon" @click="closeDialog('updateReferences')">
          <em class="fal fa-times"></em>
        </button>
      </template>

      <template #body>
        <div class="pa-2 bg-default">
          <b-autocomplete
v-model="forms.contactReferenceId" label="Contato" :options="records" option-text="name"
            option-value="id"></b-autocomplete>
          <p v-if="errors.contactReferenceId" class="text-color-danger">
            {{ errors.contactReferenceId }}
          </p>
          <b-autocomplete
v-model="forms.relationshipStatusId" label="Relacionamento" :options="relationshipStatus"
            option-text="name" option-value="id"></b-autocomplete>
          <p v-if="errors.relationshipStatusId" class="text-color-danger">
            {{ errors.relationshipStatusId }}
          </p>
          <b-textfield v-model="forms.jobReference" label="Descrição" :multiline="true" :hint="errors.jobReference" />
        </div>
      </template>

      <template #footer>
        <div class="d-flex gap-16 justify-end w-100">
          <button class="btn outlined sm" @click="closeDialog('updateReferences')">
            Voltar
          </button>
          <button class="btn btn-coral sm" @click="updateReferences">
            Salvar
          </button>
        </div>
      </template>
    </b-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import mixins from '@/mixins'
import User from '@/components/contato/user.vue'

export default {
  name: 'ReferencesTab',
  components: { User },
  mixins: [mixins],
  props: {
    disabled: {
      type: Boolean
    },
    candidancy: {
      type: Boolean
    }
  },
  emits: ['openUser', 'novaReferencia', 'novoContato'],
  data () {
    return {
      filter: '',
      filtered: [],
      contactsIds: [],
      loadingMore: false,
      type: 'contact',
      quantity: 10,
      screen: '',
      dialog: {
        references: false,
        updateReferences: false
      },
      forms: {
        contactReferenceId: null,
        relationshipStatusId: null,
        jobReference: '',
        id: '',
        contactId: ''
      },
      errors: {
        jobReference: '',
        contactReferenceId: '',
        relationshipStatusId: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      total: 'contacts/total',
      currentCandidate: 'jobs/currentCandidate',
      currentPipeline: 'jobs/currentPipeline',
      contactsReference: 'jobs/contactsReference',
      records: 'contacts/contacts',
      relationshipStatus: 'jobs/relationshipStatus'
    }),
    recordList () {
      return this.contactsReference
    }
  },
  mounted () {
    if (!this.records.length) {
      this.getContacts()
    }
    this.loadRelationshipStatus()
  },
  methods: {
    ...mapActions({
      getContacts: 'contacts/loadContacts',
      saveReferences: 'jobs/saveReference',
      removeReferences: 'jobs/removeReference',
      updateReference: 'jobs/updateReference',
      loadRelationshipStatus: 'jobs/loadRelationshipStatus'
    }),
    openNovaReferencia () {
      this.$emit('novaReferencia')
    },
    openNovoContato () {
      if (this.dialog.references) {
        this.closeDialog('references')
      }

      if (this.dialog.updateReferences) {
        this.closeDialog('updateReferences')
      }

      this.$emit('novoContato')
    },
    openUserModal (data) {
      this.$emit('openUser', {
        id: data.contactsReferences[0].id,
        name: data.contactsReferences[0].name,
        email: data.emails.length ? data.emails[0].email : '',
        phone: data.phones.length ? data.phones[0].phone : '',
        photo: data.contactsReferences[0].photo
      })
    },
    closeDialog (dialogName) {
      this.dialog[dialogName] = false
      this.clearForm()
    },
    openReferencesDialog () {
      this.dialog.references = true
    },
    openReferencesDialogUpdate (reference) {
      this.dialog.updateReferences = true
      this.forms.jobReference = reference.jobReference
      this.forms.id = reference.id
      this.forms.contactId = reference.contactId
      this.forms.contactReferenceId = parseInt(reference.contactReferenceId)
      this.forms.relationshipStatusId = reference.relationshipStatusId
    },
    saveReference () {
      if (!this.forms.contactReferenceId) {
        this.errors.contactReferenceId = 'Selecione um contato'
        return
      } else {
        this.errors.contactReferenceId = ''
      }

      if (!this.forms.relationshipStatusId) {
        this.errors.relationshipStatusId = 'Selecione um relacionamento'
        return
      } else {
        this.errors.relationshipStatusId = ''
      }

      if (!this.forms.jobReference) {
        this.errors.jobReference = 'Campo obrigatório'
        return
      } else {
        this.errors.jobReference = ''
      }

      this.saveReferences({
        pipelineId: this.currentPipeline.id,
        contactId: this.currentCandidate.contactId,
        contactReferenceId: this.forms.contactReferenceId,
        jobReference: this.forms.jobReference,
        relationshipStatusId: this.forms.relationshipStatusId
      })

      this.dialog.references = false
      this.clearForm()
    },
    updateReferences () {
      if (!this.forms.jobReference) {
        this.errors.jobReference = 'Campo obrigatório'
        return
      } else {
        this.errors.jobReference = ''
      }

      if (!this.forms.relationshipStatusId) {
        this.errors.relationshipStatusId = 'Selecione um relacionamento'
        return
      } else {
        this.errors.relationshipStatusId = ''
      }

      if (!this.forms.contactReferenceId) {
        this.errors.contactReferenceId = 'Selecione um contato'
        return
      } else {
        this.errors.contactReferenceId = ''
      }
      this.updateReference({
        jobReference: this.forms.jobReference,
        contactId: this.forms.contactId,
        id: this.forms.id,
        relationshipStatusId: this.forms.relationshipStatusId,
        contactReferenceId: this.forms.contactReferenceId
      })

      this.dialog.updateReferences = false
    },
    clearForm () {
      this.forms.contactReferenceId = ''
      this.forms.jobReference = ''
      this.forms.contactId = ''
      this.forms.relationshipStatusId = ''
      this.forms.id = ''
    },
  }
}
</script>

<style lang="scss" scoped>
div:not(.scrollable) .list-with-divider>.list-item {
  padding: 16px 0;
}

.referencias {
  background: var(--bg-contrast);
}
</style>
