<template>
  <div class="form-group" :class="{ 'has-error': errorText }">
    <label :key="id">{{ label }}</label>
    <div class="input-group">
      <div class="input-field has-icon">
        <select
          v-model="model"
          :id="id"
          :class="{ disabled }"
          :disabled="disabled"
          class="form-control"
          required
        >
          <option value="" disabled selected>{{ placeholder }}</option>
          <template v-if="options && options.length">
            <option
              v-for="item in options"
              :key="item"
              :value="item[optionValue] || item"
            >
              {{ item[optionText] || item }}
            </option>
          </template>
        </select>
        <div class="icon">
          <em class="fal" :class="icon ? `fa-${icon}` : ''"></em>
        </div>
      </div>
      <slot name="append-outer" />
    </div>
  </div>
</template>

<script>
import mixins from './mixins/validation'
export default {
  name: 'SelectField',
  mixins: [mixins],
  props: {
    label: String,
    modelValue: {
      type: String || Number,
      default: ''
    },
    options: Array,
    placeholder: {
      type: String,
      default: 'Selecione...'
    },
    id: String,
    disabled: {
      default: false
    },
    optionText: {
      type: String,
      default: ''
    },
    optionValue: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    },
    icon: {
      type: String,
      default: 'chevron-down'
    }
  },
  computed: {
    model: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  data () {
    return {
      error: [],
      uud: Math.random().toString(16).slice(2)
    }
  },
  watch: {
    model () {
      this.validate()
    }
  }
}
</script>
