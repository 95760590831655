<template>
  <div class="toolbar">
    <div class="toolbar-left">
      <template v-if="title">
        <div class="toolbar-icon">
          <em class="fal fa-rectangle-vertical-history"></em>
        </div>
        <div class="toolbar-title">{{ title }}</div>
        <div class="badge md bg-coral" v-if="total">{{ total }}</div>
      </template>
      <slot name="left"></slot>
    </div>
    <div class="toolbar-right">
      <template v-if="!$slots.right">
        <b-textfield placeholder="Buscar" name="search" type="search" appendIcon="fal fa-search" class="sm mb-0"
          v-model="filterSearch" />
        <b-dropdown x="right" size="md">
          <template v-slot:trigger="{ on: menu }">
            <b-tooltip x="right">
              <template v-slot:activator="{ on: tooltip }">
                <button v-on="{ ...tooltip, ...menu }" class="btn icon sm">
                  <em class="fal fa-bars-filter"></em>
                </button>
              </template>
              Filtros
            </b-tooltip>
          </template>
          <template #content>
            <div class="bg-contrast" @click.stop="">
              <div class="bg-default pa-2">
                <div class="subheader md">filtrar pipeline</div>
              </div>
              <span class="divider"></span>
              <div class="pa-2">
                <b-autocomplete v-model="contact" :options="responsibles" placeholder="Responsável" option-text="name"
                  option-value="id" />
                <b-autocomplete v-model="company" :options="companies" placeholder="Empresa" option-text="name"
                  option-value="id" />
                <b-autocomplete v-model="selectedStatus" :options="statusOptions()" placeholder="Status"
                  option-text="name" option-value="id" />
                <span class="divider justify-end"></span>
                <div class="subheader md pa-2">Possível data do fechamento</div>
                <b-datepicker v-model="possibleDate" placeholder="Inicio" id="filter-date" />
                <b-datepicker v-model="possibleDateFinish" placeholder="Fim" id="filter-date" />
              </div>
              <span class="divider"></span>
              <div class="d-flex justify-end pa-2 gap-16">
                <button class="btn sm outlined" @click="filterClear(true)">
                  Limpar
                </button>
                <button class="btn sm btn-coral" @click="filterItems">
                  Aplicar
                </button>
              </div>
            </div>
          </template>
        </b-dropdown>
        <b-tooltip x="right">
          <template v-slot:activator="{ on }">
            <button @click="filterFavorites()" class="btn icon sm" :class="{ 'btn-coral': this.activeFavoriteFilter }"
              v-on="on">
              <em class="fal fa-heart"></em>
            </button>
          </template>
          Favoritos
        </b-tooltip>
        <div class="btn-group">
          <b-tooltip x="right">
            <template v-slot:activator="{ on }">
              <button class="btn icon sm" :class="{ 'btn-coral': !showCharts }" @click="$emit('onPanel')" v-on="on">
                <em class="fal fa-rectangle-vertical-history"></em>
              </button>
            </template>
            Dashboard
          </b-tooltip>
          <b-tooltip x="right">
            <template v-slot:activator="{ on }">
              <button class="btn icon sm" :class="{ 'btn-coral': showCharts }" @click="$emit('onCharts')" v-on="on">
                <em class="fal fa-chart-mixed"></em>
              </button>
            </template>
            Dashboard
          </b-tooltip>
        </div>
      </template>
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { debounce } from '../helpers'

export default {
  name: 'ToolbarComponent',
  props: {
    title: {
      type: String,
      default: null
    },
    showCharts: {
      type: Boolean,
      default: false
    },
    total: Number
  },
  data () {
    return {
      activeFavoriteFilter: false,
      filter: null,
      contact: null,
      company: null,
      possibleDate: null,
      selectedStatus: null,
      possibleDateFinish: null,
      filterSearch: null,
      dataPipeline: []
    }
  },
  watch: {
    filterSearch: debounce(function (text) {
      this.filterSearchTextStep(text)
    }, 500)
  },
  computed: {
    ...mapGetters({
      contacts: 'contacts/contacts',
      statusHome: 'home/status',
      currentStep: 'session/currentStep',
      positionStatus: 'home/positionStatus',
      loggedUser: 'auth/loggedUser',
      userFavorites: 'home/userFavorites',
      responsibles: 'contacts/responsibles',
      closesDates: 'home/closesDates'
    })
  },
  methods: {
    ...mapActions({
      getProspects: 'home/getOnlyProspects',
      getLeads: 'home/getOnlyLeads',
      getBusiness: 'home/getOnlyBusiness',
      filterStatus: 'home/filterStatus',
      setNull: 'home/setNull',
      getFiltered: 'home/getFiltered',
      filterSearchText: 'home/filterSearchText'
    }),
    filterFavorites () {
      const activeData = this.statusHome.filter(
        (objStatus) => objStatus.active === true
      )[0]
      if (!this.activeFavoriteFilter) {
        const filteredData = activeData.data.filter(
          (objDataStatus) =>
            this.userFavorites.includes(objDataStatus.id) &&
            objDataStatus.stepId === activeData.id
        )
        this.filterStatus(filteredData)
      } else {
        this.setNull()
        this.getProspects()
        this.getLeads()
        this.getBusiness()
      }
      this.activeFavoriteFilter = !this.activeFavoriteFilter
    },
    async filterItems (text) {
      const comp = this.company
      const resp = this.contact
      const statusCheck = this.selectedStatus
      const dated = this.possibleDate
      const datedFinal = this.possibleDateFinish
      let filtered = []

      if (!comp && !resp && !text) {
        this.getProspects()
        this.getLeads()
        this.getBusiness()
        return
      }

      const queryParameters = []

      if (comp) {
        queryParameters.push(`companyId=${comp}`)
      }
      if (resp) {
        queryParameters.push(`responsibleId=${resp}`)
      }
      if (statusCheck) {
        queryParameters.push(`statusId=${statusCheck}`)
      }
      if (dated) {
        queryParameters.push(`initDate=${dated}`)
      }
      if (datedFinal) {
        queryParameters.push(`finalDate=${datedFinal}`)
      }
      const query =
        queryParameters.length > 0 ? '?' + queryParameters.join('&') : ''
      filtered = await this.getFiltered(query)
      if (filtered.length <= 0) {
        await this.setNull()
        return
      }

      if (filtered?.length > 0) {
        const filt1 = filtered.filter((object) => object.stepId === 1)
        const filt2 = filtered.filter((object) => object.stepId === 2)
        const filt3 = filtered.filter((object) => object.stepId === 3)
        this.setNull()
        if (filt1?.length > 0) {
          this.filterStatus(filt1)
        }
        if (filt2?.length > 0) {
          this.filterStatus(filt2)
        }
        if (filt3?.length > 0) {
          this.filterStatus(filt3)
        }
      }
      if ((comp || resp || text) && filtered?.length <= 0) {
        this.setNull()
      }
      this.$emitter.emit('viewLoadMore')
    },
    async filterSearchTextStep (text) {
      debounce(this.filterSearchText({ text: text, step: this.currentStep }), 500)
    },
    statusOptions () {
      const options = []

      options.push(
        ...this.positionStatus.prospect,
        ...this.positionStatus.lead,
        ...this.positionStatus.business
      )
      return options
    },
    filterClear (setNullFilter = false) {
      this.setNull()
      this.getProspects()
      this.getLeads()
      this.getBusiness()
      if (setNullFilter) {
        this.setNullFields()
      }
    },
    setNullFields () {
      this.contact = null
      this.company = null
      this.possibleDate = null
      this.selectedStatus = null
      this.possibleDateFinish = null
    }
  }
}
</script>
