<template>
  <div class="tab-content scrollable">
    <div class="pa-3">
      <div class="d-flex justify-end gap-8 mb-2">
        <b-tooltip x="right">
          <template v-slot:activator="{ on }">
            <button class="btn icon sm" v-on="on"
              :title="this.orderedFoldersWithFiles.length ? 'Crie uma pasta para anexar arquivos' : null"
              :disabled="this.orderedFoldersWithFiles.length < 1 ? true : false" @click="openFileDialog">
              <em class="fal fa-paperclip"></em>
            </button>
          </template>
          Anexar arquivo
        </b-tooltip>
        <b-tooltip x="right">
          <template v-slot:activator="{ on }">
            <button class="btn icon sm" v-on="on" :disabled="this.orderedFoldersWithFiles.length < 1 ? true : false"
              @click="openLinkDialog">
              <em class="fal fa-link"></em>
            </button>
          </template>
          Novo link
        </b-tooltip>
        <b-tooltip x="right">
          <template v-slot:activator="{ on }">
            <button class="btn icon sm" v-on="on" @click="dialog.folder = true">
              <em class="fal fa-folder-plus"></em>
            </button>
          </template>
          Nova pasta
        </b-tooltip>
      </div>
      <div>
        <div v-for="(folder, i) in this.orderedFoldersWithFiles" :key="folder.id" class="dropzone"
          @drop="onDrop($event, i)" @dragenter.prevent @dragover.prevent>
          <div v-if="folder.id" class="d-flex align-center pt-3 gap-16">
            <div class="btn icon text xs btn-coral">
              <em class="fal fa-folder"></em>
            </div>
            <div class="flex-grow-1 d-flex align-center gap-8">
              <div class="subheader md font-weight-600">{{ folder.name }}</div>
              <div class="badge">{{ folder.files.length }}</div>
            </div>
            <b-dropdown x="right">
              <template v-slot:trigger="{ on }">
                <button class="btn xxs icon text" v-on="on">
                  <em class="fal fa-ellipsis-vertical"></em>
                </button>
              </template>
              <template #content>
                <div class="list">
                  <button class="list-item" @click="openFileDialog(folder.id)">
                    <em class="fal fa-paperclip"></em>
                    <span>Anexar arquivo</span>
                  </button>
                  <button class="list-item" @click="openLinkDialog(folder.id)">
                    <em class="fal fa-link"></em>
                    <span>Adicionar link</span>
                  </button>
                  <button class="list-item" @click="openRenameFolderDialog(folder.id)">
                    <em class="fal fa-pen-line"></em>
                    <span>Renomear</span>
                  </button>
                  <span class="divider"></span>
                  <button class="list-item text-color-danger" :class="{ 'cursor-disabled': folder.files.length }"
                    :disabled="folder.files.length"
                    :title="folder.files.length ? 'Não é possível remover uma pasta com arquivos' : null"
                    @click="removeFolder(folder.id)">
                    <em class="fal fa-trash text-color-danger"></em>
                    <span>Excluir</span>
                  </button>
                </div>
              </template>
            </b-dropdown>
          </div>
          <div class="list list-with-shadow">
            <div v-for="(file, i) in folder.files" :key="file.id" class="dropzone" @drop="onDrop($event, i)"
              @dragenter.prevent @dragover.prevent>
              <div :draggable="false" class="list-item" @dragstart="startDrag($event, file)">
                <div class="icon">
                  <svg width="14" height="32" viewBox="0 0 14 32">
                    <use xlink:href="/images/grab.svg#icon" />
                  </svg>
                </div>
                <div class="btn xs icon no-hover">
                  <em class="fal" :class="mapIcon(file.webUrl, '')"></em>
                </div>
                <a :href="file.webUrl" target="_blank" class="paragraph xs flex-grow-1 lines-1 truncate truncate-after"
                  title="Clique para abrir">
                  {{ file.name?.trim() || getFileName(file.name) }}
                </a>
                <b-tooltip v-if="file.webUrl">
                  <button class="list-item">
                    <em class="fal" :class="file.webUrl ? 'fa-unlink text-color-coral' : 'fa-link'"></em>
                    <span :class="{ 'text-color-coral': file.webUrl }"
                      v-text="file.webUrl ? 'Parar de compartilhar' : 'Compartilhar'"></span>
                  </button>
                  <template v-slot:activator="{ on }">
                    <button class="btn xxs icon text btn-info" v-on="on" @click="copyLinkToClipboard(file.webUrl)">
                      <em class="fal fa-link"></em>
                    </button>
                  </template>
                  Copiar link compartilhável
                </b-tooltip>
                <b-dropdown x="right">
                  <template v-slot:trigger="{ on }">
                    <button class="btn xxs icon text" v-on="on">
                      <em class="fal fa-ellipsis-vertical"></em>
                    </button>
                  </template>
                  <template #content>
                    <div class="list">
                      <button class="list-item"
                        @click="sharedFile(file.id, file.parentReference.driveId, file.shared.scope === 'users' ? 'anonymous' : 'organization')">
                        <em class="fal" :class="file.webUrl ? 'fa-unlink text-color-coral' : 'fa-link'"></em>
                        <span :class="{ 'text-color-coral': file.webUrl }"
                          v-text="file.shared.scope !== 'users' ? 'Parar de compartilhar' : 'Compartilhar'"></span>
                      </button>
                      <button class="list-item"
                        @click="openRenameFileDialog(file.id, file.parentReference.driveId, file.name)">
                        <em class="fal fa-pen-line"></em>
                        <span>Renomear</span>
                      </button>
                      <button class="list-item"
                        @click="openNewVersionFileDialog(folder.id, file.id, file.parentReference.driveId)">
                        <em class="fal fa-code-branch"></em>
                        <span>Enviar nova versão</span>
                      </button>
                      <button class="list-item"
                        @click="openReplaceFile(folder.id, file.id, file.parentReference.driveId)">
                        <em class="fal fa-upload"></em>
                        <span>Substituir arquivo</span>
                      </button>
                      <a class="list-item" href="file['@microsoft.graph.downloadUrl']">
                        <em class="fal fa-download"></em>
                        <span>Download</span>
                      </a>
                      <button class="list-item"
                        @click="openMoveFileDialog(file.name, file.id, file.parentReference.driveId)">
                        <em class="fal fa-upload"></em>
                        <span>Mover Arquivo</span>
                      </button>
                      <span class="divider"></span>
                      <button @click="removeFile(file.id, file.parentReference.driveId)"
                        class="list-item text-color-danger">
                        <em class="fal fa-trash text-color-danger"></em>
                        <span>Excluir</span>
                      </button>
                    </div>
                  </template>
                </b-dropdown>
              </div>
            </div>
            <div v-for="(link, i) in folder.links" :key="link.id" class="dropzone" @drop="onDrop($event, i)"
              @dragenter.prevent @dragover.prevent>
              <div :draggable="false" class="list-item" @dragstart="startDrag($event, link)">
                <div class="icon">
                  <svg width="14" height="32" viewBox="0 0 14 32">
                    <use xlink:href="/images/grab.svg#icon" />
                  </svg>
                </div>
                <div class="btn xs icon no-hover">
                  <em class="fal" :class="mapIcon(link.name, 'L')"></em>
                </div>
                <a :href="link.content" target="_blank" class="paragraph xs flex-grow-1 lines-1 truncate truncate-after"
                  title="Clique para abrir">
                  {{ link?.content.trim() || getFileName(link?.content) }} {{ link?.content }}
                </a>
                <b-tooltip v-if="link.content">
                  <template v-slot:activator="{ on }">
                    <button class="btn xxs icon text btn-info" v-on="on" @click="copyLinkToClipboard(link.content)">
                      <em class="fal fa-link"></em>
                    </button>
                  </template>
                  Copiar link compartilhável
                </b-tooltip>
                <b-dropdown x="right">
                  <template v-slot:trigger="{ on }">
                    <button class="btn xxs icon text" v-on="on">
                      <em class="fal fa-ellipsis-vertical"></em>
                    </button>
                  </template>
                  <template #content>
                    <div class="list">
                      <button class="list-item text-color-danger" @click="removeLink(link.id)">
                        <em class="fal fa-trash text-color-danger"></em>
                        <span>Excluir</span>
                      </button>
                    </div>
                  </template>
                </b-dropdown>
              </div>
            </div>
            <div v-if="!folder.files.length" class="f-paragraph-xs mt-1 text-italic">Nenhum arquivo encontrado</div>
          </div>
        </div>
      </div>
    </div>

    <b-dialog :dismissable="false" v-model="dialog.folder">
      <template #header>
        <div class="btn icon btn-coral no-hover">
          <em class="fal fa-folder-plus"></em>
        </div>
        <div class="flex-grow-1 h4 ml-2 mr-2 text-color-default">Nova pasta</div>
        <button class="btn text icon" @click="closeDialog('folder')">
          <em class="fal fa-times"></em>
        </button>
      </template>
      <template #body>
        <div class="pa-2 bg-default">
          <b-textfield v-model="forms.folder.name" label="Nome"></b-textfield>
        </div>
      </template>
      <template #footer>
        <div class="d-flex gap-16 justify-end w-100">
          <button class="btn outlined sm" @click="closeDialog('folder')">Cancelar</button>
          <button class="btn btn-coral sm" @click="saveFolderForm">Salvar</button>
        </div>
      </template>
    </b-dialog>

    <b-dialog :dismissable="false" v-model="dialog.file">
      <template #header>
        <div class="btn icon btn-coral no-hover">
          <em class="fal fa-paperclip"></em>
        </div>
        <div class="flex-grow-1 h4 ml-2 mr-2 text-color-default">Anexar arquivo</div>
        <button class="btn text icon" @click="closeDialog('file')">
          <em class="fal fa-times"></em>
        </button>
      </template>
      <template #body>
        <div class="pa-2 bg-default">
          <b-textfield v-model="forms.file.title" label="Título" sublabel="Opcional"></b-textfield>
          <b-input-file v-model="forms.file.file" label="Arquivo" id="attach-file"></b-input-file>
          <b-autocomplete v-model="forms.file.folderId" label="Pasta" :options="folders" option-text="name"
            option-value="folderSharePointId"></b-autocomplete>
        </div>
      </template>
      <template #footer>
        <div class="d-flex gap-16 justify-end w-100">
          <button class="btn outlined sm" @click="closeDialog('file')">Cancelar</button>
          <button class="btn btn-coral sm" @click="addFile">Anexar</button>
        </div>
      </template>
    </b-dialog>

    <b-dialog :dismissable="false" v-model="dialog.link">
      <template #header>
        <div class="btn icon btn-coral no-hover">
          <em class="fal fa-link"></em>
        </div>
        <div class="flex-grow-1 h4 ml-2 mr-2 text-color-default">Novo link</div>
        <button class="btn text icon" @click="closeDialog('link')">
          <em class="fal fa-times"></em>
        </button>
      </template>
      <template #body>
        <div class="pa-2 bg-default">
          <b-textfield v-model="forms.link.title" label="Título" sublabel="Opcional"></b-textfield>
          <b-textfield v-model="forms.link.url" label="Link" icon="fal fa-link"></b-textfield>
          <b-autocomplete v-model="forms.link.folderId" label="Pasta" :options="folders" option-text="name"
            option-value="id"></b-autocomplete>
        </div>
      </template>
      <template #footer>
        <div class="d-flex gap-16 justify-end w-100">
          <button class="btn outlined sm" @click="closeDialog('link')">Cancelar</button>
          <button class="btn btn-coral sm" @click="saveLinkForm">Salvar</button>
        </div>
      </template>
    </b-dialog>

    <b-dialog :dismissable="false" v-model="dialog.newVersionFile">
      <template #header>
        <div class="btn icon btn-coral no-hover">
          <em class="fal fa-link"></em>
        </div>
        <div class="flex-grow-1 h4 ml-2 mr-2 text-color-default">Nova Versão</div>
        <button class="btn text icon" @click="closeDialog('newVersionFile')">
          <em class="fal fa-times"></em>
        </button>
      </template>
      <template #body>
        <div class="pa-2 bg-default">
          <b-textfield v-model="forms.newVersionFile.title" label="Título" sublabel="Opcional"></b-textfield>
          <b-input-file v-model="forms.newVersionFile.file" label="Arquivo" id="attach-newfile"></b-input-file>
          <b-autocomplete v-model="forms.newVersionFile.folderId" label="Pasta" :options="folders" option-text="name"
            option-value="folderSharePointId"></b-autocomplete>
        </div>
      </template>
      <template #footer>
        <div class="d-flex gap-16 justify-end w-100">
          <button class="btn outlined sm" @click="closeDialog('newVersionFile')">Cancelar</button>
          <button class="btn btn-coral sm" @click="newVersionFile">Salvar</button>
        </div>
      </template>
    </b-dialog>

    <b-dialog :dismissable="false" v-model="dialog.replaceFile">
      <template #header>
        <div class="btn icon btn-coral no-hover">
          <em class="fal fa-link"></em>
        </div>
        <div class="flex-grow-1 h4 ml-2 mr-2 text-color-default">Substituir Arquivo</div>
        <button class="btn text icon" @click="closeDialog('replaceFile')">
          <em class="fal fa-times"></em>
        </button>
      </template>
      <template #body>
        <div class="pa-2 bg-default">
          <b-textfield v-model="forms.replaceFile.title" label="Título" sublabel="Opcional"></b-textfield>
          <b-input-file v-model="forms.replaceFile.file" label="Arquivo" id="attach-replaceFile"></b-input-file>
          <b-autocomplete v-model="forms.replaceFile.folderId" label="Pasta" :options="folders" option-text="name"
            option-value="folderSharePointId"></b-autocomplete>
        </div>
      </template>
      <template #footer>
        <div class="d-flex gap-16 justify-end w-100">
          <button class="btn outlined sm" @click="closeDialog('replaceFile')">Cancelar</button>
          <button class="btn btn-coral sm" @click="replaceFilePipeline">Salvar</button>
        </div>
      </template>
    </b-dialog>

    <b-dialog :dismissable="false" v-model="dialog.renameFile">
      <template #header>
        <div class="btn icon btn-coral no-hover">
          <em class="fal fa-folder-plus"></em>
        </div>
        <div class="flex-grow-1 h4 ml-2 mr-2 text-color-default">Renomear Arquivo</div>
        <button class="btn text icon" @click="closeDialog('renameFile')">
          <em class="fal fa-times"></em>
        </button>
      </template>
      <template #body>
        <div class="pa-2 bg-default">
          <b-textfield v-model="forms.renameFile.title" label="Nome"></b-textfield>
        </div>
      </template>
      <template #footer>
        <div class="d-flex gap-16 justify-end w-100">
          <button class="btn outlined sm" @click="closeDialog('renameFile')">Cancelar</button>
          <button class="btn btn-coral sm" @click="saveRenameFile">Salvar</button>
        </div>
      </template>
    </b-dialog>

    <b-dialog :dismissable="false" v-model="dialog.renameFolder">
      <template #header>
        <div class="btn icon btn-coral no-hover">
          <em class="fal fa-folder-plus"></em>
        </div>
        <div class="flex-grow-1 h4 ml-2 mr-2 text-color-default">Renomear Pasta</div>
        <button class="btn text icon" @click="closeDialog('renameFolder')">
          <em class="fal fa-times"></em>
        </button>
      </template>
      <template #body>
        <div class="pa-2 bg-default">
          <b-textfield v-model="forms.renameFolder.title" label="Nome"></b-textfield>
        </div>
      </template>
      <template #footer>
        <div class="d-flex gap-16 justify-end w-100">
          <button class="btn outlined sm" @click="closeDialog('renameFolder')">Cancelar</button>
          <button class="btn btn-coral sm" @click="saveRenameFolder">Salvar</button>
        </div>
      </template>
    </b-dialog>

    <b-dialog :dismissable="false" v-model="dialog.moveFile">
      <template #header>
        <div class="btn icon btn-coral no-hover">
          <em class="fal fa-folder-plus"></em>
        </div>
        <div class="flex-grow-1 h4 ml-2 mr-2 text-color-default">Mover Arquivo</div>
        <button class="btn text icon" @click="closeDialog('moveFile')">
          <em class="fal fa-times"></em>
        </button>
      </template>
      <template #body>
        <div class="pa-2 bg-default">
          <b-autocomplete v-model="forms.moveFile.folderId" label="Pasta" :options="folders" option-text="name"
            option-value="folderSharePointId"></b-autocomplete>
        </div>
      </template>
      <template #footer>
        <div class="d-flex gap-16 justify-end w-100">
          <button class="btn outlined sm" @click="closeDialog('moveFile')">Cancelar</button>
          <button class="btn btn-coral sm" @click="moveFileFolder">Salvar</button>
        </div>
      </template>
    </b-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'AnexosComponent',
  props: ['details'],
  data () {
    return {
      items: [],
      file: null,
      dialog: {
        file: false,
        link: false,
        folder: false,
        newVersionFile: false,
        replaceFile: false
      },
      forms: {
        folder: {
          name: ''
        },
        file: {
          title: '',
          file: null,
          folderId: ''
        },
        link: {
          title: '',
          url: '',
          folderId: ''
        },
        replaceFile: {
          title: '',
          file: null,
          folderId: '',
          fileId: '',
          driveId: ''
        },
        newVersionFile: {
          title: '',
          file: null,
          folderId: '',
          fileId: '',
          driveId: ''
        },
        renameFolder: {
          title: '',
          folderId: ''
        },
        renameFile: {
          originalName: '',
          title: '',
          driveId: '',
          fileId: ''
        },
        moveFile: {
          fileId: '',
          folderId: '',
          driveId: '',
          nameFile: ''
        }
      }
    }
  },
  async mounted () {
    this.loadFolders(this.$props.details.id)
  },
  computed: {
    ...mapGetters({
      folders: 'attachments/folders',
      currentPipeline: 'attachments/currentPipeline'
    }),
    orderedFoldersWithFiles () {
      const folders = Object.assign([], this.folders)

      const records = folders.map(item => {
        const filesCopy = [...item.files]

        filesCopy.sort((a, b) => new Date(a.createdDateTime) - new Date(b.createdDateTime))

        return {
          ...item,
          files: filesCopy
        }
      })

      return records
    }
  },
  methods: {
    ...mapActions({
      loadFolders: 'attachments/loadFolders',
      showSnackbar: 'snackbar/showSnackbar',
      saveFile: 'attachments/saveFile',
      saveLink: 'attachments/saveLink',
      deleteLink: 'attachments/deleteLink',
      saveFolder: 'attachments/saveFolder',
      deleteFile: 'attachments/deleteFile',
      saveNewVersionFile: 'attachments/saveNewVersionFile',
      replaceFile: 'attachments/replaceFile',
      renameFile: 'attachments/renameFile',
      renameFolder: 'attachments/renameFolder',
      moveFile: 'attachments/moveFile',
      shared: 'attachments/shared',
      deleteFolder: 'attachments/deleteFolder'
    }),
    clearForms () {
      this.forms.file.title = ''
      this.forms.file.file = null
      this.forms.file.folderId = ''

      this.forms.newVersionFile.title = ''
      this.forms.newVersionFile.file = null
      this.forms.newVersionFile.folderId = ''
      this.forms.newVersionFile.fileId = ''
      this.forms.newVersionFile.driveId = ''

      this.forms.replaceFile.title = ''
      this.forms.replaceFile.file = null
      this.forms.replaceFile.folderId = ''
      this.forms.replaceFile.fileId = ''
      this.forms.replaceFile.driveId = ''

      this.forms.link.title = ''
      this.forms.link.url = ''
      this.forms.link.folderId = ''
      this.forms.folder.name = ''

      this.forms.renameFile.title = ''
      this.forms.renameFile.driveId = ''
      this.forms.renameFile.fileId = ''
      this.forms.renameFile.originalName = ''

      this.forms.renameFolder.title = ''
      this.forms.renameFolder.folderId = ''

      this.forms.moveFile.fileId = ''
      this.forms.moveFile.driveId = ''
      this.forms.moveFile.folderId = ''
      this.forms.moveFile.nameFile = ''
    },
    closeDialog (dialogName) {
      this.dialog[dialogName] = false
      this.clearForms()
    },
    openLinkDialog (folderId = '') {
      this.forms.link.folderId = folderId
      this.dialog.link = true
    },
    openFileDialog (folderId = '') {
      this.forms.file.folderId = folderId
      this.dialog.file = true
    },
    openNewVersionFileDialog (folderId = '', fileId, driveId) {
      this.forms.newVersionFile.folderId = folderId
      this.dialog.newVersionFile = true
      this.forms.newVersionFile.fileId = fileId
      this.forms.newVersionFile.driveId = driveId
    },
    openReplaceFile (folderId = '', fileId, driveId) {
      this.forms.replaceFile.folderId = folderId
      this.forms.replaceFile.fileId = fileId
      this.forms.replaceFile.driveId = driveId
      this.dialog.replaceFile = true
    },
    openRenameFileDialog (driveId, fileId, originalName) {
      this.forms.renameFile.driveId = fileId
      this.forms.renameFile.fileId = driveId
      this.forms.renameFile.originalName = originalName
      this.dialog.renameFile = true
    },
    openRenameFolderDialog (folderId) {
      this.forms.renameFolder.folderId = folderId
      this.dialog.renameFolder = true
    },
    openMoveFileDialog (fileName, fileId, driveId) {
      this.forms.moveFile.fileId = fileId
      this.forms.moveFile.driveId = driveId
      this.forms.moveFile.nameFile = fileName
      this.dialog.moveFile = true
    },
    async addFile () {
      if (!this.forms.file.file && !this.forms.file.folderId) return

      if (this.validateNameFile(this.forms.file.file.name, this.forms.file.folderId, this.forms.file.title)) {
        return this.showSnackbar({
          message: 'Já existe um arquivo com esse nome na pasta selecionada',
          secondsToClose: 10,
          type: 'error'
        })
      }

      const formData = new FormData()

      formData.append('title', this.forms.file.title)
      formData.append('file', this.forms.file.file)
      formData.append('folderIdSharePoint', this.forms.file.folderId)
      this.closeDialog('file')
      await this.saveFile(formData)
      await this.loadFolders(this.$props.details.id)
    },
    async saveFolderForm () {
      await this.saveFolder({
        name: this.forms.folder.name,
        pipelineId: this.$props.details.id
      })
      this.closeDialog('folder')
    },
    async removeFile (fileId, driveIdSharePoint) {
      await this.deleteFile({ driveIdSharePoint: driveIdSharePoint, fileId: fileId })
      await this.loadFolders(this.$props.details.id)
    },
    async removeFolder (folderId) {
      await this.deleteFolder({ folderId: folderId })
      await this.loadFolders(this.$props.details.id)
    },
    saveFileForm () {
      this.saveFile({
        title: this.forms.file.title,
        file: this.forms.file.file,
        folderId: this.forms.file.folderId,
        pipelineId: this.$props.details.id
      })
      this.closeDialog('file')
    },
    async saveLinkForm () {
      await this.saveLink({
        title: this.forms.link.title,
        url: this.forms.link.url,
        folderId: this.forms.link.folderId,
        pipelineId: this.$props.details.id
      })
      this.closeDialog('link')
      await this.loadFolders(this.$props.details.id)
    },
    async removeLink (linkId) {
      await this.deleteLink({ linkId: linkId })
      await this.loadFolders(this.$props.details.id)
    },
    async newVersionFile () {
      if (!this.forms.newVersionFile.file || !this.forms.newVersionFile.folderId) return

      if (this.validateNameFile(this.forms.newVersionFile.file.name, this.forms.newVersionFile.folderId, this.forms.newVersionFile.title)) {
        return this.showSnackbar({
          message: 'Já existe um arquivo com esse nome na pasta selecionada',
          secondsToClose: 10,
          type: 'error'
        }, { root: true })
      }

      const formData = new FormData()

      formData.append('title', this.forms.newVersionFile.title)
      formData.append('file', this.forms.newVersionFile.file)
      formData.append('folderIdSharePoint', this.forms.newVersionFile.folderId)
      formData.append('fileId', this.forms.newVersionFile.fileId)
      formData.append('driveId', this.forms.newVersionFile.driveId)

      this.closeDialog('newVersionFile')
      await this.saveNewVersionFile(formData)
      await this.loadFolders(this.$props.details.id)
    },
    async replaceFilePipeline () {
      if (!this.forms.replaceFile.file && !this.forms.replaceFile.folderId) return

      if (this.validateNameFile(this.forms.replaceFile.file.name, this.forms.replaceFile.folderId, this.forms.replaceFile.title)) {
        return this.showSnackbar({
          message: 'Já existe um arquivo com esse nome na pasta selecionada',
          secondsToClose: 10,
          type: 'error'
        }, { root: true })
      }

      const formData = new FormData()

      formData.append('title', this.forms.replaceFile.title)
      formData.append('file', this.forms.replaceFile.file)
      formData.append('fileId', this.forms.replaceFile.fileId)
      formData.append('driveId', this.forms.replaceFile.driveId)
      formData.append('folderSharePointId', this.forms.replaceFile.folderId)

      this.closeDialog('replaceFile')
      await this.replaceFile(formData)
      await this.loadFolders(this.$props.details.id)
    },
    async saveRenameFile () {
      if (this.forms.renameFile.title) {
        await this.renameFile({
          driveId: this.forms.renameFile.driveId,
          fileId: this.forms.renameFile.fileId,
          title: this.forms.renameFile.title,
          originalName: this.forms.renameFile.originalName
        })
        this.closeDialog('renameFile')
        await this.loadFolders(this.$props.details.id)
      }
    },
    async saveRenameFolder () {
      if (this.forms.renameFolder.title) {
        await this.renameFolder({
          folderId: this.forms.renameFolder.folderId,
          title: this.forms.renameFolder.title
        })
        this.closeDialog('renameFolder')
        await this.loadFolders(this.$props.details.id)
      }
    },
    async sharedFile (fileId, driveId, share) {
      await this.shared({ fileId: fileId, driveId: driveId, shared: share })
      await this.loadFolders(this.$props.details.id)
    },
    async moveFileFolder () {
      await this.moveFile({
        fileId: this.forms.moveFile.fileId,
        driveId: this.forms.moveFile.driveId,
        folderId: this.forms.moveFile.folderId,
        nameFile: this.forms.moveFile.nameFile
      })
      this.closeDialog('moveFile')
      await this.loadFolders(this.$props.details.id)
    },
    getFileName (content) {
      if (!content) return ''

      const [fileName] = content.trim().replace(/\/*$/g, '').split('/').slice(-1)

      return fileName
    },
    startDrag (event, item) {
      event.dataTransfer.dropEffect = 'move'
      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.setData('itemId', item.id)
    },
    onDrop (event, list) {
      const itemId = parseInt(event.dataTransfer.getData('itemId'))
      const item = this.items.find((e) => e.id === itemId)
      item.list = list
    },
    copyLinkToClipboard (text) {
      navigator.clipboard.writeText(text)
        .then(() =>
          this.showSnackbar({
            message: 'Link copiado para a área de transferência',
            secondsToClose: 5,
            type: 'success'
          })
        )
        .catch(() =>
          this.showSnackbar({
            message: 'Falha ao copiar link para a área de transferêncai',
            secondsToClose: 5,
            type: 'error'
          }))
    },
    mapIcon (content, type) {
      if (type.trim().toUpperCase() === 'L') return 'fa-link'

      let icon = ''

      const [extension] = content.split('.').slice(-1)

      switch (extension.trim().toLowerCase()) {
        case 'doc':
        case 'docx':
          icon = 'fa-file-word'
          break
        case 'xls':
        case 'xlsx':
          icon = 'fa-file-excel'
          break
        case 'ppt':
        case 'pptx':
          icon = 'fa-file-powerpoint'
          break
        case 'pdf':
          icon = 'fa-file-pdf'
          break
        case 'mp3':
        case 'wav':
        case 'ogg':
          icon = 'fa-file-audio'
          break
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'gif':
          icon = 'fa-file-image'
          break
        case 'csv':
          icon = 'fa-file-csv'
          break
        case 'rar':
        case 'zip':
        case '7zip':
          icon = 'fa-file-archive'
          break
        default:
          icon = 'fa-file'
      }

      return icon
    },
    validateNameFile (nameFile, folderSharePointId, title = null) {
      if (title) {
        const lastDotIndex = nameFile.lastIndexOf('.')

        if (lastDotIndex > 0 && lastDotIndex < nameFile.length - 1) {
          const extension = nameFile.substring(lastDotIndex + 1)
          nameFile = `${title}.${extension}`
        }
      }

      const foldersItems = this.folders
      const folder = foldersItems.find(folder => folder.folderSharePointId === folderSharePointId)
      const file = folder.files.find(file => file.name === nameFile)

      if (file) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>
