export default {
  namespaced: true,
  state: {
    users: [],
    userResetPassword: {},
    tenants: [],
    primaryFunctions: [
      {
        id: 1,
        name: 'Embarque'
      },
      {
        id: 2,
        name: 'Seleção'
      },
      {
        id: 3,
        name: 'Pesquisa'
      }
    ]
  },
  getters: {
    users: (state) => state.users,
    UserResetPassword: (state) => state.userResetPassword,
    tenants: (state) => state.tenants,
    primaryFunctions: (state) => state.primaryFunctions
  },
  mutations: {
    SET_USERS (state, users) {
      state.users = users
    },
    SET_TENANT (state, tenants) {
      state.tenants = tenants
    },
    SET_LOGGED_USER_RESET_PASSWORD (state, loggedUser) {
      state.userResetPassword = loggedUser
    }
  },
  actions: {
    loadUsers ({ commit, dispatch }) {
      const { $axios } = this.state

      $axios
        .get('/user')
        .then((response) => {
          commit('SET_USERS', response.data)
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao carregar usuários',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    loadTenants ({ commit, dispatch }) {
      const { $axios } = this.state

      $axios
        .get('/tenant')
        .then((response) => {
          commit('SET_TENANT', response.data)
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao carregar usuários',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    getUserDataByTokenPassword ({ commit }, token) {
      const { $axios } = this.state

      return $axios
        .post(`/email/recoveryUserById?token=${token}`)
        .then(({ data }) => commit('SET_LOGGED_USER_RESET_PASSWORD', data))
        .catch(console.error)
    },
    resetPassword ({ dispatch }, { id, password }) {
      const { $axios } = this.state

      return $axios
        .post('/user/updatePassword', {
          id,
          password
        })
        .then(({ data }) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Salvo com exito.',
              secondsToClose: 5,
              type: 'success'
            },
            { root: true }
          )
          return data
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)
          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao cadastrar senha.',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )
          return Promise.reject(error)
        })
    },
    updateUser ({ commit, dispatch }, { id, data }) {
      const { $axios } = this.state
      $axios
        .put(`/user/${id}`, data)
        .then(() => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Sucesso ao atualizar usuários',
              secondsToClose: 5,
              type: 'Success'
            },
            { root: true }
          )
          commit('loadUsers')
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao atualizar usuários',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    deleteUser ({ commit, dispatch }, { id }) {
      const { $axios } = this.state
      $axios
        .delete(`/user/${id}`)
        .then(() => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Sucesso ao atualizar usuários',
              secondsToClose: 5,
              type: 'Success'
            },
            { root: true }
          )
          commit(
            'SET_USERS',
            this.state.users.users.filter((user) => user.id !== id)
          )
          return true
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao atualizar usuários',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    blockUser ({ commit, dispatch }, { id }) {
      const { $axios } = this.state
      $axios
        .post(`/user/blockUser/${id}`)
        .then(() => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Sucesso ao atualizar usuários',
              secondsToClose: 5,
              type: 'Success'
            },
            { root: true }
          )
          commit(
            'SET_USERS',
            this.state.users.users.map((user) => {
              if (user.id === id) {
                user.statusId = 2
              }
              return user
            })
          )
          return true
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao atualizar usuários',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    async consultStatusUser ({ dispatch }, { id }) {
      const { $axios } = this.state
      await $axios
        .get(`/user/consultStatusUser/${id}`)
        .then((response) => {
          return response.data
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao atualizar usuários',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    createUser ({ dispatch }, { data }) {
      const { $axios } = this.state
      $axios
        .post('/user', data)
        .then((response) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Sucesso ao criar usuários',
              secondsToClose: 5,
              type: 'Success'
            },
            { root: true }
          )
          return response
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao criar usuários',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    unlockUser ({ commit, dispatch }, id) {
      const { $axios } = this.state
      $axios
        .post(`/user/unlockUser/${id}`)
        .then(() => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Sucesso ao atualizar usuários',
              secondsToClose: 5,
              type: 'Success'
            },
            { root: true }
          )
          commit(
            'SET_USERS',
            this.state.users.users.map((user) => {
              if (user.id === id) {
                user.statusId = 1
              }
              return user
            })
          )
          return true
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao atualizar usuários',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    requestResetPassword ({ dispatch }, request) {
      const { $axios } = this.state

      return $axios
        .post('/email/recoveryPassword', request)
        .then(({ data }) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Enviado com exito.',
              secondsToClose: 5,
              type: 'success'
            },
            { root: true }
          )
          return data
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)
          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao enviar e-mail.',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )
          return Promise.reject(error)
        })
    }
  }
}
