<template>
  <div>
    <input v-show="false" :id="id" type="file" @change="handleFile" />
    <label :for="id" class="cursor-pointer">
      <slot :url="url" />
    </label>
  </div>
</template>
<script>
export default {
  props: {
    id: String,
    modelValue: File
  },
  data() {
    return {
      file: null
    }
  },
  computed: {
    url() {
      if (!(this.file instanceof File)) return ''
      return URL.createObjectURL(this.file)
    }
  },
  watch: {
    modelValue(value) {
      this.file = value
    }
  },
  methods: {
    handleFile(event) {
      this.file = event.target.files[0] || null
      this.$emit('update:modelValue', this.file)
    }
  }
}
</script>
