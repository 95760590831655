import { createRouter, createWebHistory } from 'vue-router'
import Home from '../pages/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../pages/Login.vue')
  },
  {
    path: '/recover-password',
    name: 'RecoverPassword',
    component: () => import('../pages/RecoverPassword.vue')
  },
  {
    path: '/password',
    name: 'Password',
    component: () => import('../pages/Password.vue')
  },
  {
    path: '/crm',
    name: 'ContatoCrm',
    component: () => import('../pages/contato/crm.vue')
  },
  {
    path: '/contato',
    name: 'Contato',
    component: () => import('../pages/contato/contato.vue')
  },
  {
    path: '/equipe',
    name: 'Equipe',
    component: () => import('../pages/contato/equipe.vue')
  },
  {
    path: '/empresa-crm',
    name: 'EmpresaCrm',
    component: () => import('../pages/empresa/crm.vue')
  },
  {
    path: '/ms/authorize',
    name: 'Autenticação Microsoft',
    component: () => import('../pages/MicrosoftAuthorize.vue')
  },
  {
    path: '/api/password',
    name: 'Alterar Senha',
    component: () => import('../pages/Password.vue'),
    props: (route) => ({ token: route.query.token })
  }
]

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  routes
})

export default router
