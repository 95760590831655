<template>
  <button v-if="hasListener" class="avatar" :data-initials="_initials" :class="size" :style="styles">
    <slot></slot>
  </button>
  <div v-else class="avatar" :data-initials="_initials" :class="size" :style="styles">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    size: String,
    initials: String,
    name: String,
    color: String,
    randomBg: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    _initials () {
      if (this.initials) return this.initials
      if (this.name) {
        let initials = this.name.split(' ')

        if (initials.length > 1) {
          initials = initials.shift().charAt(0) + initials.pop().charAt(0)
        } else {
          initials = this.name.substring(0, 2)
        }
        return initials.toUpperCase()
      }
      return ''
    },
    styles () {
      const backgroundColor = this.color || (this.randomBg ? this.randomColor() : '')

      if (!backgroundColor) return

      return { backgroundColor }
    },
    hasListener () {
      return this.$attrs.onClick !== undefined
    }
  },
  methods: {
    randomColor: () => {
      return '#' + Math.floor(Math.random() * 16777215)
        .toString(16)
        .padStart(6, '0')
        .toUpperCase()
    }
  }
}
</script>
