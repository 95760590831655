<template>
  <div class="form-group" :class="{ 'has-error': errorText || hint }" ref="formgroup">
    <div class="d-flex justify-between">
      <label :for="id" v-if="label" v-text="label"></label>
      <span v-if="sublabel" v-text="sublabel" class="f-sm text-color-light"></span>
    </div>
    <div class="input-group">
      <div class="input-field" :class="{ 'has-icon': icon }">
        <em v-if="appendIcon" :class="appendIcon"></em>
        <textarea v-if="multiline" v-model="model" :type="type" :id="id" :placeholder="placeholder" :readonly="readonly"
          :disabled="disabled" class="form-control" ref="input" :name="name"></textarea>
        <input v-else v-model="model" v-maska="mask" :type="inputType" :id="id" :placeholder="placeholder"
          :inputmode="inputmode" :readonly="readonly" :disabled="disabled" :name="name" class="form-control" ref="input" />
        <a v-if="icon" class="icon" @click="$emit('iconClick')">
          <em :class="icon"></em>
        </a>
      </div>
      <slot name="append-outer" />
    </div>
    <span class="hint">{{ errorText || hint }}</span>
  </div>
</template>

<script>
import { maska } from 'maska'
import mixins from './mixins/validation'
export default {
  name: 'TextField',
  mixins: [mixins],
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    modelValue: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    appendIcon: {
      type: String,
      default: ''
    },
    iconClick: {
      type: Function,
      default: () => { }
    },
    label: {
      type: String,
      default: ''
    },
    sublabel: {
      type: String,
      default: ''
    },
    hint: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multiline: {
      default: null
    },
    rules: {
      type: Array,
      default: () => []
    }
  },
  directives: { maska },
  computed: {
    model: {
      get () {
        if (this.type === 'money') {
          return this.modelValue.toLocaleString('pt-BR')
        } else {
          return this.modelValue
        }
      },
      set (value) {
        if (this.type === 'money' && value) {
          const valorSemFormatacao = value.replace(/\./g, '').replace(',', '.')
          const numero = Number(valorSemFormatacao)
          const valorFormatado = numero.toLocaleString('pt-BR', {
            minimumFractionDigits: valorSemFormatacao % 1 !== 0 ? 3 : 0,
            maximumFractionDigits: 2
          })
          this.$emit('update:modelValue', valorFormatado)
        } else {
          this.$emit('update:modelValue', value)
        }
      }
    },
    inputType () {
      const types = ['cpf', 'cnpj', 'phone', 'url']
      if (types.includes(this.type)) {
        return 'text'
      }
      return this.type
    },
    inputmode () {
      const types = ['cpf', 'cnpj', 'phone', 'number']
      if (types.includes(this.type)) {
        return 'decimal'
      }
      return ''
    },
    mask () {
      let result = ''

      switch (this.type) {
        case 'number':
          result = '#*'
          break
        case 'cpf':
          result = '###.###.###-##'
          break
        case 'cnpj':
          result = '##.###.###/####-##'
          break
        case 'phone':
          result = '(##) #####-####'
          break
        default:
          result = ''
      }

      return result
    }
  },
  data () {
    return {
      error: [],
      uud: Math.random().toString(16).slice(2)
    }
  },
  watch: {
    model () {
      this.validate()
    }
  }
}
</script>
