export default {
  namespaced: true,
  state: {
    options: [],
    businessData: []
  },
  getters: {
    options: state => state.options,
    businessData: state => state.businessData
  },
  mutations: {
    SET_OPTIONS (state, options) {
      state.options = options
    },
    SET_BUSINESS_DATA (state, data) {
      state.businessData = data
    }
  },
  actions: {
    loadOptions ({ commit, dispatch }) {
      const { $axios } = this.state

      return $axios.get('pipeline/business/options').then((response) => {
        commit('SET_OPTIONS', response.data)
      }).catch(error => {
        if (!error.parsedErrorText) console.error(error)

        dispatch('snackbar/showSnackbar', {
          message: error.parsedErrorText || 'Falha ao carregar opções de negócio',
          secondsToClose: 5,
          type: 'warn'
        }, { root: true })

        return Promise.reject(error)
      })
    },
    getOnlyBusiness ({ commit, dispatch }) {
      const { $axios } = this.state

      return $axios.get('/pipeline/step/3').then((response) => {
        commit('SET_BUSINESS_DATA', response.data)
      }).catch(error => {
        if (!error.parsedErrorText) console.error(error)

        dispatch('snackbar/showSnackbar', {
          message: error.parsedErrorText || 'Falha ao carregar negócios',
          secondsToClose: 5,
          type: 'warn'
        }, { root: true })

        return Promise.reject(error)
      })
    },
    createBusiness ({ dispatch }, { id, note, code, companyId, priorityId, originId, prospectStatusId, leadStatusId = 1, businessStatusId, contractValue, percentage }) {
      const body = {
        note,
        companyId,
        priorityId,
        originId,
        prospectStatusId,
        leadStatusId,
        code,
        percentage: percentage,
        businessStatusId,
        contractValue: contractValue || '0'
      }

      const { $axios } = this.state

      return $axios.put(`/pipeline/business/${id}`, body).then(({ data }) => data).catch((error) => {
        if (!error.parsedErrorText) console.error(error)

        dispatch('snackbar/showSnackbar', {
          message: error.parsedErrorText || 'Falha na inserção',
          secondsToClose: 5,
          type: 'error'
        }, { root: true })

        return Promise.reject(error)
      })
    }
  }
}
