<template>
  <div class="dialog" :class="{ active: dialog }">
    <div class="dialog-content md">
      <div class="dialog-content-header">
        <div class="btn icon btn-coral no-hover">
          <em class="fal fa-compass"></em>
        </div>
        <div class="flex-grow-1 h4 ml-2 mr-2">Sources</div>
        <button class="btn text icon" @click="close()">
          <em class="fal fa-times"></em>
        </button>
      </div>
      <span class="divider"></span>
      <div class="dialog-content-body">
        <div class="pa-1">
          <b-textfield
            placeholder="Buscar"
            name="search"
            type="search"
            appendIcon="fal fa-search"
            class="sm mb-0"
          />
        </div>
        <span class="divider"></span>
        <div class="list list-with-divider bg-contrast">
          <div v-for="i in 10" :key="i + 's'" class="list-item">
            <b-checkbox
              v-model="sources"
              :inputValue="i"
              :id="'source' + i"
              label="Source"
            />
          </div>
        </div>
      </div>
      <span class="divider"></span>
      <div class="dialog-content-footer">
        <span class="paragraph text-color-light">{{ sources.length }}/10</span>
        <span class="flex-grow-1" />
        <button class="btn outlined sm" @click="clear()">Limpar</button>
        <button class="btn btn-coral sm">Aplicar</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'sourcesModal',
  data () {
    return {
      dialog: false,
      sources: []
    }
  },
  methods: {
    open () {
      this.dialog = true
    },
    close () {
      this.dialog = false
    },
    update (event) {
      const value = event.target.value
      if (this.sources.includes(value)) {
        this.sources.splice(this.sources.indexOf(value), 1)
      } else {
        this.sources.push(value)
      }
    },
    clear () {
      this.sources = []
    }
  }
}
</script>
