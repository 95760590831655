<template>
  <div class="d-flex pt-2 pb-2 gap-8 cursor-pointer" :class="isSelected ?'bg-info' : 'bg-contrast'" @click="open()">
    <div
      class="read-status"
      :class="notification.read ? 'bg-contrast' : 'bg-info'"
    ></div>
    <div
      class="icon"
      :class="notification.read ? 'text-color-transparent' : 'text-color-info'"
    >
      <em class="fas fa-bell mt-xs"></em>
    </div>
    <div class="flex-grow-1 pr-2">
      <div class="d-flex justify-between gap-8">
        <div class="flex-grow-1">
          <div class="paragraph sm font-weight-700" :class="textColor">
            {{ notification.title }}
          </div>
          <div class="paragraph sm" :class="textColor">
            {{ notification.area }}
          </div>
        </div>
        <div class="paragraph sm flex-shrink-0" :class="isSelected ? 'text-color-white' : 'text-color-light'">
          {{ date }}
        </div>
        <div v-if="notification.urgent" class="icon mt-xs text-color-danger">
          <em class="fas fa-exclamation-circle"></em>
        </div>
      </div>
      <div
        class="mt-1 truncate truncate-after lines-2 paragraph xs"
        :class="textColor"
        v-html="notification.message"
      ></div>
      <div v-if="hasButton" class="mt-1">
        <button class="btn xs" :class="isSelected ? 'bg-accent' : 'btn-info'">{{ buttonText }}</button>
      </div>
    </div>
  </div>
  <span class="divider"></span>
</template>

<script>
import moment from 'moment'
export default {
  name: 'NotificationItem',
  props: ['read', 'notification', 'selected'],
  computed: {
    date () {
      moment.locale('pt-br')
      return moment.utc(this.notification.date).fromNow()
    },
    hasButton () {
      return (
        this.notification.type === 'project' ||
        this.notification.type === 'count'
      )
    },
    buttonText () {
      return this.notification.type === 'project'
        ? 'Abrir projeto'
        : 'Abrir no Conta Azul'
    },
    textColor () {
      return this.isSelected ? 'text-color-white' : this.notification.read ? 'text-color-light' : ''
    },
    isSelected () {
      return this.selected.title === this.notification.title
    }
  },
  methods: {
    open () {
      this.$emit('openNotification', this.notification)
    }
  }
}
</script>
