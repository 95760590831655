<template>
  <div class="scrollable">
    <div class="pa-3">
      <div class="section-title lavanda">Dashboard</div>
    </div>
    <span class="divider"></span>
    <div class="pa-3 bg-highlight">
      <div class="row">
        <div class="col-xs-12 col-sm-6 col-md-3 d-flex">
          <div class="d-flex bg-contrast shadow pa-2 gap-8 flex-grow-1">
            <div class="btn icon text sm">
              <em class="fal fa-sack-dollar fs-24"></em>
            </div>
            <span class="divider v"></span>
            <div>
              <div class="h1">84k</div>
              <div class="paragraph xs text-color-light mt-xs">
                Ticket médio
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-3 d-flex">
          <div class="d-flex bg-contrast shadow pa-2 gap-8 flex-grow-1">
            <div class="btn icon text sm">
              <em class="fal fa-address-book fs-24"></em>
            </div>
            <span class="divider v"></span>
            <div>
              <div class="h1">1.2k</div>
              <div class="paragraph xs text-color-light mt-xs">Contatos</div>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-3 d-flex">
          <div class="d-flex bg-contrast shadow pa-2 gap-8 flex-grow-1">
            <div class="btn icon text sm">
              <em class="fal fa-timer fs-24"></em>
            </div>
            <span class="divider v"></span>
            <div>
              <div class="h1">12 dias</div>
              <div class="paragraph xs text-color-light mt-xs">
                Tempo médio de projeto
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-3 d-flex">
          <div class="d-flex bg-contrast shadow pa-2 gap-8 flex-grow-1">
            <div class="btn icon text sm">
              <em class="fal fa-file-invoice-dollar fs-24"></em>
            </div>
            <span class="divider v"></span>
            <div>
              <div class="h1">15</div>
              <div class="paragraph xs text-color-light mt-xs">
                Final Billings
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-xs-12 col-sm-6 col-md-8">
          <Chart
            type="bar"
            title="Pipeline / Origem"
            :labels="[
              'Prospect',
              'Recompra',
              'Ind. cliente',
              'Ind. parceiro',
              'Thyme',
              'Relac. Plongê',
              'Ind. Colaborador',
              'Reposição'
            ]"
            :values="[20, 30, 5, 6, 8, 35, 10, 20]"
            :colors="['#8C8BA7', '#52526B']"
          />
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4">
          <Chart
            title="Pipeline / Fase"
            type="doughnut"
            :total="120"
            :labels="['Prosp', 'Leads', 'Neg', 'Proj', 'Plac']"
            :values="[25, 15, 20, 30, 10]"
          />
        </div>
        <!-- <div class="col-xs-12 col-sm-6 col-md-4">
          <Chart
            type="doughnut"
            title="Pipeline / Atividade"
            :labels="['Tel', 'Reunião', 'E-mail', 'WhatsApp', 'Vídeo']"
            :values="[25, 15, 20, 30, 10]"
          />
        </div> -->
      </div>
      <div class="d-flex mt-2 bg-contrast shadow">
        <div class="btn icon btn-esmeralda no-hover">
          <em class="fal fa-folder-user"></em>
        </div>
        <div class="flex-grow-1 h4 pa-2">Projetos</div>
      </div>
      <div class="row mt-2">
        <div class="col-xs-12">
          <Chart
            title="Projetos / Mês"
            type="line"
            :total="32"
            :colors="['#439F8E', '#8C8BA7']"
          />
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-xs-12">
          <Chart
            title="Projetos / Semestre"
            type="bar-vertical"
            :total="152"
            :byMonth="false"
            :labels="['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun']"
            :values="[
              [12, 15, 13],
              [12, 15, 13],
              [12, 15, 13],
              [12, 15, 13],
              [12, 15, 13],
              [12, 15, 13]
            ]"
            suffix=""
            :colors="['#439F8E']"
          />
        </div>
      </div>
      <div class="d-flex mt-2 bg-contrast shadow">
        <div class="btn icon btn-coral no-hover">
          <em class="fal fa-user-tie"></em>
        </div>
        <div class="flex-grow-1 h4 pa-2">Leads</div>
      </div>
      <div class="row mt-2">
        <div class="col-xs-12">
          <Chart
            type="bar-vertical"
            :labels="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
            :values="[1, 3, 2, 1, 2]"
            :colors="['#D3AAA8', '#D3AAA8', '#D3AAA8', '#D3AAA8', '#13B279']"
            suffix=""
          />
        </div>
        <div class="col-xs-12 col-sm-6 col-md-8">
          <Chart
            title="Leads"
            type="bar"
            :total="79"
            :labels="[
              'A ser trabalhado',
              'Tentativa de contato',
              'Em andamento',
              'Reunião agendada',
              'Sem retorno',
              'Sem demanda',
              'Não trabalhar'
            ]"
            :values="[34, 32, 2, 8, 7, 15, 7]"
            :colors="['#D3AAA8']"
            suffix=""
          />
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4">
          <LineBg title="Atividades x dia" total="20,2" />
          <LineBg title="Reuniões x dia" total="2,3" class="mt-2" />
        </div>
      </div>
      <div class="d-flex mt-2 bg-contrast shadow">
        <div class="btn icon btn-turquesa no-hover">
          <em class="fal fa-badge-dollar"></em>
        </div>
        <div class="flex-grow-1 h4 pa-2">Negócios</div>
      </div>
      <div class="row mt-2">
        <div class="col-xs-12 col-sm-6 col-md-8">
          <Chart
            title="Propostas perdidas / Mês"
            type="line"
            :byMonth="false"
            :total="13"
            :colors="['#FF5530', '#52526B']"
          />
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4">
          <Chart
            title="Propostas"
            type="doughnut"
            :total="80"
            :labels="['Ganhas', 'Perdidas', 'Em andamento']"
            :values="[50, 15, 35]"
            :colors="['#13B279', '#EA2A00', 'rgba(31, 38, 72, 0.2)']"
          />
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-xs-12 col-sm-6 col-md-8">
          <Chart
            type="bar"
            title="Negócios / Setor"
            :labels="[
              'Setor 1',
              'Setor 2',
              'Setor 3',
              'Setor 4',
              'Setor 5',
              'Setor 6',
              'Setor 7'
            ]"
            :values="[20, 30, 5, 6, 8, 35, 10]"
            :colors="['#56B3BA99', '#56B3BA']"
          />
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4">
          <Chart
            title="Negócios / Persona"
            type="doughnut"
            :total="120"
            :labels="[
              'Gov. familiar',
              'Em transf.',
              'Impacto',
              'ESG',
              'Transacional'
            ]"
            :values="[25, 15, 20, 30, 10]"
            :colors="['#598FB4', '#365149', '#439F8E', '#D3AAA8', '#99B7B1']"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from '@/components/charts/Chart.vue'
import LineBg from '@/components/charts/LineBg.vue'
export default {
  name: 'ChartsComponent',
  components: {
    Chart,
    LineBg
  }
}
</script>

<style lang="scss" scoped>
.card {
  max-height: none;
}
</style>
