<template>
  <div class="pl-3 pr-3 pt-3">
    <apexchart
      width="100%"
      height="280"
      type="area"
      :options="options"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
export default {
  name: 'LineChart',
  props: {
    labels: {
      type: Array,
      default: () => []
    },
    values: {
      type: Array,
      default: () => []
    },
    colors: {
      type: Array,
      default: () => ['#439F8E']
    }
  },
  data() {
    return {
      options: {
        colors: this.colors,
        chart: {
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 2
        },
        grid: {
          borderColor: '#E1E1EB',
          clipMarkers: false,
          yaxis: {
            lines: {
              show: true
            }
          },
          xaxis: {
            lines: {
              show: true
            }
          }
        },
        yaxis: {
          labels: {
            show: false
          }
        },
        xaxis: {
          categories: this.labels
        },
        markers: {
          size: 6,
          colors: this.colors,
          strokeColor: '#F8F8FF',
          strokeWidth: 4
        }
      },
      series: [
        {
          data: [30, 35, 30, 35, 40, 35, 40, 45, 40, 35, 30, 35]
        },
        {
          data: [50, 45, 55, 40, 45, 50, 40, 45, 55, 30, 20, 30]
        }
      ]
    }
  }
}
</script>
