<template>
  <div class="tab-content scrollable">
    <template v-if="name !== 'Prospect' && details.new === false">
      <div class="pa-3">
        <div class="d-flex justify-between">
          <button class="btn btn-success outlined sm" title="Atualizar atividades" @click="runSync">
            <em class="fal fa-sync"></em>
          </button>
          <b-dropdown x="right">
            <template v-slot:trigger="{ on }">
              <button class="btn btn-coral outlined sm" v-on="on">
                <em class="fal fa-plus"></em>
                <span>Nova atividade</span>
              </button>
            </template>
            <template #content>
              <div class="list">
                <button v-for="activityType in activityTypes" :key="activityType.id" class="list-item"
                  @click="newActivityModal(activityType.id)">
                  <em class="fal" :class="activityTypeClass(activityType.id)"></em>
                  <span v-text="activityType.name"></span>
                </button>
              </div>
            </template>
          </b-dropdown>
        </div>
        <div class="list list-with-shadow mt-2">
          <Activity v-for="activity in sortedActivities" :key="activity.id" :activity="activity" />
        </div>
      </div>
    </template>
    <template v-else-if="name !== 'Prospect' && details.new">
      <div class="empty">
        <div class="empty-content">
          <div class="h1 text-color-coral">Cadastre Atividades</div>
          <div class="paragraph sm mt-1">Para cadastrar atividades salve essa posição</div>
        </div>
      </div>
    </template>
    <template v-else>
      <Empty image="lead" title="Cadastre atividades" :details="details"
        text="Para cadastrar atividades mude a fase deste <strong>Prospect</strong> para <strong>Lead</strong>."
        button="Lead" icon="users" color="jambo" @close="$emit('close')" @openDrawerToLead="$emit('openDrawerToLead')" />
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { openWindow } from '@/helpers'
import Empty from './Empty.vue'
import Activity from '../ui/Atividade.vue'

export default {
  name: 'AtividadesComponent',
  props: ['name', 'details'],
  components: { Activity, Empty },
  data () {
    return {
      dialog: false
    }
  },
  computed: {
    ...mapGetters({
      activityTypes: 'activities/types',
      activities: 'activities/records'
    }),
    sortedActivities () {
      const activities = Object.assign([], this.activities)

      const pending = activities.filter(activity => !activity.conclusionAt).sort((a, b) => {
        const startA = new Date(a.start)
        const startb = new Date(b.start)

        if (startA < startb) return -1
        if (startA > startb) return 1
        return 0
      })

      const concluded = activities.filter(activity => activity.conclusionAt).sort((a, b) => {
        const startA = new Date(a.start)
        const startb = new Date(b.start)

        if (startA < startb) return 1
        if (startA > startb) return -1
        return 0
      })

      return [...pending, ...concluded]
    },
    activityTypeId: {
      get () {
        return this.$store.state.activities.form.activityTypeId
      },
      set (value) {
        this.$store.commit('activities/SET_FORM_ACTIVITY_TYPE_ID', value)
      }
    },
    title: {
      get () {
        return this.$store.state.activities.form.title
      },
      set (value) {
        this.$store.commit('activities/SET_FORM_TITLE', value)
      }
    },
    description: {
      get () {
        return this.$store.state.activities.form.description
      },
      set (value) {
        this.$store.commit('activities/SET_FORM_DESCRIPTION', value)
      }
    },
    place: {
      get () {
        return this.$store.state.activities.form.place
      },
      set (value) {
        this.$store.commit('activities/SET_FORM_PLACE', value)
      }
    },
    start: {
      get () {
        return this.$store.state.activities.form.start
      },
      set (value) {
        this.$store.commit('activities/SET_FORM_START', value)
      }
    },
    end: {
      get () {
        return this.$store.state.activities.form.end
      },
      set (value) {
        this.$store.commit('activities/SET_FORM_END', value)
      }
    },
    attendees: {
      get () {
        return this.$store.state.activities.form.attendees
      },
      set (value) {
        this.$store.commit('activities/SET_FORM_ATTENDEES', value)
      }
    }
  },
  methods: {
    ...mapActions({
      loadTypes: 'activities/loadTypes',
      loadPipelineActivities: 'activities/loadPipelineActivities',
      setActivityTypeId: 'activities/setActivityTypeId',
      createActivity: 'activities/createActivity',
      syncActivities: 'activities/syncActivities'
    }),
    async newActivityModal (activityTypeId) {
      this.setActivityTypeId(activityTypeId)

      const activity = await this.createActivity({
        activityTypeId,
        positionId: this.details.id
      })

      if (!activity) return

      openWindow(activity.microsoftCalendarLink)
    },
    async runSync () {
      const { success } = await this.syncActivities(this.details.id)
      if (success) this.loadPipelineActivities(this.details.id)
    },
    activityTypeClass (activityTypeId) {
      const [activityType] = this.activityTypes.filter((type) => type.id === activityTypeId)

      let className = ''

      if (!activityType) return className

      switch (activityType.name.trim()) {
        case 'Ligação':
          className = 'fa-phone-alt'
          break
        case 'E-mail':
          className = 'fa-envelope'
          break
        case 'Mensagem':
          className = 'fa-comment-alt'
          break
        case 'Reunião presencial':
          className = 'fa-users'
          break
        case 'Videoconferência':
          className = 'fa-video'
          break
        case 'Tarefa':
          className = 'fa-tasks'
          break
        default:
          className = 'fa-question'
      }

      return className
    }
  },
  mounted () {
    this.loadTypes()
  }
}
</script>
