<template>
  <form class="">
    <div :class="`rating ${large ? 'lg' : ''}`">
      <label
        v-for="i in [...Array(5).keys()]"
        :key="`star_${i + 1}`"
        title="text"
        @click="changeRating(i + 1)"
      >
        <em class="fa fa-star"></em>
        <input
          :checked="i + 1 === model"
          type="radio"
          name="rate"
          :value="i + 1"
        />
      </label>
    </div>
  </form>
</template>

<script>
export default {
  name: 'CheckboxField',
  props: {
    value: String,
    large: Boolean
  },
  emits: ['change'],
  computed: {
    model: {
      get() {
        return 5 - this.value + 1
      },
      set(value) {
        this.$emit('change', value)
      }
    }
  },
  methods: {
    changeRating(rating) {
      this.model = 5 - rating + 1
    }
  }
}
</script>

<style lang="scss" scoped>
.rating {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;

  label {
    padding-left: 1.5px;
    padding-right: 1.5px;
  }

  &.lg label {
    padding-left: 5px;
    padding-right: 5px;
  }

  &:not(:checked) input {
    display: none;
  }
  &:not(:checked) label {
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 900;
    line-height: 100%;
    cursor: pointer;
    color: var(--light-ui-border, #e1e1eb);
  }

  &:not(:checked).lg label {
    font-size: 24px;
  }

  label:has(input:checked),
  label:has(input:checked) ~ label {
    color: var(--palette-manga-100, #eeb250);
  }
  &:not(:checked) label:hover,
  &:not(:checked) label:hover ~ label {
    color: var(--palette-manga-100, #eeb250);
    opacity: 0.6;
  }
}
</style>
