<template>
  <div class="form-group">
    <label :key="id">{{ label }}</label>
    <div class="input-range">
      <input type="range" v-model="model" :style="'background-size:' + bg" />
      <strong class="paragraph sm font-weight-700 text-color-coral icon">
        {{ customValue }}
      </strong>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SliderComponent',
  props: {
    label: String,
    modelValue: {
      type: String,
      default: ''
    },
    options: Array,
    placeholder: {
      type: String,
      default: 'Selecione...'
    },
    id: String,
    disabled: {
      default: false
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    },
    suffix: {
      type: String,
      default: '%'
    }
  },
  computed: {
    model: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    },
    customValue () {
      return `${this.model}${this.suffix}`
    },
    bg () {
      return ((this.model - this.min) * 100) / (this.max - this.min) + '% 100%'
    }
  }
}
</script>
