<template>
  <div style="background: #d3aaa8" class="chart-line">
    <div class="chart-line-header">
      <div class="flex-grow-1">
        <div class="name paragraph md font-weight-800">Atividades x dia</div>
        <div class="total">20,2</div>
      </div>
      <div>
        <button class="btn-icon">
            <em class="fal fa-ellipsis-vertical"></em>
        </button>
      </div>
    </div>
    <apexchart
      width="140%"
      height="200"
      type="area"
      :options="options"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
export default {
  name: 'LineChart',
  props: {
    labels: {
      type: Array,
      default: () => []
    },
    values: {
      type: Array,
      default: () => []
    },
    colors: {
      type: Array,
      default: () => ['#000000']
    }
  },
  data () {
    return {
      options: {
        colors: this.colors,
        chart: {
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 2,
          colors: ['#ffffff']
        },
        grid: {
          borderColor: '#E1E1EB',
          clipMarkers: false,
          yaxis: {
            lines: {
              show: false
            }
          },
          xaxis: {
            lines: {
              show: false
            }
          }
        },
        yaxis: {
          labels: {
            show: false
          }
        },
        xaxis: {
          labels: {
            show: false
          },
          categories: this.labels
        },
        markers: {
          size: 0,
          colors: '#d3aaa8',
          strokeColor: '#F8F8FF',
          strokeWidth: 4
        }
      },
      series: [
        {
          data: [30, 35, 30, 35, 40, 35, 40, 45, 40, 35, 30, 35]
        }
      ]
    }
  }
}
</script>
