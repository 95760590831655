<template>
  <div class="dialog dialog-user" :class="{ active: dialog }">
    <div class="dialog-content sm">
      <div class="dialog-content-image">
        <div class="image-top-bar">
          <div />
          <button class="btn btn-coral icon xs" @click="close()">
            <em class="fal fa-times"></em>
          </button>
        </div>
        <b-avatar class="full" :name="user.name">
          <img v-if="user.photo" :src="user.photo" :alt="user.name" />
        </b-avatar>
        <div class="image-bottom-bar">
          <div class="flag plonge">
            <img src="/images/p.svg" alt="" />
          </div>
          <div class="d-flex gap-4">
            <button
              class="flag text-color-default"
              title="Abrir atividades"
              @click="showDetails('atividades')"
            >
              <em class="fal fa-list-check"></em>
            </button>
            <button
              class="flag text-color-default"
              title="Editar contato"
              @click="showDetails('dados')"
            >
              <em class="fal fa-pen"></em>
            </button>
          </div>
        </div>
      </div>
      <div class="dialog-content-body">
        <div class="pa-2 bg-contrast">
          <div class="paragraph md font-weight-600 mb-xs">
            {{ user.name }}
          </div>
          <template v-if="user.type === 'placeds'">
            <div class="paragraph xs text-color-light mb-xs">
              <span></span>
            </div>
            <div class="paragraph xs text-color-light mb-xs">
              <span>{{ user.company.name }}</span>
            </div>
          </template>
          <template v-else>
            <b-autocomplete
              v-model="input"
              class="xs coral mb-0"
              :options="['Seleção', 'Pesquisa', 'Embarque']"
            />
          </template>
        </div>
        <template v-if="email || phone">
          <span class="divider"></span>
          <div class="list">
            <div v-if="email" class="list-item">
              <em class="fal fa-envelope"></em>
              <span>
                <a class="text-color-light" :href="`mailto:${email}`">{{
                  email
                }}</a>
              </span>
            </div>
            <div v-if="phone" class="list-item">
              <em class="fal fa-phone"></em>
              <span>{{ phone }}</span>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from '@/mixins'
export default {
  name: 'UserModal',
  mixins: [mixins],
  props: {
    user: {
      type: Object
    }
  },
  data() {
    return {
      dialog: false,
      input: ''
    }
  },
  computed: {
    email() {
      if (!this.user.email || !this.user.email.trim().length) return
      return this.user.email.trim()
    },
    phone() {
      if (!this.user.phone || !this.user.phone.trim().length) return

      const numbers = this.user.phone.trim().replace(/\D/g, '')
      const middleIndex = numbers.length === 11 ? 7 : 6

      const code = numbers.substring(0, 2)
      const phone =
        numbers.substring(2, middleIndex) + '.' + numbers.substring(middleIndex)

      return `+55 ${code} ${phone}`
    }
  },
  watch: {
    user: {
      handler() {
        if (this.user.profile) {
          this.input = this.user.profile
        }
      },
      deep: true
    }
  },
  methods: {
    open() {
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    showDetails(tab) {
      this.$router.push({
        path: '/contato',
        query: {
          tab,
          id: this.user.id
        }
      })
    }
  }
}
</script>
