<template>
  <div class="w-100 historico">
    <div class="w-100 d-flex justify-between pt-3 pb-3">
      <button
v-if="false && currentHistoryIdentifier.contactId" class="btn btn-success outlined sm"
        title="Atualizar histórico" disabled @click="runMessageSync">
        <em class="fal fa-sync"></em>
      </button>
      <div></div>
      <b-dropdown x="right">
        <template #trigger="{ on }">
          <button class="btn btn-coral outlined sm" v-on="on">
            <em class="fal fa-plus"></em>
            <span>Novo</span>
          </button>
        </template>

        <template #content>
          <div class="list">
            <button class="list-item" @click="toggleRegisterModal('registerDialog')">
              <em class="fal fa-history"></em>
              <span>Histórico</span>
            </button>
          </div>

        </template>
      </b-dropdown>
    </div>
    <div v-for="reg in history" :key="reg.id" class="list list-with-shadow">
      <div class="list-item align-center">
        <div v-if="currentCandidate && !hideInfo.includes('candidato')" class="d-flex gap-8 align-center">
          <b-avatar :name="currentCandidate.name" size="xs">
            <img :src="currentCandidate.photo" :alt="currentCandidate.name" />
          </b-avatar>
          <div class="flex-grow-1">
            <div class="paragraph xs">
              <strong v-text="currentCandidate.name"></strong>
            </div>
            <div
class="paragraph xs text-color-light" v-text="currentCandidate.emails.length
          ? currentCandidate.emails[0].email
          : ''
        "></div>
          </div>
        </div>
        <p
class="paragraph xs lines-1 truncate truncate-after ma-0" :class="{
        'flex-grow-1': hideInfo.includes('descricao'),
        'title-w': !hideInfo.includes('descricao')
      }">
          {{ reg.title }}
        </p>
        <div v-if="currentPipeline.id && !hideInfo.includes('descricao')" class="d-flex-column flex-grow-1">
          <p class="paragraph xs fs-8 ma-0" :class="{ 'fs-8': !!currentPipeline.name }">
            Descrição
          </p>
          <p class="paragraph xs fs-12 lines-1 truncate truncate-after ma-0">
            {{ reg.description || '...' }}
          </p>
        </div>
        <div
v-if="currentPipeline.id && !hideInfo.includes('vaga')" class="d-flex-column"
          :class="{ 'flex-grow-1': hideInfo.includes('descricao') }">
          <p
v-if="currentPipeline?.company?.name" class="paragraph xs lines-1 truncate truncate-after ma-0"
            :class="{ 'fs-8': !!currentPipeline.name }">
            {{ currentPipeline?.company?.name }}
          </p>
          <p v-if="currentPipeline.name" class="paragraph xs fs-12 lines-1 truncate truncate-after ma-0">
            {{ currentPipeline.name }}
          </p>
        </div>
        <p class="paragraph xs date ma-0">
          {{ reg.date }}
        </p>
        <div class="d-flex">
          <button v-if="reg.microsoftWebLink" class="btn xxs icon text ma-0" @click="openLink(reg.microsoftWebLink)">
            <em class="fal fa-external-link"></em>
          </button>
          <b-dropdown x="right">
            <template #trigger="{ on }">
              <button class="btn xxs icon text ma-0" v-on="on">
                <em class="fal fa-ellipsis-vertical"></em>
              </button>
            </template>

            <template #content>
              <div class="list">
                <button
class="list-item" @click="
        reg.microsoftWebLink
          ? openLink(reg.microsoftWebLink)
          : toggleRegisterModal('updateDialog', reg)
        ">
                  <em class="fal fa-edit"></em>
                  <span>Editar histórico</span>
                </button>
                <span class="divider"></span>
                <button class="list-item text-color-danger" @click="deleteHistory(reg.id)">
                  <em class="fal fa-ban text-color-danger"></em>
                  <span>Remover histórico</span>
                </button>
              </div>
            </template>
          </b-dropdown>
        </div>
      </div>
    </div>

    <b-dialog v-model="dialog.registerDialog" size="xl2" :dismissable="false">
      <template #header>
        <div class="btn icon btn-coral no-hover">
          <em class="fal fa-plus"></em>
        </div>
        <div class="flex-grow-1 h4 ml-2 mr-2 text-color-default">Histórico</div>
        <button class="btn text icon" @click="closeDialog('registerDialog')">
          <em class="fal fa-times"></em>
        </button>
      </template>

      <template #body>
        <div class="pa-2 bg-default">
          <b-autocomplete
id="history-types-register" v-model="register.typeHistoryId" label="Tipo"
            :options="typeHistory" option-text="name" option-value="id"></b-autocomplete>
          <p v-if="errors.typeHistoryId" class="text-color-danger">
            {{ errors.typeHistoryId }}
          </p>
          <b-textfield v-model="register.title" label="Título" :hint="errors.title"></b-textfield>
          <b-textfield
v-model="register.description" label="Descrição" :multiline="true"
            :hint="errors.description"></b-textfield>
        </div>
      </template>

      <template #footer>
        <button class="btn outlined sm" @click="closeDialog('registerDialog')">
          Cancelar
        </button>
        <button class="btn btn-coral sm" @click="createNewHistory(false)">
          Salvar
        </button>
      </template>
    </b-dialog>

    <b-dialog v-model="dialog.updateDialog" size="lg" :dismissable="false">
      <template #header>
        <div class="btn icon btn-coral no-hover">
          <em class="fal fa-plus"></em>
        </div>
        <div class="flex-grow-1 h4 ml-2 mr-2 text-color-default">
          Atualizar Histórico
        </div>
        <button class="btn text icon" @click="closeDialog('updateDialog')">
          <em class="fal fa-times"></em>
        </button>
      </template>

      <template #body>
        <div class="pa-2 bg-default">
          <b-autocomplete
id="history-types-update" v-model="register.typeHistoryId" label="Tipo" :options="typeHistory"
            option-text="name" option-value="id"></b-autocomplete>
          <p v-if="errors.typeHistoryId" class="text-color-danger">
            {{ errors.typeHistoryId }}
          </p>
          <b-textfield v-model="register.title" label="Título" :hint="errors.title"></b-textfield>
          <b-textfield
v-model="register.description" label="Descrição" :multiline="true"
            :hint="errors.description"></b-textfield>
        </div>
      </template>

      <template #footer>
        <button class="btn outlined sm" @click="closeDialog('updateDialog')">
          Cancelar
        </button>
        <button class="btn btn-coral sm" @click="updateHistory()">
          Salvar
        </button>
      </template>
    </b-dialog>

    <b-confirm-delete ref="confirm"></b-confirm-delete>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { openWindow } from '@/helpers'

export default {
  name: 'HistoricoComponent',
  props: {
    hideInfo: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      dialog: {
        registerDialog: false,
        updateDialog: false
      },
      register: {
        typeHistoryId: '',
        title: '',
        description: ''
      },
      errors: {
        typeHistoryId: '',
        title: '',
        description: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      history: 'history/records',
      currentHistoryIdentifier: 'history/currentHistoryIdentifier',
      currentCandidate: 'jobs/currentCandidate',
      currentPipeline: 'jobs/currentPipeline',
      typeHistory: 'history/typeHistory'
    })
  },
  watch: {
    registerDialog (close) {
      if (!close) {
        this.register = {
          title: '',
          description: '',
          id: ''
        }
      }
    },
    'register.title': function (newTitle) {
      this.validateField('title', newTitle)
    },
    'register.description': function (newDescription) {
      this.validateField('description', newDescription)
    },
    'register.typeHistoryId': function (newTypeHistoryId) {
      this.validateField('typeHistoryId', newTypeHistoryId)
    }
  },
  async mounted () {
    await this.loadHistory()
  },
  methods: {
    ...mapActions({
      newHistory: 'history/createHistory',
      runSync: 'history/syncHistory',
      loadHistory: 'history/loadHistory',
      removeHistory: 'history/removeHistory',
      updateHistoryCandidate: 'history/updateHistory'
    }),
    createNewHistory (isDraft = false) {
      this.validateField('title', this.register.title)
      this.validateField('description', this.register.description)
      this.validateField('typeHistoryId', this.register.typeHistoryId)

      if (this.hasErrors()) return

      this.newHistory({
        title: this.register.title,
        description: this.register.description,
        typeHistoryId: this.register.typeHistoryId,
        isDraft
      })
      this.closeDialog('registerDialog')
    },
    deleteHistory (id) {
      this.removeHistory(id)
    },
    runMessageSync () {
      return this.runSync().then(this.loadHistory)
    },
    toggleRegisterModal (value, register) {
      this.resetErrors()
      this.dialog[value] = true
      if (register) {
        this.register = {
          title: register.title,
          description: register.description,
          id: register.id,
          typeHistoryId: register.typeHistoryId
        }
      }
    },
    closeDialog (value) {
      this.dialog[value] = false
      this.clearForm()
      this.resetErrors()
    },
    updateHistory () {
      this.updateHistoryCandidate(this.register)
      this.closeDialog('updateDialog')
    },
    openLink (link) {
      return openWindow(link)
    },
    clearForm () {
      this.register.title = ''
      this.register.description = ''
      this.register.id = ''
      this.register.typeHistoryId = ''
    },
    validateField (fieldName, value) {
      if (!value) {
        this.errors[fieldName] = 'Campo obrigatório'
      } else {
        this.errors[fieldName] = ''
      }
    },
    hasErrors () {
      return Object.values(this.errors).some((error) => error !== '')
    },
    resetErrors () {
      for (const key in this.errors) {
        this.errors[key] = ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
.title-w {
  min-width: 90px;
  max-width: 90px;
}

.description-w {
  min-width: 200px;
  max-width: 200px;
}

.historico {
  display: flex;
  flex-direction: column;
}

p.date {
  color: var(--light-text-light, #8c8ba7);
  text-align: right;
}

.candidate-form {
  gap: 4px;
}

.w-50 {
  width: calc(50% - 2px);
}

ul {
  width: 100%;

  &>li {
    height: 42px;
    border-bottom: 1px solid var(--border);
    display: flex;
    align-items: center;
  }
}

.py-3 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.ma-0 {
  margin: 0;
}
</style>
