<template>
  <div class="w-100">
    <div v-if="!candidancy" class="subheader title py-3">Atividades</div>
    <span v-if="!candidancy" class="divider"></span>
    <User v-if="!candidancy" />
    <div>
      <div ref="perfil" class="py-3">
        <div class="subheader mb-2">Perfil</div>
        <b-autocomplete v-model="jobLevelId" placeholder="Selecione o status" :options="jobLevelOptions"
          option-text="name" option-value="id" label="Senioridade" />
      </div>
      <span class="divider"></span>
      <div ref="idiomas" class="py-3">
        <div class="subheader">Idiomas</div>
        <div class="d-flex justify-end">
          <button class="btn btn-coral outlined sm" @click="toggleDialog('language')">
            <em class="fal fa-plus"></em>
            <span>Adicionar</span>
          </button>
        </div>
        <div class="list list-with-shadow mt-2">
          <div v-for="record in parsedLanguages" :key="record.languageId" class="list-item">
            <em class="fal fa-language"></em>
            <div class="paragraph xs text-color-light">
              {{ record.language }}
            </div>
            <div class="paragraph xs flex-grow-1">{{ record.level }}</div>
            <button class="icon text" @click="removeLanguage(record.languageId)">
              <em class="fal fa-trash"></em>
            </button>
          </div>
        </div>
      </div>
      <span class="divider"></span>
      <div ref="competencias" class="py-3">
        <div class="subheader">Competências técnicas</div>
        <div class="d-flex justify-end mb-2">
          <button class="btn btn-coral outlined sm" @click="openSkillList">
            <em class="fal fa-plus"></em>
            <span>Adicionar</span>
          </button>
        </div>
        <div class="list list-with-shadow">
          <div v-for="record in parsedSkills" :key="record?.id" class="list-item">
            <em class="fal fa-pen-ruler"></em>
            <div class="paragraph xs flex-grow-1 text-color-light">
              {{ record.name }}
            </div>
            <button class="icon text" @click="removeSkill(record?.id)">
              <em class="fal fa-trash"></em>
            </button>
          </div>
        </div>
      </div>
      <span class="divider"></span>
      <div class="py-3">
        <div class="subheader">Competências comportamentais</div>
        <div class="d-flex justify-end mb-2">
          <button class="btn btn-coral outlined sm" @click="openSoftSkillList">
            <em class="fal fa-plus"></em>
            <span>Adicionar</span>
          </button>
        </div>
        <div class="list list-with-shadow">
          <div v-for="record in parsedSoftSkills" :key="record?.id" class="list-item">
            <em class="fal fa-person-running"></em>
            <div class="paragraph xs flex-grow-1 text-color-light">
              {{ record.name }}
            </div>
            <button class="icon text" @click="removeSoftSkill(record?.id)">
              <em class="fal fa-trash"></em>
            </button>
          </div>
        </div>
      </div>
      <span class="divider"></span>
      <div ref="formacao" class="py-3">
        <div class="subheader">Formação</div>
        <div class="d-flex justify-end mb-2">
          <button class="btn btn-coral outlined sm" @click="toggleDialog('schooling')">
            <em class="fal fa-plus"></em>
            <span>Adicionar</span>
          </button>
        </div>
        <div class="list list-with-shadow">
          <div v-for="record in parsedSchoolings" :key="record.schoolingId" class="list-item">
            <em class="fal" :class="record.iconClass"></em>
            <div class="paragraph xs text-color-light">{{ record.type }}</div>
            <div class="paragraph xs flex-grow-1">{{ record.schooling }}</div>
            <button class="icon text" @click="removeSchooling(record.schoolingId)">
              <em class="fal fa-trash"></em>
            </button>
          </div>
        </div>
      </div>
    </div>

    <b-dialog v-model="dialog.skillsList" size="md" :dismissable="false">
      <template #header>
        <div class="btn icon btn-coral no-hover">
          <em class="fal fa-pen-ruler"></em>
        </div>
        <div class="flex-grow-1 h4 ml-2 mr-2 text-color-default">
          Competências Técnicas
        </div>
        <button class="btn text icon" @click="toggleDialog('skillsList')">
          <em class="fal fa-times"></em>
        </button>
      </template>
      <template #body>
        <div class="pa-1 d-flex gap-8">
          <b-textfield v-model="forms.skillsList.filter" placeholder="Buscar" name="search" type="search"
            append-icon="fal fa-search" class="sm mb-0 flex-grow-1" />
          <button class="btn btn-coral sm" @click="toggleDialog('skill')">
            <em class="fal fa-user-plus"></em>
            <span>Novo</span>
          </button>
        </div>
        <span class="divider"></span>
        <div class="list list-with-divider bg-contrast">
          <div v-for="skill in filteredSkills" :key="skill?.id" class="list-item">
            <b-checkbox :id="`skill-${skill?.id}`" v-model="forms.skillsList.selected" :input-value="skill?.id"
              class="invert pb-2 pt-2">
              <template #label>
                <div class="flex-grow-1" v-text="skill.name"></div>
              </template>
            </b-checkbox>
          </div>
        </div>
      </template>
      <template #footer>
        <button class="btn outlined sm" @click="toggleDialog('skillsList')">
          Cancelar
        </button>
        <button class="btn btn-coral sm" @click="changeSkillList">
          Salvar
        </button>
      </template>
    </b-dialog>

    <b-dialog v-model="dialog.softSkillsList" size="md" :dismissable="false">
      <template #header>
        <div class="btn icon btn-coral no-hover">
          <em class="fal fa-pen-ruler"></em>
        </div>
        <div class="flex-grow-1 h4 ml-2 mr-2 text-color-default">
          Competências Comportamentais
        </div>
        <button class="btn text icon" @click="toggleDialog('softSkillsList')">
          <em class="fal fa-times"></em>
        </button>
      </template>
      <template #body>
        <div class="pa-1 d-flex gap-8">
          <b-textfield v-model="forms.softSkillsList.filter" placeholder="Buscar" name="search" type="search"
            append-icon="fal fa-search" class="sm mb-0 flex-grow-1" />
          <button class="btn btn-coral sm" @click="toggleDialog('softSkill')">
            <em class="fal fa-user-plus"></em>
            <span>Novo</span>
          </button>
        </div>
        <span class="divider"></span>
        <div class="list list-with-divider bg-contrast">
          <div v-for="skill in filteredSoftSkills" :key="skill?.id" class="list-item">
            <b-checkbox :id="`soft-skill-${skill?.id}`" v-model="forms.softSkillsList.selected" :input-value="skill?.id"
              class="invert pb-2 pt-2">
              <template #label>
                <div class="flex-grow-1" v-text="skill.name"></div>
              </template>
            </b-checkbox>
          </div>
        </div>
      </template>
      <template #footer>
        <button class="btn outlined sm" @click="toggleDialog('softSkillsList')">
          Cancelar
        </button>
        <button class="btn btn-coral sm" @click="changeSoftSkillList">
          Salvar
        </button>
      </template>
    </b-dialog>

    <b-dialog v-model="dialog.language" size="md" :dismissable="false">
      <template #header>
        <div class="btn icon btn-coral no-hover">
          <em class="fal fa-language"></em>
        </div>
        <div class="flex-grow-1 h4 ml-2 mr-2 text-color-default">Idioma</div>
        <button class="btn text icon" @click="toggleDialog('language')">
          <em class="fal fa-times"></em>
        </button>
      </template>
      <template #body>
        <div class="pa-2 bg-default">
          <b-autocomplete v-model="forms.language.languageId" placeholder="Selecione o idioma"
            :options="languageOptions" option-text="name" option-value="id" label="Idioma" />
          <b-autocomplete v-model="forms.language.languageLevelId" placeholder="Selecione o nível"
            :options="languageLevelOptions" option-text="name" option-value="id" label="Nível" />
        </div>
      </template>
      <template #footer>
        <button class="btn outlined sm" @click="toggleDialog('language')">
          Cancelar
        </button>
        <button class="btn btn-coral sm" @click="addLanguage">Salvar</button>
      </template>
    </b-dialog>

    <b-dialog v-model="dialog.skill" size="md" :dismissable="false">
      <template #header>
        <div class="btn icon btn-coral no-hover">
          <em class="fal fa-pen-ruler"></em>
        </div>
        <div class="flex-grow-1 h4 ml-2 mr-2 text-color-default">
          Nova Competência Técnica
        </div>
        <button class="btn text icon" @click="toggleDialog('skill')">
          <em class="fal fa-times"></em>
        </button>
      </template>
      <template #body>
        <div class="pa-2 bg-default">
          <b-textfield v-model="forms.skill.name" label="Nome" :hint="errors.skillError"></b-textfield>
        </div>
      </template>
      <template #footer>
        <button class="btn outlined sm" @click="toggleDialog('skill')">
          Cancelar
        </button>
        <button class="btn btn-coral sm" @click="createSkillRecord">
          Salvar
        </button>
      </template>
    </b-dialog>

    <b-dialog v-model="dialog.softSkill" size="md" :dismissable="false">
      <template #header>
        <div class="btn icon btn-coral no-hover">
          <em class="fal fa-pen-ruler"></em>
        </div>
        <div class="flex-grow-1 h4 ml-2 mr-2 text-color-default">
          Nova Competência Comportamental
        </div>
        <button class="btn text icon" @click="toggleDialog('softSkill')">
          <em class="fal fa-times"></em>
        </button>
      </template>
      <template #body>
        <div class="pa-2 bg-default">
          <b-textfield v-model="forms.softSkill.name" label="Nome" :hint="errors.softSkillError"></b-textfield>
        </div>
      </template>
      <template #footer>
        <button class="btn outlined sm" @click="toggleDialog('softSkill')">
          Cancelar
        </button>
        <button class="btn btn-coral sm" @click="createSoftSkillRecord">
          Salvar
        </button>
      </template>
    </b-dialog>

    <b-dialog v-model="dialog.schooling" size="md" :dismissable="false">
      <template #header>
        <div class="btn icon btn-coral no-hover">
          <em class="fal fa-graduation-cap"></em>
        </div>
        <div class="flex-grow-1 h4 ml-2 mr-2 text-color-default">Formação</div>
        <button class="btn text icon" @click="toggleDialog('schooling')">
          <em class="fal fa-times"></em>
        </button>
      </template>
      <template #body>
        <div class="pa-2 bg-default">
          <b-autocomplete v-model="forms.schooling.schoolingTypeId" placeholder="Selecione o tipo de curso"
            :options="schoolingTypeOptions" option-text="name" option-value="id" label="Tipo de curso" />
          <b-autocomplete v-model="forms.schooling.schoolingId" placeholder="Selecione o curso"
            :options="schoolingOptions" option-text="name" option-value="id" label="Curso" />
        </div>
      </template>
      <template #footer>
        <button class="btn outlined sm" @click="toggleDialog('schooling')">
          Cancelar
        </button>
        <button class="btn btn-coral sm" @click="addSchooling">Salvar</button>
      </template>
    </b-dialog>
    <b-confirm-delete ref="confirm"></b-confirm-delete>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import User from '@/components/contato/user.vue'

export default {
  name: 'CurriculoComponent',
  components: { User },
  props: {
    candidancy: {
      type: Boolean
    },
    candidate: {
      type: Object
    }
  },
  data () {
    return {
      dialog: {
        language: false,
        skill: false,
        softSkill: false,
        schooling: false,
        skillsList: false,
        softSkillsList: false
      },
      forms: {
        language: {
          languageId: '',
          languageLevelId: '',
          languageRequirementId: ''
        },
        skill: {
          name: ''
        },
        softSkill: {
          name: ''
        },
        schooling: {
          schoolingTypeId: '',
          schoolingId: ''
        },
        skillsList: {
          selected: [],
          filter: ''
        },
        softSkillsList: {
          selected: [],
          filter: ''
        }
      },
      errors: {
        skillError: '',
        softSkillError: ''
      }
    }
  },


  computed: {
    ...mapGetters({
      currentCompany: 'company/currentCompany',
      schoolingOptions: 'curriculum/schooling',
      schoolingTypeOptions: 'curriculum/schoolingType',
      skillsOptions: 'curriculum/skills',
      softSkillsOptions: 'curriculum/softSkills',
      languageOptions: 'curriculum/language',
      languageLevelOptions: 'curriculum/languageLevel',
      jobLevelOptions: 'curriculum/jobLevel',
      currentStep: 'session/currentStep',
      contact: 'contacts/currentContact',
      currentCandidate: 'jobs/currentCandidate'
    }),
    filteredSkills () {
      const list = Object.assign([], this.skillsOptions)

      list.sort((a, b) => {
        if (a.name > b.name) return 1
        if (a.name < b.name) return -1
        return 0
      })

      const filter = this.forms.skillsList.filter.trim().toUpperCase()

      if (!filter) return list

      return list.filter((skill) =>
        skill.name.trim().toUpperCase().includes(filter)
      )
    },
    filteredSoftSkills () {
      const list = Object.assign([], this.softSkillsOptions)

      list.sort((a, b) => {
        if (a.name > b.name) return 1
        if (a.name < b.name) return -1
        return 0
      })

      const filter = this.forms.softSkillsList.filter.trim().toUpperCase()

      if (!filter) return list

      return list.filter((skill) =>
        skill.name.trim().toUpperCase().includes(filter)
      )
    },
    parsedLanguages () {
      const records = []

      this.languages.forEach((record) => {
        const [language] = this.languageOptions.filter(
          (language) => language?.id === record.languageId
        )
        const [level] = this.languageLevelOptions.filter(
          (level) => level?.id === record.languageLevelId
        )

        records.push({
          languageId: record?.languageId,
          language: language?.name,
          level: level?.name
        })
      })

      return records
    },
    parsedSchoolings () {
      const records = []

      this.schooling.forEach((record) => {
        const [schooling] = this.schoolingOptions.filter(
          (schooling) => schooling?.id === record.schoolingId
        )
        const [type] = this.schoolingTypeOptions.filter(
          (type) => type?.id === record.schoolingTypeId
        )

        records.push({
          schoolingId: record.schoolingId,
          schooling: schooling.name,
          type: type.name,
          iconClass:
            type.name.trim() === 'Técnico'
              ? 'fa-file-certificate'
              : 'fa-graduation-cap'
        })
      })

      return records
    },
    parsedSkills () {
      return this.skillsOptions.filter((skill) =>
        this.skills.includes(skill?.id)
      )
    },
    parsedSoftSkills () {
      return this.softSkillsOptions.filter((softSkill) =>
        this.softSkills.includes(softSkill?.id)
      )
    },
    languages: {
      get () {
        return this.$store.state.curriculum.form.languages
      },
      set (value) {
        this.$store.commit('curriculum/SET_FORM_LANGUAGES', value)
      }
    },
    skills: {
      get () {
        return this.$store.state.curriculum.form.skills
      },
      set (value) {
        this.$store.commit('curriculum/SET_FORM_SKILLS', value)
      }
    },
    softSkills: {
      get () {
        return this.$store.state.curriculum.form.softSkills
      },
      set (value) {
        this.$store.commit('curriculum/SET_FORM_SOFT_SKILLS', value)
      }
    },
    schooling: {
      get () {
        return this.$store.state.curriculum.form.schooling
      },
      set (value) {
        this.$store.commit('curriculum/SET_FORM_SCHOOLING', value)
      }
    },
    jobLevelId: {
      get () {
        return this.$store.state.curriculum.form.jobLevelId
      },
      set (value) {
        this.$store.commit('curriculum/SET_FORM_JOB_LEVEL_ID', value)
        this.updateContact()
      }
    }
  },
  watch: {
    'dialog.language' (close) {
      if (!close) this.resetForms('language')
    },
    'dialog.skill' (close) {
      if (!close) this.resetForms('skill')
    },
    'dialog.softSkill' (close) {
      if (!close) this.resetForms('softSkill')
    },
    'dialog.schooling' (close) {
      if (!close) this.resetForms('schooling')
    },
    'dialog.skillsList' (close) {
      if (!close) this.resetForms('skillsList')
    },
    'dialog.softSkillsList' (close) {
      if (!close) this.resetForms('softSkillsList')
    }
    ,
    jobLevelId: {
      immediate: true,
      handler (value) {
        if (value) {
          this.loadJobOptions()
        }
      }
    }
  },
  mounted () {
    this.loadJobOptions()
    if (this.$route.query.id) {
      this.loadContactData(this.$route.query.id).catch(this.resetData)
    }
  },
  methods: {
    ...mapActions({
      loadJobOptions: 'curriculum/loadJobOptions',
      loadContactData: 'curriculum/loadContactData',
      createSkill: 'curriculum/createSkill',
      createSoftSkill: 'curriculum/createSoftSkill',
      resetData: 'curriculum/resetData',
      updateCurriculum: 'curriculum/updateCurriculum',
      matchJobCandidate: 'jobs/matchJobCandidate'
    }),
    scrollTo (id) {
      this.$nextTick(() => {
        this.$refs[id].scrollIntoView({ behavior: 'smooth' })
      })
    },
    async updateContact () {
      this.updateCurriculum(this.contact.id).then((result) => {
        if (result) {
          this.matchJobCandidate({
            jobApplicationId: this.currentCandidate.id,
            contactId: this.contact.id,
            pipelineId: this.currentCandidate.pipelineId
          })
        }
      })
    },
    resetForms (section = null) {
      const resetForm = {
        language: () => {
          this.forms.language.languageId = ''
          this.forms.language.languageLevelId = ''
          this.forms.language.languageRequirementId = ''
        },
        skill: () => this.forms.skill.name = '',
        softSkill: () => this.forms.softSkill.name = '',
        schooling: () => {
          this.forms.schooling.schoolingTypeId = ''
          this.forms.schooling.schoolingId = ''
        },
        skillsList: () => {
          this.forms.skillsList.selected = []
          this.forms.skillsList.filter = ''
        },
        softSkillsList: () => {
          this.forms.softSkillsList.selected = []
          this.forms.softSkillsList.filter = ''
        }
      }

      if (section) {
        resetForm[section]?.()
      } else {
        Object.values(resetForm).forEach(reset => reset())
      }

    },
    toggleDialog (dialog) {
      this.dialog[dialog] = !this.dialog[dialog]
    },
    openSkillList () {
      this.forms.skillsList.selected = Object.assign([], this.skills)
      this.toggleDialog('skillsList')
    },
    openSoftSkillList () {
      this.forms.softSkillsList.selected = Object.assign([], this.softSkills)
      this.toggleDialog('softSkillsList')
    },
    addLanguage () {
      const languageToAdd = { ...this.forms.language }

      const { languageId, languageLevelId } = languageToAdd

      const alreadyAdded = this.languages.filter(
        (language) =>
          language.languageId === languageId &&
          language.languageLevelId === languageLevelId
      )?.length

      if (alreadyAdded) return this.toggleDialog('language')

      this.languages = [...this.languages, languageToAdd]

      this.toggleDialog('language')

      this.updateContact()
    },
    addSchooling () {
      const schoolingToAdd = { ...this.forms.schooling }

      const { schoolingId, schoolingTypeId } = schoolingToAdd

      const alreadyAdded = this.schooling.filter(
        (schooling) =>
          schooling.schoolingId === schoolingId &&
          schooling.schoolingTypeId === schoolingTypeId
      )?.length

      if (alreadyAdded) return this.toggleDialog('schooling')

      this.schooling = [...this.schooling, schoolingToAdd]

      this.toggleDialog('schooling')

      this.updateContact()
    },
    createSkillRecord () {
      if (
        this.skillsOptions.filter(
          (skill) =>
            skill.name.toLowerCase() === this.forms.skill.name.toLowerCase()
        ).length > 0
      ) {
        this.errors.skillError =
          'Já existe uma competência cadastrada com esse nome'
        return
      }

      this.errors.skillError = ''
      this.createSkill(this.forms.skill.name)
      this.dialog.skill = false
    },
    createSoftSkillRecord () {
      if (
        this.softSkillsOptions.filter(
          (skill) =>
            skill.name.toLowerCase() === this.forms.softSkill.name.toLowerCase()
        ).length > 0
      ) {
        this.errors.softSkillError =
          'Já existe uma competência cadastrada com esse nome'
        return
      }

      this.errors.softSkillError = ''
      this.createSoftSkill(this.forms.softSkill.name)
      this.dialog.softSkill = false
    },
    changeSkillList () {
      this.skills = Object.assign([], this.forms.skillsList.selected)
      this.toggleDialog('skillsList')
      this.updateContact()
    },
    changeSoftSkillList () {
      this.softSkills = Object.assign([], this.forms.softSkillsList.selected)
      this.toggleDialog('softSkillsList')
      this.updateContact()
    },
    removeLanguage (languageId) {
      this.languages = this.languages.filter(
        (language) => language.languageId !== languageId
      )
      this.updateContact()
    },
    removeSchooling (schoolingId) {
      this.schooling = this.schooling.filter(
        (schooling) => schooling.schoolingId !== schoolingId
      )
      this.updateContact()
    },
    removeSkill (skillId) {
      this.skills = this.skills.filter((record) => record !== skillId)
      this.updateContact()
    },
    removeSoftSkill (softSkillId) {
      this.softSkills = this.softSkills.filter(
        (record) => record !== softSkillId
      )
      this.updateContact()
    },
    async checkValue (data, index) {
      console.info(data, index)
      if (this.pairs[index].quantity > 1) {
        this.pairs[index].quantity--
      } else {
        const ok = await this.$refs.confirm.show({
          message: 'Tem certeza que deseja exluir este item?',
          button: 'Excluir',
          buttonColor: 'danger'
        })
        if (ok) {
          this.pairs.splice(index, 1)
          this.$refs.confirm.close()
        }
      }
    }
  },
}
</script>

<style scoped lang="scss">
.candidate-form {
  gap: 4px;
}

.w-50 {
  width: calc(50% - 2px);
}

.btn {
  margin-right: 24px;
}

ul {
  width: 100%;

  &>li {
    height: 42px;
    border-bottom: 1px solid var(--border);
    display: flex;
    align-items: center;
  }
}

.py-3 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}
</style>
