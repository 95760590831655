<template>
  <div class="page">
    <Sidebar />
    <main class="page-content">
      <slot />
    </main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Sidebar from '../components/Sidebar.vue'
export default {
  name: 'DefautLayout',
  components: { Sidebar },
  computed: {
    ...mapGetters({
      theme: 'theme/theme'
    })
  },
  watch: {
    theme (val) {
      document.body.classList = val
    }
  }
}
</script>
