export default {
  namespaced: true,
  state: {
    theme: 'light'
  },
  getters: {
    theme: state => state.theme
  },
  mutations: {
    SET_THEME (state, theme) {
      state.theme = theme
    }
  },
  actions: {
    toggleTheme ({ commit, state }) {
      commit('SET_THEME', state.theme === 'light' ? 'dark' : 'light')
    }
  }
}
