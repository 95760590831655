<template>
  <div class="app">
    <router-view />
    <ConfirmExitDialog />
    <Snackbar
      v-for="snackbar in snackbarInstances"
      :key="snackbar.id"
      :message="snackbar.message"
      :type="snackbar.type"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Snackbar from './components/ui/Snackbar.vue'
import ConfirmExitDialog from './components/ui/ConfirmExitDialog.vue'
export default {
  components: { Snackbar, ConfirmExitDialog },
  computed: {
    ...mapGetters({
      snackbarInstances: 'snackbar/instances'
    })
  }
}
</script>
