const getItem = (key) => sessionStorage.getItem(key)
const setItem = (key, value) =>
  sessionStorage.setItem(
    key,
    value instanceof Object ? JSON.stringify(value) : value
  )

export default {
  namespaced: true,
  state: {
    currentStep: null
  },
  getters: {
    currentStep: (state) => {
      const step = parseInt(state.currentStep || getItem('session.currentStep'))

      if (!step || isNaN(step) || step < 0 || step > 5) return 1

      return step
    }
  },
  mutations: {
    SET_CURRENT_STEP (state) {
      state.currentStep = getItem('session.currentStep') || 1
    }
  },
  actions: {
    setCurrentStep ({ commit }, step) {
      setItem('session.currentStep', step)
      commit('SET_CURRENT_STEP')
    }
  }
}
