export default Object.freeze({
  logo: null,
  name: null,
  cnpj: null, // 19.913.318/0001-91
  linkedin: null,
  website: null,
  note: null,
  personaId: null,
  sectorId: null,
  categoryId: null,
  numberEmployeeId: null,
  billingId: null,
  offLimitIds: [],
  partnerIds: [],
  address: []
})
