<template>
  <BDialog v-model="show" noBorder :dismissable="false" size="md">
    <template #body>
      <div class="pl-3 pr-3 pt-3 pb-3 paragraph">
        <h1 class="title-text fs-18 mb-2">Salvar e fechar?</h1>
        <div class="paragraph sm">
          <div>Suas alterações não foram salvas.</div>
          <div>Quer salvar as alterações antes de fechar?</div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="d-flex flex-grow-1 gap-16 justify-end">
        <button class="btn sm outlined" @click="discardChanges">Não salvar</button>
        <button class="btn sm btn-coral" @click="saveChanges">Salvar</button>
      </div>
    </template>
  </BDialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ConfirmExitDialog',
  computed: {
    ...mapGetters({
      show: 'dialog/show'
    })
  },
  methods: {
    ...mapActions({
      discardChanges: 'dialog/discardChanges',
      saveChanges: 'dialog/saveChanges'
    })
  }
}
</script>
