<template>
  <div class="form-group">
    <div class="d-flex justify-between">
      <label v-if="label" :for="currentId" v-text="label"></label>
      <span
        v-if="sublabel"
        class="f-sm text-color-light"
        v-text="sublabel"
      ></span>
    </div>
    <div class="align-end d-flex gap-8">
      <div class="flex-grow-1">
        <div class="input-field has-icon">
          <em v-if="appendIcon" :class="appendIcon"></em>
          <input
            :id="currentId"
            v-model="term"
            class="form-control"
            :class="{ 'cursor-disabled': disabled }"
            :list="`${currentId}-list`"
            :placeholder="placeholder"
            :disabled="disabled"
            :name="name"
            type="text"
            autocomplete="new-password"
            @blur="setModelValue"
            @keyup="onKeyUp"
            @focus="onFocus"
            @input="$emit('input', $event.target.value)"
          />
          <label :for="currentId" class="icon">
            <div v-if="loading" class="loader"></div>
            <em class="fal fa-chevron-down"></em>
          </label>
        </div>
        <button v-if="showCreateNew" class="autocomplite" @click="suggest">
          Cadastrar novo
        </button>
        <datalist :id="`${currentId}-list`">
          <option
            v-for="option in autocompleteOptions"
            :key="`${currentId}-${getValue(option)}`"
            :value="option.autocompleteTitle"
          >
            {{ option.optionalTitle }}
          </option>
        </datalist>
      </div>
      <slot name="append-outer" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'AutocompleteField',
  props: {
    appendIcon: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    sublabel: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [String, Number, Object],
      default: ''
    },
    options: Array,
    suggest: {
      type: Function,
      default: null
    },
    placeholder: {
      type: String,
      default: 'Selecione uma opção...'
    },
    id: String,
    disabled: {
      type: Boolean,
      default: false
    },
    optionText: {
      type: String,
      default: ''
    },
    optionTitle: {
      type: String,
      default: ''
    },
    optionValue: {
      type: String,
      default: ''
    },
    onKeyUp: {
      type: Function,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    },
    moreButton: {
      type: Boolean,
      default: false
    }
  },
  emits: ['input', 'update:modelValue'],
  data() {
    return {
      term: ''
    }
  },
  computed: {
    model: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    currentId() {
      return `autocomplete-${this.id || 'any'}-${this.$.uid}`
    },
    showCreateNew() {
      if (!this.suggest) {
        return false
      }
      return !this.autocompleteOptions.filter((opt) =>
        opt.autocompleteTitle.includes(this.term)
      ).length
    },
    autocompleteOptions() {
      if (!this.options) return []
      return this.options.map((option, index) => {
        if (option instanceof Object) {
          const result = {
            ...option,
            autocompleteTitle: this.autocompleteValue(option, index)
          }

          if (this.optionTitle) result.optionalTitle = option[this.optionTitle]

          return result
        }

        return option
      })
    }
  },
  watch: {
    model() {
      const option = this.getOptionByValue(this.model)
      this.term = option ? option.autocompleteTitle : ''
    }
  },
  mounted() {
    if (!this.model) return
    const option = this.getOptionByValue(this.model)
    this.term = option ? option.autocompleteTitle : ''
  },
  methods: {
    getOptionByValue(value) {
      const [option] = this.autocompleteOptions.filter(
        (option) =>
          JSON.stringify(this.getValue(option)) === JSON.stringify(value)
      )
      return option || null
    },
    getValueByAutocompleteTitle() {
      const [option] = this.autocompleteOptions.filter(
        (option) => option.autocompleteTitle === this.term
      )
      return option ? this.getValue(option) : ''
    },
    getText(option) {
      if (!this.optionText) return option
      return option[this.optionText] || option
    },
    getValue(option) {
      if (!this.optionValue) return option
      return option[this.optionValue] || option
    },
    getOptionSpecification(option, index = null) {
      if (!option) return ''

      const text = this.getText(option).toString().trim().toUpperCase()

      const optionsWithSameText = this.options.filter(
        (option) =>
          this.getText(option).toString().trim().toUpperCase() === text
      )

      if (optionsWithSameText.length === 1) return ''

      let value = parseInt(this.getValue(option))

      if (isNaN(value)) value = parseInt(option.id || index)

      if (isNaN(value))
        value =
          option instanceof Object
            ? JSON.stringify(option).replace(/ /g, '')
            : option.toString().replace(/ /g, '')

      if (!value) return '???'

      return isNaN(value) ? value : `#${value.toString().padStart(4, '0')}`
    },
    autocompleteValue(option, index) {
      return `${this.getText(option)} ${this.getOptionSpecification(
        option,
        index
      )}`.trim()
    },
    setModelValue() {
      if (!this.getValueByAutocompleteTitle()) {
        const option = this.getOptionByValue(this.model)
        this.term = option ? option.autocompleteTitle : ''
      }
      this.model = this.term ? this.getValueByAutocompleteTitle() : ''
      this.isTyping = false
      this.$emit('input', '')
    },

    onFocus() {
      this.term = ''
      this.$emit('input', '')
    }
  }
}
</script>

<style scoped>
.loader {
  width: 24px;
  padding: 4px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: var(--coral);
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}
</style>
