<template>
  <div class="tab-content scrollable">
    <template v-if="name === 'Negócios' || name === 'Projetos' || name === 'Placeds'">
      <div class="pa-3">
        <div class="subheader">Valor do negócio</div>
        <div class="mt-2">
          <b-textfield placeholder="R$" type="money" v-model="dealValue" :hint="errors.dealValue" :required="true">
            <!-- <template v-slot:append-outer>
              <button class="btn icon sm" @click="$emit('openValor')">
                <em class="fal fa-pen"></em>
              </button>
            </template> -->
          </b-textfield>
        </div>
        <div class="list list-with-shadow">
          <div class="list-item" v-for="history in histories" :key="history.id + 'f-contato'">
            <b-avatar :name="history.name" size="xxs">
              <img src="" alt="Foto usuário" />
            </b-avatar>
            <span class="flex-grow-1 paragraph xs">{{ history.name }}</span>
            <span class="paragraph xs">R$ {{ verifyValueDecimal(history.dealValue) }}</span>
            <span class="paragraph xs text-color-light">há {{ history.date === 1 ? `${history.date} dia` :
              `${history.date} dias` }} </span>
            <!-- <b-dropdown x="right">
              <template v-slot:trigger="{ on }">
                <button class="btn-icon" v-on="on">
                  <em class="fal fa-ellipsis-vertical"></em>
                </button>
              </template>
              <template #content>
                <div class="list">
                  <button class="list-item">
                    <em class="fal fa-circle-info"></em>
                    <span>Detalhes</span>
                  </button>
                  <span class="divider"></span>
                  <button class="list-item" @click="$emit('openValorCorrecao')">
                    <em class="fal fa-pen-line"></em>
                    <span>Corrigir</span>
                  </button>
                </div>
              </template>
            </b-dropdown> -->
          </div>
        </div>
        <!-- <div class="mt-2">
          <b-input-file label="Contrato" id="contrato" />
          <b-textfield label="Ordem de compra" />
          <span class="divider"></span>
          <div class="pt-2 pb-2 mb-2 d-flex align-center">
            <div class="flex-grow-1 paragraph xs">Final billing</div>
            <b-switch v-model="form.billing" id="final-billing" />
          </div>
          <b-autocomplete v-if="form.billing" label="Final billing - nota" />
          <span class="divider"></span>
          <div class="pt-2 pb-2 mb-2 d-flex align-center">
            <div class="flex-grow-1 paragraph xs">
              Descrever posição na nota
            </div>
            <b-switch id="descrever" />
          </div>
        </div> -->
      </div>
      <!-- <span class="divider"></span>
      <div class="pa-3">
        <div class="subheader">Parcelas</div>
        <div class="mt-2">
          <b-datepicker label="Data do aceite" v-model="form.date" />
        </div>
        <div class="list list-with-shadow">
          <div class="list-item">
            <span class="paragraph xs font-weight-700">01</span>
            <span class="label-status bg-success">Paga</span>
            <span class="spacer"></span>
            <span class="paragraph xs">R$ 30.000.00</span>
            <div class="d-flex align-center gap-4">
              <em class="fal fa-calendar-pen text-color-light"></em>
              <span class="paragraph xs text-color-light">20/01/2022</span>
            </div>
            <div class="d-flex align-center gap-4">
              <em class="fal fa-calendar-exclamation text-color-light"></em>
              <span class="paragraph xs text-color-light">05/02/2022</span>
            </div>
            <b-dropdown x="right">
              <template v-slot:trigger="{ on }">
                <button class="btn-icon" v-on="on">
                  <em class="fal fa-ellipsis-vertical"></em>
                </button>
              </template>
              <template #content>
                <div class="list">
                  <button class="list-item">
                    <em class="fal fa-pen"></em>
                    <span>Editar</span>
                  </button>
                  <span class="divider"></span>
                  <button class="list-item text-color-danger">
                    <em class="fal fa-trash text-color-danger"></em>
                    <span>Excluir</span>
                  </button>
                </div>
              </template>
            </b-dropdown>
          </div>
          <div class="list-item">
            <span class="paragraph xs font-weight-700">02</span>
            <span class="label-status bg-success">Paga</span>
            <span class="spacer"></span>
            <span class="paragraph xs">R$ 30.000.00</span>
            <div class="d-flex align-center gap-4">
              <em class="fal fa-calendar-pen text-color-light"></em>
              <span class="paragraph xs text-color-light">20/01/2022</span>
            </div>
            <div class="d-flex align-center gap-4">
              <em class="fal fa-calendar-exclamation text-color-light"></em>
              <span class="paragraph xs text-color-light">05/02/2022</span>
            </div>
            <b-dropdown x="right">
              <template v-slot:trigger="{ on }">
                <button class="btn-icon" v-on="on">
                  <em class="fal fa-ellipsis-vertical"></em>
                </button>
              </template>
              <template #content>
                <div class="list">
                  <button class="list-item">
                    <em class="fal fa-pen"></em>
                    <span>Editar</span>
                  </button>
                  <span class="divider"></span>
                  <button class="list-item text-color-danger">
                    <em class="fal fa-trash text-color-danger"></em>
                    <span>Excluir</span>
                  </button>
                </div>
              </template>
            </b-dropdown>
          </div>
          <div class="list-item">
            <span class="paragraph xs font-weight-700">03</span>
            <span class="label-status bg-danger">Vencida</span>
            <span class="spacer"></span>
            <span class="paragraph xs">R$ 30.000.00</span>
            <div class="d-flex align-center gap-4">
              <em class="fal fa-calendar-pen text-color-light"></em>
              <span class="paragraph xs text-color-light">20/01/2022</span>
            </div>
            <div class="d-flex align-center gap-4">
              <em class="fal fa-calendar-exclamation text-color-light"></em>
              <span class="paragraph xs text-color-light">05/02/2022</span>
            </div>
            <b-dropdown x="right">
              <template v-slot:trigger="{ on }">
                <button class="btn-icon" v-on="on">
                  <em class="fal fa-ellipsis-vertical"></em>
                </button>
              </template>
              <template #content>
                <div class="list">
                  <button class="list-item">
                    <em class="fal fa-pen"></em>
                    <span>Editar</span>
                  </button>
                  <span class="divider"></span>
                  <button class="list-item text-color-danger">
                    <em class="fal fa-trash text-color-danger"></em>
                    <span>Excluir</span>
                  </button>
                </div>
              </template>
            </b-dropdown>
          </div>
          <div class="list-item">
            <span class="paragraph xs font-weight-700">04</span>
            <span class="label-status bg-warning">Emitida</span>
            <span class="spacer"></span>
            <span class="paragraph xs">R$ 30.000.00</span>
            <div class="d-flex align-center gap-4">
              <em class="fal fa-calendar-pen text-color-light"></em>
              <span class="paragraph xs text-color-light">20/01/2022</span>
            </div>
            <div class="d-flex align-center gap-4">
              <em class="fal fa-calendar-exclamation text-color-light"></em>
              <span class="paragraph xs text-color-light">05/02/2022</span>
            </div>
            <b-dropdown x="right">
              <template v-slot:trigger="{ on }">
                <button class="btn-icon" v-on="on">
                  <em class="fal fa-ellipsis-vertical"></em>
                </button>
              </template>
              <template #content>
                <div class="list">
                  <button class="list-item">
                    <em class="fal fa-pen"></em>
                    <span>Editar</span>
                  </button>
                  <span class="divider"></span>
                  <button class="list-item text-color-danger">
                    <em class="fal fa-trash text-color-danger"></em>
                    <span>Excluir</span>
                  </button>
                </div>
              </template>
            </b-dropdown>
          </div>
          <div class="list-item">
            <span class="paragraph xs font-weight-700">05</span>
            <span class="label-status bg-info">Aguardando</span>
            <span class="spacer"></span>
            <span class="paragraph xs">R$ 30.000.00</span>
            <div class="d-flex align-center gap-4">
              <em class="fal fa-calendar-pen text-color-light"></em>
              <span class="paragraph xs text-color-light">20/01/2022</span>
            </div>
            <div class="d-flex align-center gap-4">
              <em class="fal fa-calendar-exclamation text-color-light"></em>
              <span class="paragraph xs text-color-light">05/02/2022</span>
            </div>
            <b-dropdown x="right">
              <template v-slot:trigger="{ on }">
                <button class="btn-icon" v-on="on">
                  <em class="fal fa-ellipsis-vertical"></em>
                </button>
              </template>
              <template #content>
                <div class="list">
                  <button class="list-item">
                    <em class="fal fa-pen"></em>
                    <span>Editar</span>
                  </button>
                  <span class="divider"></span>
                  <button class="list-item text-color-danger">
                    <em class="fal fa-trash text-color-danger"></em>
                    <span>Excluir</span>
                  </button>
                </div>
              </template>
            </b-dropdown>
          </div>
          <div class="list-item">
            <span class="paragraph xs font-weight-700">0</span>
            <span class="label-status bg-info">Aguardando</span>
            <span class="spacer"></span>
            <span class="paragraph xs">R$ 30.000.00</span>
            <div class="d-flex align-center gap-4">
              <em class="fal fa-calendar-pen text-color-light"></em>
              <span class="paragraph xs text-color-light">20/01/2022</span>
            </div>
            <div class="d-flex align-center gap-4">
              <em class="fal fa-calendar-exclamation text-color-light"></em>
              <span class="paragraph xs text-color-light">05/02/2022</span>
            </div>
            <b-dropdown x="right">
              <template v-slot:trigger="{ on }">
                <button class="btn-icon" v-on="on">
                  <em class="fal fa-ellipsis-vertical"></em>
                </button>
              </template>
              <template #content>
                <div class="list">
                  <button class="list-item">
                    <em class="fal fa-pen"></em>
                    <span>Editar</span>
                  </button>
                  <span class="divider"></span>
                  <button class="list-item text-color-danger">
                    <em class="fal fa-trash text-color-danger"></em>
                    <span>Excluir</span>
                  </button>
                </div>
              </template>
            </b-dropdown>
          </div>
        </div>
      </div> -->
    </template>
    <template v-else>
      <Empty image="financeiro" title="Habilite os dados financeiros" :details="details"
        text="Para cadastrar dados financeiros mude a fase deste <strong>Lead</strong> para <strong>Negócio</strong>."
        button="Negócio" icon="badge-dollar" color="turquesa" />
    </template>
  </div>
</template>

<script>
import mixins from '@/mixins'
import Empty from './Empty.vue'
import { mapActions } from 'vuex'
export default {
  name: 'FinanceiroComponent',
  mixins: [mixins],
  props: ['name', 'details'],
  components: { Empty },
  data () {
    return {
      form: {
        date: null,
        billing: false
      },
      errors: {
        dealValue: ''
      }
    }
  },
  methods: {
    ...mapActions({
      loadPipelineFinancialData: 'jobs/loadPipelineFinancialData',
      resetData: 'jobs/resetData'
    }),
    verifyValueDecimal (number) {
      let numberString = number.toString()

      if (numberString.includes('.') && /\d$/.test(numberString)) {
        return (number * 1000).toLocaleString('pt-BR')
      }

      return number.toLocaleString('pt-BR')
    }
  },
  computed: {
    dealValue: {
      get () {
        return this.$store.state.jobs.form.dealValue
      },
      set (value) {
        if (!value) {
          this.errors.dealValue = 'Campo obrigatório'
          return
        }
        this.errors.dealValue = ''
        this.$store.commit('jobs/SET_FORM_FINANCIAL_VALUEDEAL', value)
      }
    },
    histories: {
      get () {
        return this.$store.state.jobs.form.valueHistory
      },
    }
  },
  mounted () {
    this.loadPipelineFinancialData(this.$props.details.id).catch(this.resetData)
  }
}
</script>
