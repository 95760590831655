const stages = [
  {
    stepId: 1,
    name: 'Prospect',
    color: 'lavanda',
    icon: 'bullhorn'
  },
  {
    stepId: 2,
    name: 'Leads',
    color: 'jambo',
    icon: 'users'
  },
  {
    stepId: 3,
    name: 'Negócios',
    color: 'turquesa',
    icon: 'badge-dollar'
  },
  {
    stepId: 4,
    name: 'Projetos',
    color: 'esmeralda',
    icon: 'folder-user'
  },
  {
    stepId: 5,
    name: 'Placeds',
    color: 'liquen',
    icon: 'user-tie'
  }
]

const helpers = {
  computed: {
    stages () {
      const result = {}

      stages.forEach((stage) => {
        result[stage.stepId] = stage
      })

      return result
    }
  }
}


export default helpers
